import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'

const initialState = {
  startDate: '',
  endDate: '',
  notes: '',
}

interface ContractConfigurationState {
  /** The start date of the contract */
  startDate: string
  /** The end date of the contract */
  endDate: string
  /** The notes of the contract */
  notes: string
}

export const ContractConfigurationSlice = createSlice({
  name: 'onDemandContractConfiguration',
  initialState,
  reducers: {
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload
    },
    setServerStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload
    },

    setEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload
    },

    setServerEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload
    },

    setNotes: (state, action: PayloadAction<string>) => {
      state.notes = action.payload
    },

    setServerNotes: (state, action: PayloadAction<string>) => {
      state.notes = action.payload
    },
    clearContractConfiguration: () => {
      return initialState
    },
  },
})

export const selectOnDemandContractConfiguration = createSelector(
  (state: RootState) => state.onDemandContractConfigurationDetails.startDate,
  (state: RootState) => state.onDemandContractConfigurationDetails.endDate,
  (state: RootState) => state.onDemandContractConfigurationDetails.notes,
  (startDate, endDate, notes): ContractConfigurationState => {
    return {
      startDate,
      endDate,
      notes,
    }
  }
)

export const {
  setStartDate,
  setServerStartDate,
  setEndDate,
  setServerEndDate,
  setNotes,
  setServerNotes,
  clearContractConfiguration,
} = ContractConfigurationSlice.actions

export default ContractConfigurationSlice.reducer
