import React, { useCallback, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToggle } from 'Hooks/useToggle'
import { Accordion } from 'Components/shared/accordion'
import { SidebarFieldWrapper } from 'Components/shared/sidebar'

import { Slider } from 'Components/shared/slider'
import { SidebarField } from 'Components/shared/sidebar'
import { Gpa as GpaComponent } from 'Components/shared/segmentfilters/prospectprofile/Gpa'
import { selectGpaTestScores } from './GpaTestScoresSlice'
import { GpaTestScores } from './GpaTestScores'

import {
  selectIntendedAreaofStudy,
  setOnDemandIntendedAreaofStudy,
} from './IntendedAreaofStudySlice'

import {
  selectAreaOfInterest,
  setOnDemandAreaOfInterest,
  setAreaOfInterestDefaultValue,
} from './AreaOfInterestSlice'

import {
  selectAcademicDiscipline,
  setOnDemandAcademicDiscipline,
} from './AcademicDisciplineSlice'

import {
  selectExpectedTransferTerm,
  setOnDemandExpectedTransferTerm,
} from './ExpectedTransferTermSlice'
import {
  selectGraduationYear,
  setGraduationYearDefaultValue,
  setOnDemandGraduationYearRange,
} from './GraduationYearSlice'

import {
  selectLastDegreeCompleted,
  setLastDegreeCompleted,
} from './LastDegreeCompletedSlice'
import {
  selectCurrentlyEnrolled,
  setCurrentlyEnrolled,
} from './CurrentlyEnrolledSlice'
import {
  selectLevelOfDegreeSeeking,
  setLevelOfDegreeSeekingDefaultValue,
  setLevelOfDegreeSeeking,
} from './LevelOfDegreeSeekingSlice'

import { selectGpa, setOnDemandAnyGpa, setOnDemandGpaRange } from './GpaSlice'
import { FilterSingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { VirtualizedMultiSelect } from 'Components/shared/multiselectdropdown'
import { Contract } from 'Services/contracts/contractsUtils'
import { setOnDemandModality, selectModality } from './ModalitySlice'
import {
  setOnDemandExperienceRange,
  setOnDemandExperienceRangeSelection,
  selectExperience,
  setExperienceDefaultValue,
} from './YearsOfWorkExperienceSlice'

interface ProspectProfileProps {
  chosenItem: Partial<Contract>
  filterData?: any
  segmentId?: string
  resetKey?: number
}

export const ProspectProfile = memo(
  ({ chosenItem, filterData, segmentId, resetKey }: ProspectProfileProps) => {
    const dispatch = useDispatch()
    const [expand, setExpand] = useToggle(false)

    const gpa = useSelector(selectGpa)
    const gpaTestScores = useSelector(selectGpaTestScores)
    const levelOfDegreeSeeking = useSelector(selectLevelOfDegreeSeeking)
    const intendedAreaofStudy = useSelector(selectIntendedAreaofStudy)
    const areaOfInterest = useSelector(selectAreaOfInterest)
    const academicDiscipline = useSelector(selectAcademicDiscipline)
    const expectedTransferTerm = useSelector(selectExpectedTransferTerm)
    const lastDegreeCompleted = useSelector(selectLastDegreeCompleted)
    const currentlyEnrolled = useSelector(selectCurrentlyEnrolled)
    const graduationYear = useSelector(selectGraduationYear)
    const modality = useSelector(selectModality)
    const experience = useSelector(selectExperience)

    /** Reset Some Defaults */
    useEffect(() => {
        const audienceType = chosenItem?.audience_type
        setLevelOfDegreeSeekingDefaultValue(dispatch, audienceType)
        setGraduationYearDefaultValue(dispatch, audienceType)
        setAreaOfInterestDefaultValue(dispatch, audienceType)
        // setExperienceDefaultValue(dispatch, audienceType) #TODO: To be added back next sprint
        dispatch(setOnDemandAnyGpa({ audienceType: chosenItem?.audience_type, selections: ['Any']}))
    }, [chosenItem?.id, resetKey])

    /** This useEffect is for setting the filters in edit segment */
    useEffect(() => {
      if (segmentId && filterData) {
        if (filterData?.[modality.label]) {
          dispatch(setOnDemandModality(filterData[modality.label]))
        }

        if (filterData?.[experience.label]) {
          let selections = filterData?.[experience.label]
          dispatch(setOnDemandExperienceRangeSelection({selections}))
        }

        if (filterData?.[levelOfDegreeSeeking.label]) {
          dispatch(
            setLevelOfDegreeSeeking(filterData[levelOfDegreeSeeking.label])
          )
        }

        if (filterData?.[intendedAreaofStudy.label])
          dispatch(
            setOnDemandIntendedAreaofStudy(
              filterData[intendedAreaofStudy.label]
            )
          )
        if (filterData?.[areaOfInterest.label])
          dispatch(setOnDemandAreaOfInterest(filterData[areaOfInterest.label]))

        if (filterData?.[expectedTransferTerm.label])
          dispatch(
            setOnDemandExpectedTransferTerm(
              filterData[expectedTransferTerm.label]
            )
          )

        if (filterData?.[lastDegreeCompleted.label]) {
          dispatch(
            setLastDegreeCompleted(filterData?.[lastDegreeCompleted.label])
          )
        }
        if (filterData?.[currentlyEnrolled.label]) {
          dispatch(setCurrentlyEnrolled(filterData?.[currentlyEnrolled.label]))
        }

        if (filterData?.[graduationYear.label]) {
          const range = filterData?.[graduationYear.label]
          dispatch(
            setOnDemandGraduationYearRange({
              min: range[0],
              max: range[1],
            })
          )
        }

        if (filterData?.[gpa.label]) {
          const selections = filterData?.[gpa.label]
            dispatch(
              setOnDemandAnyGpa({
                audienceType: chosenItem?.audience_type,
                selections
              })
            )
        }

        if (filterData?.[academicDiscipline.label]) {
          dispatch(
            setOnDemandAcademicDiscipline(
              filterData?.[academicDiscipline.label]
            )
          )
        }
      }
    }, [filterData])

    const dispatchModality = useCallback((value: string[]) => {
      dispatch(setOnDemandModality(value))
    }, [])

    const dispatchExperience = useCallback(({ min, max }) => {
      dispatch(setOnDemandExperienceRange({ min, max }))
    }, [])

    const dispatchLevelOfDegreeSeeking = useCallback((values: string[]) => {
      dispatch(setLevelOfDegreeSeeking(values))
    }, [])

    const dispatchAcademicDiscipline = useCallback((values: string[]) => {
      dispatch(setOnDemandAcademicDiscipline(values))
    }, [])

    const dispatchIntendedAreaofStudy = useCallback((values: string[]) => {
      dispatch(setOnDemandIntendedAreaofStudy(values))
    }, [])

    const dispatchAreaOfInterest = useCallback((values: string[]) => {
      dispatch(setOnDemandAreaOfInterest(values))
    }, [])

    const dispatchExpectedTransferTerm = useCallback((values: string[]) => {
      dispatch(setOnDemandExpectedTransferTerm(values))
    }, [])
    const dispatchCurrentlyEnrolled = useCallback((value: string) => {
      dispatch(setCurrentlyEnrolled(value))
    }, [])

    const dispatchGraduationYearRange = useCallback(({ min, max }) => {
      dispatch(setOnDemandGraduationYearRange({ min, max }))
    }, [])

    const dispatchLastDegreeCompleted = useCallback((values: string[]) => {
      dispatch(setLastDegreeCompleted(values))
    }, [])

    const dispatchAnyGpa = useCallback((selections) => {
      dispatch(setOnDemandAnyGpa({ audienceType: chosenItem?.audience_type, selections}))
    }, [chosenItem?.id])

    const dispatchGpaRange = useCallback(
      ({ min, max }) => {
        dispatch(
          setOnDemandGpaRange({
            min,
            max,
            audienceType: chosenItem?.audience_type,
          })
        )
      },
      [chosenItem]
    )
    return (
      <Accordion
        setExpand={setExpand}
        expand={expand}
        title='PROSPECT PROFILE'
        disabled={chosenItem?.id ? false : true}
      >
        <SidebarFieldWrapper>
          {modality.audienceTypes.includes(chosenItem?.audience_type) && (
            <VirtualizedMultiSelect
              label={modality.label}
              counterLabel={'Modality'}
              values={modality.values}
              initialSelectedValues={modality.selectedValues}
              selectedValues={modality.selectedValues}
              setSelectedValues={dispatchModality}
            />
          )}
          {/* #TODO To be added back next sprint */}
          {experience.audienceTypes.includes(chosenItem?.audience_type) && (
            <>
            <VirtualizedMultiSelect
              label={experience.label}
              counterLabel="Selections"
              values={experience.values}
              selectedValues={experience.selections}
              initialSelectedValues={experience.selections}
              setSelectedValues={(selections) => dispatch(setOnDemandExperienceRangeSelection({selections}))}
              />
            {experience.selections?.includes('Specific Year Range') && <SidebarField id='experience' label="Experience Range">
              <Slider
                ariaLabel={experience.ariaLabel}
                min={experience.minExperience}
                max={experience.maxExperience}
                selectedMin={experience.selectedMinExperience}
                selectedMax={experience.selectedMaxExperience}
                step={experience.step}
                handleChange={(range: number[]) =>
                  dispatchExperience({ min: range[0], max: range[1] })
                }
              />
            </SidebarField>}
            </>
          )}
          {graduationYear.audienceTypes.includes(chosenItem?.audience_type) && (
            <SidebarField id='graduation year' label={graduationYear.label}>
              <Slider
                ariaLabel={graduationYear.ariaLabel}
                min={graduationYear.minYear}
                max={graduationYear.maxYear}
                selectedMin={graduationYear.selectedMinGradYear}
                selectedMax={graduationYear.selectedMaxGradYear}
                step={graduationYear.step}
                handleChange={(range: number[]) =>
                  dispatchGraduationYearRange({ min: range[0], max: range[1] })
                }
              />
            </SidebarField>
          )}

          {expectedTransferTerm.audienceTypes.includes(
            chosenItem?.audience_type
          ) && (
            <VirtualizedMultiSelect
              label={expectedTransferTerm.label}
              counterLabel={'Transfer Term'}
              values={expectedTransferTerm.values}
              initialSelectedValues={expectedTransferTerm.selectedValues}
              selectedValues={expectedTransferTerm.selectedValues}
              setSelectedValues={dispatchExpectedTransferTerm}
            />
          )}

          {areaOfInterest.audienceTypes.includes(chosenItem?.audience_type) && (
            <VirtualizedMultiSelect
              label={areaOfInterest.label}
              counterLabel={'Area Of Interest'}
              values={areaOfInterest.values}
              initialSelectedValues={areaOfInterest.selectedValues}
              selectedValues={areaOfInterest.selectedValues}
              setSelectedValues={dispatchAreaOfInterest}
            />
          )}
          {academicDiscipline.audienceTypes.includes(chosenItem?.audience_type) && (
            <VirtualizedMultiSelect
              label={academicDiscipline.label}
              counterLabel={'Academic Discipline'}
              values={academicDiscipline.values}
              initialSelectedValues={academicDiscipline.selectedValues}
              selectedValues={academicDiscipline.selectedValues}
              setSelectedValues={dispatchAcademicDiscipline}
            />
          )}
          {intendedAreaofStudy.audienceTypes.includes(
            chosenItem?.audience_type
          ) && (
            <VirtualizedMultiSelect
              label={intendedAreaofStudy.label}
              counterLabel={'Area Of Study'}
              values={intendedAreaofStudy.values}
              initialSelectedValues={intendedAreaofStudy.selectedValues}
              selectedValues={intendedAreaofStudy.selectedValues}
              setSelectedValues={dispatchIntendedAreaofStudy}
            />
          )}
          {levelOfDegreeSeeking.audienceTypes.includes(
            chosenItem?.audience_type
          ) && (
            <VirtualizedMultiSelect
              label={levelOfDegreeSeeking.label}
              counterLabel={'Degree'}
              values={levelOfDegreeSeeking.values}
              selectedValues={levelOfDegreeSeeking.selectedValues}
              initialSelectedValues={levelOfDegreeSeeking.selectedValues}
              setSelectedValues={dispatchLevelOfDegreeSeeking}
            />
          )}
          {gpa.audienceTypes.includes(chosenItem?.audience_type) && (
            <GpaComponent
              dispatchAnyGpa={dispatchAnyGpa}
              dispatchGpaRange={dispatchGpaRange}
              gpaState={gpa}
            />
          )}

          {gpaTestScores.audienceTypes.includes(chosenItem?.audience_type) && (
            <GpaTestScores audienceType={chosenItem?.audience_type} />
          )}
          {lastDegreeCompleted.audienceTypes.includes(
            chosenItem?.audience_type
          ) && (
            <VirtualizedMultiSelect
              label={lastDegreeCompleted.label}
              counterLabel={'Degree'}
              values={lastDegreeCompleted.values}
              selectedValues={lastDegreeCompleted.selectedValues}
              initialSelectedValues={lastDegreeCompleted.selectedValues}
              setSelectedValues={dispatchLastDegreeCompleted}
            />
          )}
          {currentlyEnrolled.audienceTypes.includes(
            chosenItem?.audience_type
          ) && (
            <FilterSingleSelectDropdown
              label={currentlyEnrolled.label}
              values={currentlyEnrolled.values}
              value={currentlyEnrolled.selectedValue}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                dispatchCurrentlyEnrolled(e.target.value)
              }}
            />
          )}
        </SidebarFieldWrapper>
      </Accordion>
    )
  }
)
