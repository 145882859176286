import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type WebsocketStatus = {
  waitingForResponse: boolean
  availableStudents: Object[]
  charts: Object[]
}

const initialState: WebsocketStatus = { waitingForResponse: false, availableStudents: [], charts: [
  {chartType: 'bar', studentMetric: 'state_abbr', metricLabel: 'State', limit: 10, title: 'Top 10 Student States'},
  {chartType: 'table', studentMetric: 'state_abbr', metricLabel: 'State', title: 'All Student States'},
  {chartType: 'table', studentMetric: 'state_abbr', metricLabel: 'State', secondMetric: 'high_school_grad_year', title: 'All Student States Grad Years'},
  {chartType: 'pie', studentMetric: 'high_school_grad_year', title: 'HS Grad Class'},
  {chartType: 'pie', studentMetric: 'gender', title: 'Gender'}
]}

const websocketSlice = createSlice({
  name: 'websocketStatus',
  initialState,
  reducers: {
    messageSent: (state) => {
      state.waitingForResponse = true
    },
    messageReceived: (state, action: PayloadAction<[Object]>) => {
      state.waitingForResponse = false
      state.availableStudents = action.payload
    },
  },
})

export const { messageSent, messageReceived } = websocketSlice.actions

export default websocketSlice.reducer
