import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { alpha } from '@mui/material'
import { Link, Outlet } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'

const FlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  boxShadow: `0px 1px 1px ${alpha(theme.palette.secondary['100'], 0.1)}`,
  userSelect: 'none',
}))

const NavFlexContainer = styled(FlexContainer)(() => ({
  justifyContent: 'space-between',
  padding: '0 32px',
  height: '68px',
}))

const SubNavFlexContainer = styled(FlexContainer)(() => ({
  justifyContent: 'center',
  position: 'relative',
  height: '48px',
}))

const EABLogo = styled('span')(({ theme }) => ({
  color: theme.palette.secondary['100'],
  fontSize: '1.5rem',
  lineHeight: '24px',
}))

const BaseNavElement = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  color: active ? theme.palette.primary.main : 'initial',
  cursor: 'pointer',
  display: 'inline-block',
  position: 'relative',
  fontWeight: theme.typography.fontWeightMedium,
  marginLeft: '28px',
  marginRight: '28px',
}))

const MainNavElement = styled(BaseNavElement, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  borderBottom: active
    ? `3px solid ${theme.palette.primary.main}`
    : '3px solid transparent',
  fontSize: '1rem',
  height: '100%',
  lineHeight: '22px',
  padding: '23px 0 20px 0',
}))

const SubNavElement = styled(BaseNavElement, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  borderBottom: active
    ? `2px solid ${theme.palette.primary.main}`
    : '2px solid transparent',
  fontSize: '0.75rem',
  height: '100%',
  lineHeight: '16px',
  padding: '20px 0 10px 0',
}))

const Divider = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey['300'],
  display: 'inline-block',
  position: 'relative',
  top: '10px',
  height: '31px',
  width: '1px',
}))

const NavLinks = styled('div')(() => ({
  height: '100%',
}))

const CustomLink = styled(Link)(() => ({
  display: 'inline-block',
  height: '100%',
}))

interface HeaderProps {
  /** Is the user Logged in? */
  isLoggedIn: boolean
  /** Navigation config */
  navConfig: any[]
  /** Navigation config indexes from redux */
  navConfigIdxs: { mainNavIdx: number; subNavIdx: number }
  /** When the user click a navigation item */
  handleNavClick: (e: any) => void
  /**  Account Settings passed as children */
  children: React.ReactNode
  /** boolean that determines which header config to use */
  isAdmin: boolean
  /** Institution IPED ID */
  ipedId?: string
}

type Nav = {
  url: string
  active: boolean
  mainNav: string
  subNavs: { subNav: string; active: boolean, restricted?: boolean }[]
}

export const Header = ({
  isLoggedIn,
  navConfig,
  navConfigIdxs,
  handleNavClick,
  children,
  isAdmin,
  ipedId = '',
}: HeaderProps) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const institutionPrefix = isAdmin ? '' : `/institution/${ipedId}`
  const { hasPermission: canViewEnhancedCandidates } = useHasPermission(
  Permissions.ViewEnhancedCandidatesSegmentDetails
)
  const navConfigWithPermissions = canViewEnhancedCandidates ? 
    navConfig : 
    navConfig.map((nav: Nav) => ({...nav, subNavs: nav.subNavs.filter(subNav => !subNav.restricted)}))
  return (
    isLoggedIn && (
      <div
        onClick={handleNavClick}
        onKeyDown={(e) =>
          (e.key === 'Enter' || e.key === ' ') && handleNavClick(e)
        }
      >
        <NavFlexContainer>
          <EABLogo>EAB</EABLogo>
          {matches && (
            <NavLinks data-testid='nav'>
              {navConfigWithPermissions.map((nav: Nav, idx: number) => {
                return (
                  <React.Fragment key={idx}>
                    {!isAdmin && idx === navConfig.length - 1 && <Divider />}
                    <CustomLink
                      to={`${institutionPrefix}${nav.url}`}
                      data-idx={`${idx}-0`}
                    >
                      <MainNavElement
                        active={idx === navConfigIdxs.mainNavIdx}
                        data-idx={`${idx}-0`}
                      >
                        {nav.mainNav}
                      </MainNavElement>
                    </CustomLink>
                  </React.Fragment>
                )
              })}
            </NavLinks>
          )}
          {children}
        </NavFlexContainer>

        {!matches && (
          <SubNavFlexContainer>
            {navConfigWithPermissions.map((nav: Nav, idx: number) => {
              return (
                <React.Fragment key={idx}>
                  {idx === navConfigWithPermissions.length - 1 && <Divider />}
                  <CustomLink
                    to={`${institutionPrefix}${nav.url}`}
                    data-idx={`${idx}-0`}
                  >
                    <SubNavElement
                      active={idx === navConfigIdxs.mainNavIdx}
                      data-idx={`${idx}-0`}
                    >
                      {nav.mainNav}
                    </SubNavElement>
                  </CustomLink>
                </React.Fragment>
              )
            })}
          </SubNavFlexContainer>
        )}

        <SubNavFlexContainer data-testid='sub-nav'>
          {navConfigWithPermissions.map((nav: Nav, outerIdx: number) => {
            return nav.subNavs.map((subNavObj: any, innerIdx: number) => {
              return (
                outerIdx === navConfigIdxs.mainNavIdx && (
                  <React.Fragment key={outerIdx + innerIdx}>
                    <CustomLink
                      to={`${institutionPrefix}${nav.url}${subNavObj.url}`}
                      data-idx={`${outerIdx}-${innerIdx}`}
                    >
                      <SubNavElement
                        active={innerIdx === navConfigIdxs.subNavIdx}
                        data-idx={`${outerIdx}-${innerIdx}`}
                      >
                        {subNavObj.subNav}
                      </SubNavElement>
                    </CustomLink>
                  </React.Fragment>
                )
              )
            })
          })}
        </SubNavFlexContainer>

        <Outlet />
      </div>
    )
  )
}
