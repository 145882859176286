import React, { useEffect, useState } from 'react'
import { BrowserRouter, HashRouter, useLocation } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'
import { Table, TableFooter, DeliveryPeriod } from 'Components/shared/table'
import { TablePagination } from 'Containers/shared/tablePagination'
import { BodyContent } from 'Components/shared/body'
import { usePagination } from 'Hooks/usePagination'
import { CombineBy, FilterBy, properties } from 'Filter/properties'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { Loading } from 'Utils/loading'
import { useGetCandidatesContractsQuery } from 'Services/contracts/candidatesContracts'
import { useGetInquiriesContractsQuery } from 'Services/contracts/inquiriesContracts'
import { useLazyGetSegmentsForContractQuery } from 'Services/segments/segments'
import { useGetAudienceTypesQuery } from 'Services/global/audienceTypes'
import { formatDate } from 'Utils/dateUtils'
import { ContractSource } from 'Types/index'
import { AudienceTypeHelper, ContractStatus } from 'Types/index'

interface ContractsProps {
  /** contractSource is the type of contract */
  contractSource: ContractSource
  /** contractStatus indicates whether contract is expired or not */
  contractStatus: ContractStatus[]
}

const inquiriesColumnData = [
  { columnName: 'Name', columnWidth: 270, sortOrder: 'ASC' },
  {
    columnName: 'Audience Type',
    columnWidth: 140,
    sortBy: 'audience_type',
    sortOrder: 'desc',
  },
  { columnName: 'Lead Cap', columnWidth: 75 },
  { columnName: 'Delivery Period', columnWidth: 170 },
  { columnName: 'Created', columnWidth: 100 },
]

const candidatesColumnData = [
  { columnName: 'Name', columnWidth: 270 },
  {
    columnName: 'Audience Type',
    columnWidth: 140,
    sortBy: 'audience_type',
    sortOrder: 'desc',
  },
  { columnName: 'Total Leads Delivered', columnWidth: 75 },
  { columnName: 'Contract Period', columnWidth: 170 },
  { columnName: 'Created', columnWidth: 100 },
]

export const ContractsContainer = ({
  contractSource,
  contractStatus,
}: ContractsProps) => {
  const navigate = useNavigate()
  const { ipedId, ...other } = useParams()
  const PAGE_SIZE = 6
  const { currentPage, handlePageChange } = usePagination()
  const [tableRows, setTableRows] = useState([])
  const [leadsDelivered, setLeadsDelivered] = useState([])
  const [contractsInfo, setContractsInfo] = useState({
    records: [],
    total_pages: 0,
    total_records: 0,
  })
  const [sortOrder, setSortOrder] = useState('desc')
  const [sortBy, setSortBy] = useState('audience_type')
  const [columnData, setColumnData] = useState(
    contractSource === ContractSource.inquiries
      ? inquiriesColumnData
      : candidatesColumnData
  )

  const [getSegmentsForContract] = useLazyGetSegmentsForContractQuery()
  const { data: audienceTypes, isFetching: isFetchingAudienceTypes } =
    useGetAudienceTypesQuery(contractSource)

  const contractQuery = {
    query: {
      filterQuery: properties(
        [
          {
            column: 'source',
            op: FilterBy.EQ,
            value: contractSource,
          },
          {
            column: 'status',
            op: FilterBy.IN,
            value: contractStatus,
          },
        ],
        CombineBy.AND
      ),
    },
  }

  const { data: candidatesInfo, isLoading: isLoadingCandidates } =
    useGetCandidatesContractsQuery(
      {
        ipedId,
        page: currentPage,
        size: PAGE_SIZE,
        order: sortOrder,
        filter: buildFilterQuery(contractQuery),
      },
      { skip: contractSource === ContractSource.inquiries }
    )

  const { data: inquiriesInfo, isLoading: isLoadingInquiries } =
    useGetInquiriesContractsQuery(
      {
        ipedId,
        page: currentPage,
        size: PAGE_SIZE,
        order: sortOrder,
        filter: buildFilterQuery(contractQuery),
      },
      { skip: contractSource === ContractSource.candidates }
    )

  const handleRowClick = (rowIdx: number) => {
    navigate(
      `/institution/${ipedId}/${contractSource}/contracts/${tableRows[rowIdx][0]}`
    )
  }

  useEffect(() => {
    if (inquiriesInfo) {
      let allRows = []

      inquiriesInfo.records?.forEach((contract: any) => {
        allRows.push([
          contract.id,
          contract.label,
          AudienceTypeHelper.getReadableString(
            audienceTypes,
            contract.audience_type
          ),
          contract.lead_cap,
          <DeliveryPeriod
            startDate={formatDate(contract.start_date)}
            endDate={formatDate(contract.end_date)}
          />,
          formatDate(contract.created_at),
        ])
      })
      setContractsInfo(inquiriesInfo)
      setTableRows(allRows)
    }
  }, [inquiriesInfo, audienceTypes])

  useEffect(() => {
    if (candidatesInfo) {
      const allPromises = []
      candidatesInfo.records?.forEach((contract: any) => {
        allPromises.push(
          getSegmentsForContract({
            ipedId,
            contractId: contract.id,
          })
        )
      })

      Promise.allSettled(allPromises).then((results) => {
        const allLeadsDelivered = []
        results.forEach((result) => {
          let leadsDelivered = 0
          result['value'].data?.forEach((segment: any) => {
            leadsDelivered += segment.leads_delivered
              ? segment.leads_delivered
              : 0
          })
          allLeadsDelivered.push(leadsDelivered)
        })
        setLeadsDelivered(allLeadsDelivered)
      })
    }
  }, [candidatesInfo])

  useEffect(() => {
    let allRows = []
    if (candidatesInfo && leadsDelivered.length > 0) {
      candidatesInfo.records?.forEach((contract: any, idx: number) => {
        allRows.push([
          contract.id,
          contract.label,
          AudienceTypeHelper.getReadableString(
            audienceTypes,
            contract.audience_type
          ),
          leadsDelivered[idx],
          <DeliveryPeriod
            startDate={formatDate(contract.start_date)}
            endDate={formatDate(contract.end_date)}
          />,
          formatDate(contract.created_at),
        ])
      })
      setTableRows(allRows)
      setContractsInfo(candidatesInfo)
    }
  }, [candidatesInfo, leadsDelivered, audienceTypes])

  useEffect(() => {
    setColumnData(
      columnData.map((column) => {
        if (column.sortBy === sortBy) {
          return {
            ...column,
            sortOrder: sortOrder,
            sortBy: sortBy,
          }
        }
        return column
      })
    )
  }, [sortOrder, sortBy])

  if (isLoadingCandidates || isLoadingInquiries || isFetchingAudienceTypes)
    return <Loading />

  return (
    <BodyContent>
      {contractsInfo?.total_records > 0 && (
        <>
          <Table
            columnData={columnData}
            rows={tableRows}
            handleRowClick={handleRowClick}
            setSortOrder={setSortOrder}
            setSortBy={setSortBy}
          />
          <TableFooter>
            {contractsInfo?.total_pages > 1 && (
              <TablePagination
                rowsPerPage={PAGE_SIZE}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                totalRecords={contractsInfo.total_records}
                totalPages={contractsInfo.total_pages}
              />
            )}
          </TableFooter>
        </>
      )}
    </BodyContent>
  )
}
