import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import {
  useGetDestinationsForContractQuery,
  useUpdateContractMutation,
  useAddDestinationsToContractMutation,
  useSubscribeUsertoContractNotificationsMutation,
} from 'Services/contracts/candidatesContracts'

import { useGetDeduplicatesForContractQuery } from 'Services/contracts/deduplicates'

import { useGetAllDestinationsQuery } from 'Services/configuration/destinations'

import { useGetCandidatesContractsByIdQuery } from 'Services/contracts/candidatesContracts'
import {
  useUpdateDeduplicateFileMutation,
  useAddDeduplicateFileMutation,
  useUpdateFileStatusMutation,
} from 'Services/contracts/deduplicates'

import { ContractDetails as ContractDetailsComponent } from 'Components/shared/contracts/contractdetails'

import { Body } from 'Components/shared/body'

import { Loading } from 'Utils/loading'
import { RootState } from 'Utils/store'
import { formatContractDate } from 'Utils/dateUtils'
import { DeduplicatesContainer } from 'Containers/shared/contracts/deduplicates'
import {
  setStartDate,
  setServerStartDate,
  setEndDate,
  setServerEndDate,
  setNotes,
  setServerNotes,
  selectOnDemandContractConfiguration,
  clearContractConfiguration,
} from './OnDemandContractConfigurationSlice'
import {
  setAllDestinations,
  setSelectedDestinations,
  setServerSelectedDestinations,
  setSelectedFileFormat,
  setServerSelectedFileFormat,
  setSelectedFields,
  setServerSelectedFields,
  setSelectedLeads,
  setServerSelectedLeads,
  setServerLookbackPeriod,
  setSelectedLookbackPeriod,
  selectOnDemandDeliveryCriteria,
  clearOnDemandDeliveryCriteria,
} from './OnDemandDeliveryCriteriaSlice'

import { resetDisableUpdate } from './OnDemandContractStateSlice'
import { selectDeduplicatesFile } from 'Containers/shared/contracts/deduplicates/DeduplicatesSlice'

import { ContractConfiguration } from 'Containers/shared/contracts/contractdetails/ContractConfiguration'
import { DeliveryCriteria } from 'Containers/shared/contracts/contractdetails/DeliveryCriteria'
import { DaysHumanToNumber } from 'Utils/dateUtils'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { ContractSource, ContractType, ContractStatus } from 'Types/index'

export const CandidatesContractDetails = () => {
  const dispatch = useDispatch()
  const { contractId, ipedId } = useParams()
  const navigate = useNavigate()

  const {
    control,
    formState: { errors: formErrors, isValid },
  } = useForm({
    mode: 'onChange',
  })

  const disableUpdate = useSelector(
    ({ onDemandContractState }: RootState) =>
      onDemandContractState.disableUpdate
  )

  const { startDate, endDate, notes } = useSelector(
    selectOnDemandContractConfiguration
  )
  const { hasPermission: canUpdateContract, isFetching } = useHasPermission(
    Permissions.UpdateContract
  )

  const { fileId, fileName } = useSelector(selectDeduplicatesFile)

  const {
    allDestinations,
    selectedDestinations,
    selectedFileFormat,
    selectedFields,
    allLeads,
    selectedLeads,
    allLookbackPeriods,
    selectedLookbackPeriod,
  } = useSelector(selectOnDemandDeliveryCriteria)

  const { data: candidatesContract, isLoading: isLoadingCandidatesContracts } =
    useGetCandidatesContractsByIdQuery({
      ipedId,
      contractId,
    })

  const [addDestinationsToContract] = useAddDestinationsToContractMutation()

  const [subscribeUsertoContractNotifications] =
    useSubscribeUsertoContractNotificationsMutation()

  /** Get all destinations and already selected destinations for the contract */
  const { data: allDestinationsInfo, isLoading: isGettingAlldestinations } =
    useGetAllDestinationsQuery({
      ipedId,
    })

  const { data: selectedDestinationsInfo, isLoading: isLoadingDestinations } =
    useGetDestinationsForContractQuery({
      ipedId,
      contractId: contractId,
    })

  const { data: addedDeDuplicateFile, isLoading: isLoadingDeduplicates } =
    useGetDeduplicatesForContractQuery({
      ipedId,
      contractId: contractId,
    })

  const [updateFileStatus] = useUpdateFileStatusMutation()

  const [addDeduplicate] = useAddDeduplicateFileMutation()

  const [
    updateContract,
    { isLoading: isUpdatingContract, isSuccess: isContractUpdated },
  ] = useUpdateContractMutation()

  const disabledContract =
    [ContractStatus.expired, ContractStatus.completed].includes(
      candidatesContract?.status
    ) || !canUpdateContract

  const goBack = useCallback(() => {
    navigate(-1)
    clearContractValues()
  }, [])

  const [updateDeduplicate] = useUpdateDeduplicateFileMutation()

  useEffect(() => {
    if (allDestinationsInfo) {
      dispatch(setAllDestinations(allDestinationsInfo))
    }
  }, [allDestinationsInfo])

  useEffect(() => {
    if (selectedDestinationsInfo) {
      dispatch(setServerSelectedDestinations(selectedDestinationsInfo?.data))
    }
  }, [selectedDestinationsInfo])

  useEffect(() => {
    //If contract is updated, do the following
    if (isContractUpdated) {
      //add destinations to contract
      const destinationIds =
        selectedDestinations?.length > 0
          ? selectedDestinations.map((destination: any) => destination.id)
          : []

      addDestinationsToContract({
        contractId: contractId,
        ipedId,
        destinationIds,
      })

      const accountIdsEnabled = selectedLeads?.length > 0 ? selectedLeads : []

      //subscribe user to contract notifications
      subscribeUsertoContractNotifications({
        contractId: contractId,
        ipedId,
        accountIdsEnabled,
      })

      if (fileId) {
        /** If there is a file id, call file status update to update
         * the file status. Once the file status update call returns,
         * call the deduplicate api **/

        updateFileStatus({
          ipedId,
          fileId: fileId,
        }).then(() => {
          /** If deduplicate file is added during contract creation, update it
           * Otherwise, add it for the first time
           */
          const deduplicateId = addedDeDuplicateFile?.data[0]?.id
          if (deduplicateId) {
            updateDeduplicate({
              ipedId,
              contractId: contractId,
              fileId: fileId,
              deduplicateId: addedDeDuplicateFile?.data[0]?.id,
            })
          } else {
            addDeduplicate({
              ipedId,
              contractId: contractId,
              fileId: fileId,
            })
          }
        })
      }
    }
  }, [isContractUpdated])

  const clearContractValues = () => {
    dispatch(clearContractConfiguration())
    dispatch(clearOnDemandDeliveryCriteria())
    dispatch(resetDisableUpdate())
  }

  const handleOnClick = () => {
    updateContract({
      ipedId,
      contractId: candidatesContract.id,
      contract: {
        source: ContractSource.candidates,
        contract_type: ContractType.on_demand,
        fields_to_export: selectedFields,
        label: candidatesContract.label,
        notes: notes,
        start_date: formatContractDate(startDate),
        end_date: formatContractDate(endDate),
        audience_type: candidatesContract.audience_type,
        look_back_period: DaysHumanToNumber[selectedLookbackPeriod],
        ...(candidatesContract.purchase_type && {
          purchase_type: candidatesContract.purchase_type,
        }),
      },
    })
  }

  const disableFormSubmit = !isValid || Object.keys(formErrors).length > 0

  if (
    isLoadingCandidatesContracts ||
    isGettingAlldestinations ||
    isUpdatingContract ||
    isLoadingDestinations ||
    isLoadingDeduplicates ||
    isFetching
  )
    return <Loading />

  return candidatesContract ? (
    <Body>
      <ContractDetailsComponent
        goBack={goBack}
        contract={candidatesContract}
        onClick={handleOnClick}
        disableUpdate={disableUpdate}
        disabledContract={disabledContract}
      />

      <ContractConfiguration
        contractSource={ContractSource.candidates}
        contract={candidatesContract}
        startDate={startDate}
        endDate={endDate}
        notes={notes}
        control={control}
        setStartDate={setStartDate}
        setServerStartDate={setServerStartDate}
        setEndDate={setEndDate}
        setServerEndDate={setServerEndDate}
        setNotes={setNotes}
        setServerNotes={setServerNotes}
        disabledContract={disabledContract}
      />
      <DeliveryCriteria
        contractType={ContractType.on_demand}
        audienceType={candidatesContract.audience_type}
        contractSource={ContractSource.candidates}
        contract={candidatesContract}
        allDestinations={allDestinations}
        selectedDestinations={selectedDestinations}
        selectedFileFormat={selectedFileFormat}
        selectedFields={selectedFields}
        allLeads={allLeads}
        selectedLeads={selectedLeads}
        allLookbackPeriods={allLookbackPeriods}
        selectedLookbackPeriod={selectedLookbackPeriod}
        // control={control}
        setAllDestinations={setAllDestinations}
        setSelectedDestinations={setSelectedDestinations}
        setServerSelectedDestinations={setServerSelectedDestinations}
        setSelectedFileFormat={setSelectedFileFormat}
        setServerSelectedFileFormat={setServerSelectedFileFormat}
        setSelectedFields={setSelectedFields}
        setServerSelectedFields={setServerSelectedFields}
        setSelectedLeads={setSelectedLeads}
        setServerSelectedLeads={setServerSelectedLeads}
        setSelectedLookbackPeriod={setSelectedLookbackPeriod}
        setServerLookbackPeriod={setServerLookbackPeriod}
        disabledContract={disabledContract}
      />
      {!disabledContract && <DeduplicatesContainer fileName={fileName} />}
    </Body>
  ) : null
}
