import { AnyAction, Dispatch, createSelector } from '@reduxjs/toolkit'
import { sliderSlice, buildSliderProperty } from 'Slices/sliderSlice'
import type { RootState } from 'Utils/store'
import AgeConfig from 'Configs/fields/age.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.inquiries

export const AgeSlice = sliderSlice(
  AgeConfig.column,
  'setTimeBasedAgeRange',
  [AgeConfig.range.min, AgeConfig.range.max],
  AgeConfig.meta,
  AgeConfig.defaultValues
)

export const selectAge = createSelector(
  ({ timeBasedFilter: { demographics } }: RootState) =>
    demographics.age['selectedMin'],
  ({ timeBasedFilter: { demographics } }: RootState) =>
    demographics.age['selectedMax'],
  ({ timeBasedFilter: { demographics } }: RootState) =>
    demographics.age['selections'],
  (
    selectedMinAge: number,
    selectedMaxAge: number,
    selections: string[]
  ): {
    step: number
    ariaLabel: string
    selectedMinAge: number
    selectedMaxAge: number
    minAge: number
    maxAge: number
    values: string[]
    filterQuery: any
    audienceTypes: string[]
    label: string
    selections: string[]
  } => {
    return {
      selectedMinAge,
      selectedMaxAge,
      minAge: AgeConfig.range.min,
      maxAge: AgeConfig.range.max,
      step: AgeConfig.range.step,
      ariaLabel: AgeConfig.ARIA_LABEL,
      values: AgeConfig.values,
      filterQuery: buildSliderProperty(
        AgeConfig.column,
        [selectedMinAge, selectedMaxAge],
        AgeConfig.meta,
        selections
      ),
      audienceTypes: AgeConfig.source[contractSource].audience_types,
      label: AgeConfig.meta.label,
      selections,
    }
  }
)

export const { setTimeBasedAgeRange, setTimeBasedAgeRangeSelection  } = AgeSlice.actions

export const setAgeRangeDefaultValue = (
  dispatch: Dispatch<AnyAction>,
  audienceType: string
) => {
  const ageConfig = AgeConfig.source[contractSource]
  const audienceTypes = ageConfig.audience_types

  if (!audienceType) return

  if (audienceTypes.includes(audienceType)) {
    if (ageConfig?.default_selected[audienceType]) {
      dispatch(setTimeBasedAgeRangeSelection({selections: [ageConfig?.default_selected[audienceType].min, ageConfig?.default_selected[audienceType].max]}))
    } else {
      dispatch(
        setTimeBasedAgeRangeSelection({selections: [
          AgeConfig.range.min,
          AgeConfig.range.max,
        ]})
      )
    }
  }
}

export default AgeSlice.reducer
