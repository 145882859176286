import React, { memo } from 'react'
import {
  Dialog,
  DialogActionButtons,
  DialogField,
  DialogFieldContainer,
} from 'Components/shared/dialog'

import { styled, useTheme } from '@mui/material/styles'
import { alpha } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import { filePathRegex } from 'Utils/validation'
import { Input } from 'Components/shared/input'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import CheckboxUnchecked from 'Assets/images/checkbox_unchecked.svg'
import CheckboxChecked from 'Assets/images/checkbox_checked.svg'

const Divider = styled('div')(() => ({
  height: '1px',
  width: '421px',
  marginTop: '20px',
  marginBottom: '10px',
  background: 'hsla(223, 70%, 92%, 1)',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const CancelButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.4)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const AddButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  border: 'none',
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
  },
  [theme.breakpoints.up('md')]: {
    width: '104px',
  },
}))

const DownloadFile = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark,
  display: 'inline-block',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  letterSpacing: '0.01em',
  lineHeight: '16px',
}))

interface AddDestinationProps {
  /** To show the add distination dialog or not */
  addDestinationDialog: boolean
  /** Clean up actions when closing the dialog */
  closeDialog: () => void
  /** To set the destination label name */
  setLabel: (value: string) => void
  /** To set the connection type for destination */
  setType: (value: string) => void
  /** To set the destination path */
  setPath: (value: string) => void
  /** To set the hostname for destination */
  setHost: (value: string) => void
  /** To set the download preference of the user */
  setDownloadPreference: (value: string) => void
  /** Determines if download is zip of not */
  setIsZip: (value: boolean) => void
  /** Add all destination values */
  addDestinationValues: () => void
  /** Authentication Type component */
  children?: React.ReactNode
  /** Boolean that determines whether to disable submit  */
  disableSubmit: boolean
  /** Object that registers component into React Hook Form for validation */
  control?: any
}

export const AddDestination = memo(
  ({
    addDestinationDialog,
    setLabel,
    setType,
    setPath,
    setHost,
    setDownloadPreference,
    setIsZip,
    addDestinationValues,
    closeDialog,
    disableSubmit,
    control,
    children,
  }: AddDestinationProps) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    return (
      <Dialog
        onClose={closeDialog}
        open={addDestinationDialog}
        title='Add Destination'
      >
        <DialogFieldContainer>
          <DialogField id='add-destination-name' label='Destination Name'>
            <Input
              id='add-destination-name'
              name='destinationName'
              control={control}
              rules={{
                required: true,
                maxLength: {
                  value: 100,
                  message: 'Destination name should not exceed 100 characters',
                },
              }}
              placeholder='Add New Destination Name'
              style={matches ? { width: '421px' } : {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLabel(e.target.value)
              }
            />
          </DialogField>

          <DialogField id='add-destination-type' label='Destination Type'>
            <SingleSelectDropdown
              id='add-destination-type'
              name='destinationType'
              control={control}
              rules={{
                required: 'Type is a required field',
              }}
              style={matches ? { width: '279px' } : {}}
              values={['SFTP']}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setType(e.target.value)
              }}
            />
          </DialogField>

          <DialogField id='add-hostname' label='Hostname'>
            <Input
              id='add-hostname'
              name='hostname'
              control={control}
              rules={{
                required: 'Hostname is a required field'
              }}
              placeholder='SFTP'
              style={matches ? { width: '279px' } : {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setHost(e.target.value)
              }
            />
          </DialogField>

          <DialogField id='add-destination-path' label='Destination Path'>
            <Input
              id='add-destination-path'
              name='destinationPath'
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: filePathRegex,
                  message: 'Must be a valid path beginning with /'
                }
              }}
              placeholder='/cappex/incoming'
              style={matches ? { width: '279px' } : {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPath(e.target.value)
              }
            />
          </DialogField>

          <DialogField id='divider' label=''>
            <Divider />
          </DialogField>

          {children}

          <DialogField id='divider' label=''>
            <Divider />
          </DialogField>

          <DialogField
            id='lead-download-preferences'
            label='Lead Download Preferences'
            alignSelf={'flex-start'}
          >
            <SingleSelectDropdown
              id='lead-download-preferences'
              name='leadDownloadPreferences'
              control={control}
              rules={{
                required: 'Lead Download Preferences is a required field',
              }}
              style={matches ? { width: '279px' } : {}}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setDownloadPreference(e.target.value)
              }}
              values={['Comma Separated Values']}
            />
            <div style={{ marginTop: '10px' }}>
              <Checkbox
                icon={<CheckboxUnchecked />}
                checkedIcon={<CheckboxChecked />}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setIsZip(e.target.checked)
                }}
              />
              <DownloadFile>Download the file in ZIP format</DownloadFile>
            </div>
          </DialogField>
        </DialogFieldContainer>

        <DialogActionButtons>
          <CancelButton onClick={closeDialog}>Cancel</CancelButton>
          <AddButton disabled={disableSubmit} onClick={addDestinationValues}>Add</AddButton>
        </DialogActionButtons>
      </Dialog>
    )
  }
)
