export enum ContractType {
  annual = 'annual',
  on_demand = 'on_demand',
}

export enum ContractTypeReadable {
  annual = 'Annual',
  on_demand = 'On-Demand',
}

export enum ContractSource {
  candidates = 'candidates',
  inquiries = 'inquiries',
}

export enum ContractStatus {
  pending = 'pending',
  in_progress = 'in_progress',
  completed = 'completed',
  expired = 'expired',
  paused = 'paused',
  flagged = 'flagged',
}

export enum PurchaseType {
  direct_purchase = 'Direct Purchase',
  eab_internal = 'EAB Internal',
}
