import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'

import { Destination } from 'Services/configuration/destinations'
import { FileFormat } from 'Types/index'


interface DeliveryCriteriaState {
  allDestinations: Destination[]
  selectedDestinations: Destination[]
  selectedFileFormat: FileFormat
  selectedDeliveryCadence: string[]
  selectedDeliveryModel: string
  selectedFields: string[]
  allLeads: string[]
  selectedLeads: string[]
  allFileFormats: string[]
  allDeliveryCadence: string[]
  allDeliveryModel: string[]
}

const allDeliveryCadence = [
  'Weekly, Monday',
  'Weekly, Tuesday',
  'Weekly, Wednesday',
  'Weekly, Thursday',
  'Weekly, Friday',
  'Weekly, Saturday',
  'Weekly, Sunday',
]
const allDeliveryModel = ['Deliver inquiries until cap is hit']

const initialState: DeliveryCriteriaState = {
  allDestinations: [],
  selectedDestinations: [],
  selectedFileFormat: FileFormat.CSV,
  selectedDeliveryCadence: [],
  selectedDeliveryModel: '',
  selectedFields: [],
  allLeads: [],
  selectedLeads: [],
  allFileFormats: Object.values(FileFormat),
  allDeliveryCadence,
  allDeliveryModel,
}

const deliveryCriteriaSlice = createSlice({
  name: 'timeBasedDeliveryCriteriaDetails',
  initialState,
  reducers: {
    setAllDestinations: (state, action: PayloadAction<Destination[]>) => {
      state.allDestinations = action.payload
    },

    setSelectedDestinations: (state, action: PayloadAction<Destination[]>) => {
      state.selectedDestinations = action.payload
    },
    setServerSelectedDestinations: (
      state,
      action: PayloadAction<Destination[]>
    ) => {
      state.selectedDestinations = action.payload
    },

    setSelectedFileFormat: (state, action: PayloadAction<FileFormat>) => {
      state.selectedFileFormat = action.payload
    },

    setServerSelectedFileFormat: (state, action: PayloadAction<FileFormat>) => {
      state.selectedFileFormat = action.payload
    },

    setSelectedDeliveryCadence: (state, action: PayloadAction<string[]>) => {
      state.selectedDeliveryCadence = action.payload
    },

    setServerSelectedDeliveryCadence: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.selectedDeliveryCadence = action.payload
    },

    setSelectedDeliveryModel: (state, action: PayloadAction<string>) => {
      state.selectedDeliveryModel = action.payload
    },

    setServerSelectedDeliveryModel: (state, action: PayloadAction<string>) => {
      state.selectedDeliveryModel = action.payload
    },

    setSelectedFields: (state, action: PayloadAction<string[]>) => {
      state.selectedFields = action.payload
    },

    setServerSelectedFields: (state, action: PayloadAction<string[]>) => {
      state.selectedFields = action.payload
    },

    setAllLeads: (state, action: PayloadAction<string[]>) => {
      state.allLeads = action.payload
    },

    setSelectedLeads: (state, action: PayloadAction<string[]>) => {
      state.selectedLeads = action.payload
    },

    setServerSelectedLeads: (state, action: PayloadAction<string[]>) => {
      state.selectedLeads = action.payload
    },
    clearTimeBasedDeliveryCriteria: () => {
      return initialState
    },
  },
})

export const selectTimeBasedDeliveryCriteriaDetails = createSelector(
  (state: RootState) => state.timeBasedDeliveryCriteriaDetails.allDestinations,
  (state: RootState) =>
    state.timeBasedDeliveryCriteriaDetails.selectedDestinations,
  (state: RootState) =>
    state.timeBasedDeliveryCriteriaDetails.selectedFileFormat,
  (state: RootState) =>
    state.timeBasedDeliveryCriteriaDetails.selectedDeliveryCadence,
  (state: RootState) =>
    state.timeBasedDeliveryCriteriaDetails.selectedDeliveryModel,
  (state: RootState) => state.timeBasedDeliveryCriteriaDetails.selectedFields,
  (state: RootState) => state.timeBasedDeliveryCriteriaDetails.allLeads,
  (state: RootState) => state.timeBasedDeliveryCriteriaDetails.selectedLeads,
  (
    allDestinations,
    selectedDestinations,
    selectedFileFormat,
    selectedDeliveryCadence,
    selectedDeliveryModel,
    selectedFields,
    allLeads,
    selectedLeads
  ): DeliveryCriteriaState => {
    return {
      allDestinations,
      selectedDestinations,
      selectedFileFormat,
      selectedDeliveryCadence,
      selectedDeliveryModel,
      selectedFields,
      allLeads,
      selectedLeads,
      allFileFormats: Object.values(FileFormat),
      allDeliveryCadence,
      allDeliveryModel,
    }
  }
)

export const {
  setAllDestinations,
  setSelectedDestinations,
  setServerSelectedDestinations,
  setSelectedFileFormat,
  setServerSelectedFileFormat,
  setSelectedDeliveryCadence,
  setServerSelectedDeliveryCadence,
  setSelectedDeliveryModel,
  setServerSelectedDeliveryModel,
  setSelectedFields,
  setServerSelectedFields,
  setSelectedLeads,
  setServerSelectedLeads,
  clearTimeBasedDeliveryCriteria,
} = deliveryCriteriaSlice.actions

export default deliveryCriteriaSlice.reducer
