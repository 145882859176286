import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'
import {
  ContractSection,
  ContractSectionHeader,
  ContractConfigurationFieldContainer,
} from 'Components/shared/contracts'
import { DialogField } from 'Components/shared/dialog'
import { TransferList } from 'Components/shared/transferlist'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { VirtualizedMultiSelect } from 'Components/shared/multiselectdropdown'
import { Table } from 'Components/shared/table'
import { useGetAllDestinationsQuery } from 'Services/configuration/destinations'
import { useGetSupportedColumnsQuery } from 'Services/global/introspection'
import { useGetUsersQuery } from 'Services/configuration/users'
import { useGetContractConfigQuery } from 'Services/global/contractConfig'
import { Loading } from 'Utils/loading'
import { ContractSource } from 'Types/index'

const Divider = styled('div')(() => ({
  height: '1px',
  width: '421px',
  marginTop: '20px',
  marginBottom: '10px',
  background: 'hsla(223, 70%, 92%, 1)',
}))

const columnData = [
  { columnName: 'Name', columnWidth: 260, sortOrder: 'ASC' },
  { columnName: 'Email', columnWidth: 250, sortOrder: 'ASC' },
]

interface DeliveryCriteriaProps {
  audienceType: string
  allFileFormats?: string[]
  allDeliveryModel?: string[]
  allDestinations: any[]
  allDeliveryCadence?: string[]
  selectedDestinations: any[]
  selectedFileFormat: string
  selectedDeliveryCadence?: string[]
  selectedDeliveryModel?: string
  selectedFields: string[]
  selectedLookbackPeriod?: string
  allLookbackPeriods?: string[]
  allLeads: string[]
  selectedLeads: string[]
  contractSource?: ContractSource
  control: any
  setAllDestinations: (allDestinations: any[]) => any
  setSelectedDestinations: (selectedDestinations: any[]) => any
  setSelectedFileFormat: (selectedFileFormat: string) => any
  setSelectedDeliveryCadence?: (selectedDeliveryCadence: string[]) => any
  setSelectedDeliveryModel?: (selectedDeliveryModel: string) => any
  setSelectedFields: (selectedFields: string[]) => any
  setSelectedLeads: (selectedLeads: string[]) => any
  setSelectedLookbackPeriod?: (selectedLookbackPeriod: string) => any
}

export const DeliveryCriteria = ({
  audienceType,
  allFileFormats,
  allDeliveryModel,
  allDestinations,
  allDeliveryCadence,
  selectedDestinations,
  selectedFileFormat,
  selectedDeliveryCadence,
  selectedDeliveryModel,
  allLookbackPeriods,
  selectedLookbackPeriod,
  selectedFields,
  allLeads,
  selectedLeads,
  contractSource,
  control,
  setAllDestinations,
  setSelectedDestinations,
  setSelectedFileFormat,
  setSelectedDeliveryCadence,
  setSelectedDeliveryModel,
  setSelectedFields,
  setSelectedLeads,
  setSelectedLookbackPeriod,
}: DeliveryCriteriaProps) => {
  const theme = useTheme()
  const { ipedId } = useParams()
  const dispatch = useDispatch()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const { data: contractConfig, isFetching: isFetchinContractConfig } =
    useGetContractConfigQuery({
      contractSource,
      audienceType,
    })

  const [allUsers, setAllUsers] = useState([])
  const [rowIdxs, setRowIdxs] = useState<boolean[]>([])
  const [selectAll, setSelectAll] = useState(true)

  /** Notifications */
  const { data: usersInfo } = useGetUsersQuery(ipedId)
  /** Destinations */
  const { data: destinationsInfo, isLoading: isLoadingDestinations } =
    useGetAllDestinationsQuery({
      ipedId,
    })
  const { data: supportedColumns } = useGetSupportedColumnsQuery()
  useEffect(() => {
    let rows = []
    if (usersInfo) {
      usersInfo?.forEach((user: any) => {
        const fullName = user.first_name + ' ' + user.last_name
        rows.push(['', fullName, user.email])
      })
      setAllUsers(rows)
      setRowIdxs(new Array(rows.length).fill(true))
    }
    dispatch(setSelectedLeads(usersInfo?.map((user: any) => user.id)))
  }, [usersInfo])

  useEffect(() => {
    // When we change source or audience type lets update our fields and lookback period
    dispatch(setSelectedFields(contractConfig?.fields_to_export))
    setSelectedLookbackPeriod &&
      dispatch(
        setSelectedLookbackPeriod(contractConfig?.default_lookback_period)
      )
  }, [contractConfig])

  const handleAllClick = (flag: boolean) => {
    const arr = new Array(rowIdxs.length)
    setSelectAll(flag)
    setRowIdxs(rowIdxs.map(() => flag))
    if (flag) {
      dispatch(setSelectedLeads(usersInfo?.map((user: any) => user.id)))
    } else {
      dispatch(setSelectedLeads([]))
    }
  }

  const handleRowClick = (rowIdx: number) => {
    const newRows = [...rowIdxs]
    newRows[rowIdx] = !newRows[rowIdx]
    setRowIdxs(newRows)
    const allTrue = newRows.every((element) => element === true)
    setSelectAll(allTrue)
    let result = []
    usersInfo?.forEach((user: any, idx: number) => {
      if (newRows[idx]) result.push(user.id)
    })
    dispatch(setSelectedLeads(result))
  }

  const handleSelectedDestinations = (selectedDestinationLabels: string[]) => {
    if (allDestinations) {
      let selectedDestinations = []
      allDestinations.forEach((destination: any) => {
        if (selectedDestinationLabels.includes(destination.label))
          selectedDestinations.push(destination)
      })
      dispatch(setSelectedDestinations(selectedDestinations))
    }
  }

  const handleSelectedDeliveryCadence = (selectedCadenceLabels: string[]) => {
    if (selectedCadenceLabels.length > 0) {
      dispatch(setSelectedDeliveryCadence(selectedCadenceLabels))
    }
  }
  useEffect(() => {
    if (destinationsInfo) {
      dispatch(setAllDestinations(destinationsInfo))
    }
  }, [destinationsInfo])

  if (isLoadingDestinations || isFetchinContractConfig) return <Loading />

  return (
    <ContractSection>
      <ContractSectionHeader label={'Delivery Criteria'} />
      <ContractConfigurationFieldContainer>
        <DialogField id='add-delivery-destination' label='Delivery Destination'>
          <VirtualizedMultiSelect
            label={''}
            counterLabel={'Destinations'}
            values={
              allDestinations?.map((destination: any) => destination?.label) ||
              []
            }
            initialSelectedValues={
              selectedDestinations?.map(
                (destination: any) => destination?.label
              ) || []
            }
            selectedValues={
              selectedDestinations?.map(
                (destination: any) => destination?.label
              ) || []
            }
            setSelectedValues={handleSelectedDestinations}
          />
        </DialogField>
        <DialogField id='add-file-format' label='*File Format to Deliver'>
          <SingleSelectDropdown
            id='add-file-format-select'
            name='fileFormat'
            style={matches ? { width: '279px' } : {}}
            rules={{
              required: 'File Format is a required field',
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(setSelectedFileFormat(e.target.value))
            }
            values={allFileFormats}
            value={selectedFileFormat}
          />
        </DialogField>

        {contractConfig?.fields_available.includes('delivery_lookback') && (
          <DialogField id='add-delivery-lookback' label='*Delivery Look-Back'>
            <SingleSelectDropdown
              id='add-delivery-lookback'
              name='deliveryLookback'
              style={matches ? { width: '279px' } : {}}
              rules={{
                required: 'Delivery Look-Back is a required field',
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSelectedLookbackPeriod &&
                dispatch(setSelectedLookbackPeriod(e.target.value))
              }
              values={allLookbackPeriods}
              value={
                selectedLookbackPeriod ||
                contractConfig?.default_lookback_period
              }
            />
          </DialogField>
        )}

        {contractConfig?.fields_available.includes('delivery_cadence') && (
          <DialogField id='add-delivery-cadence' label='*Delivery Cadence'>
            <VirtualizedMultiSelect
              label={''}
              counterLabel={'Cadence'}
              values={allDeliveryCadence || []}
              initialSelectedValues={selectedDeliveryCadence}
              selectedValues={selectedDeliveryCadence || []}
              setSelectedValues={handleSelectedDeliveryCadence}
            />
          </DialogField>
        )}

        {contractConfig?.fields_available.includes('delivery_model') && (
          <DialogField id='add-delivery-model' label='*Delivery Model'>
            <SingleSelectDropdown
              id='add-delivery-model-select'
              style={matches ? { width: '279px' } : {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(setSelectedDeliveryModel('lead_cap_reached'))
              }
              values={allDeliveryModel}
              value={selectedDeliveryModel}
            />
          </DialogField>
        )}

        <DialogField id='divider' label=''>
          <Divider />
        </DialogField>

        <DialogField
          id='add-fields-to-export'
          label='Fields to Export'
          alignSelf='flex-start'
        >
          <TransferList
            allItems={
              contractConfig?.allow_export_fields
                ? supportedColumns
                : contractConfig?.fields_to_export
            }
            exportItems={(selectedItems: string[]) =>
              dispatch(setSelectedFields(selectedItems))
            }
            exportedItems={contractConfig?.fields_to_export}
            disabled={!contractConfig?.allow_export_fields}
          />
        </DialogField>
        <DialogField id='divider' label=''>
          <Divider />
        </DialogField>
        <DialogField
          id='automatic-lead-report'
          label='Automatic Lead Report Notification'
          alignSelf='flex-start'
        >
          <Table
            width={'516px'}
            height={'248px'}
            columnData={columnData}
            rows={allUsers}
            variant='striped'
            isSelectable={true}
            selectedAll={selectAll}
            handleAllClick={handleAllClick}
            rowIndexes={rowIdxs}
            handleRowClick={handleRowClick}
            handleCheckboxClick={(_, idx: number) => handleRowClick(idx)}
          />
        </DialogField>
      </ContractConfigurationFieldContainer>
    </ContractSection>
  )
}
