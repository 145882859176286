import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { AudienceTypeHelper } from 'Types/index'
import type { RootState } from 'Utils/store'

const initialState = {
  contractName: '',
  audienceType: AudienceTypeHelper.getNone(),
  leadCap: 0,
  startDate: '',
  endDate: '',
  notes: '',
  salesforceID: '',
}

interface ContractConfigurationState {
  /** The name of the contract */
  contractName: string
  /** The type of audience */
  audienceType: string
  /** The lead cap of the contract */
  leadCap: number
  /** The start date of the contract */
  startDate: string
  /** The end date of the contract */
  endDate: string
  /** The notes of the contract */
  notes: string
  /** The salesforce ID of the contract */
  salesforceID: string
}

export const ContractConfigurationSlice = createSlice({
  name: 'timeBasedContractConfiguration',
  initialState,
  reducers: {
    setContractName: (state, action: PayloadAction<string>) => {
      state.contractName = action.payload
    },
    setAudienceType: (state, action: PayloadAction<string>) => {
      state.audienceType = action.payload
    },
    setLeadCap: (state, action: PayloadAction<number>) => {
      state.leadCap = action.payload
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload
    },

    setNotes: (state, action: PayloadAction<string>) => {
      state.notes = action.payload
    },
    setSalesforceID: (state, action: PayloadAction<string>) => {
      state.salesforceID = action.payload
    },

    clearContractConfiguration: () => {
      return initialState
    },
  },
})

export const selectTimeBasedContractConfiguration = createSelector(
  (state: RootState) => state.timeBasedContractConfiguration.contractName,
  (state: RootState) => state.timeBasedContractConfiguration.audienceType,
  (state: RootState) => state.timeBasedContractConfiguration.salesforceID,
  (state: RootState) => state.timeBasedContractConfiguration.leadCap,
  (state: RootState) => state.timeBasedContractConfiguration.startDate,
  (state: RootState) => state.timeBasedContractConfiguration.endDate,
  (state: RootState) => state.timeBasedContractConfiguration.notes,
  (
    contractName,
    audienceType,
    salesforceID,
    leadCap,
    startDate,
    endDate,
    notes
  ): ContractConfigurationState => {
    return {
      contractName,
      audienceType,
      salesforceID,
      leadCap,
      startDate,
      endDate,
      notes,
    }
  }
)

export const {
  setContractName,
  setAudienceType,
  setSalesforceID,
  setLeadCap,
  setStartDate,
  setEndDate,
  setNotes,
  clearContractConfiguration,
} = ContractConfigurationSlice.actions

export default ContractConfigurationSlice.reducer
