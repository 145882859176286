import React from 'react'
import { Routes, Route } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { Login } from 'Containers/shared/login'
import { ForgotPassword } from 'Containers/shared/forgotpassword'
import { Header } from 'Containers/header'
import { Contracts, Files } from 'Containers/inquiries'
import { AddInquiriesContract } from 'Containers/inquiries/contracts'
import {
  Contracts as CandidatesContracts,
  Files as CandidatesFiles,
} from 'Containers/candidates'
import { AddCandidatesContract } from 'Containers/candidates/contracts'
import { ContractForm } from 'Components/connectedComponents/contracts'
import { CandidatesContractDetails } from 'Containers/candidates/contractdetails/CandidatesContractDetails'
import {
  Destinations,
  ManageUsers,
  SSHKeyManagement,
  ZipCodes,
} from 'Containers/configuration'
import { Explore } from 'Containers/shared/segmentdisplay'

import { SegmentQueue } from 'Containers/admin/segmentqueue'
import { ActiveSegments } from 'Containers/admin/hipLeads'
import { LeadFileStatus } from 'Containers/admin/leadfilestatus'

import { SnackbarContainer } from 'Containers/shared/snackbar/Snackbar'
import { CheckPermissions } from 'Containers/shared/permissions'
import { Permissions } from 'Configs/userPermissions'

import { Providers } from 'Utils/Providers'
import { Router } from 'Utils/router'
import { VersionInfo } from 'Components/shared/version'

Bugsnag.start({
  apiKey: `${process.env.REACT_APP_BUGSNAG_API_KEY}`,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production', 'staging', 'development'],
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const InquiriesRoutes = () => {
  return (
    <Routes>
      <Route index element={<Contracts />} />
      <Route path='contracts' element={<Contracts />} />
      <Route path='contracts/segment/:segmentId' element={<Contracts />} />
      <Route path='files' element={<Files />} />
      <Route
        path='contracts/new'
        element={
          <CheckPermissions permission={Permissions.CreateContract}>
            <AddInquiriesContract />
          </CheckPermissions>
        }
      />
      <Route
        path='contracts/:contractId'
        element={<ContractForm />}
      />
    </Routes>
  )
}
const CandidatesRoutes = () => {
  return (
    <Routes>
      <Route index element={<CandidatesContracts />} />
      <Route path='contracts' element={<CandidatesContracts />} />
      <Route
        path='contracts/segment/:segmentId'
        element={<CandidatesContracts />}
      />
      <Route path='files' element={<CandidatesFiles />} />

      <Route
        path='contracts/new'
        element={
          <CheckPermissions permission={Permissions.CreateContract}>
            <AddCandidatesContract />
          </CheckPermissions>
        }
      />
      <Route
        path='contracts/:contractId'
        element={<CandidatesContractDetails />}
      />
      <Route
        path='explore'
        element={<Explore />}
      />
    </Routes>
  )
}

const ConfigurationRoutes = () => {
  return (
    <Routes>
      <Route index element={<Destinations />} />
      <Route path='destinations' element={<Destinations />} />
      <Route path='manageusers' element={<ManageUsers />} />
      <Route path='managekeys' element={<SSHKeyManagement />} />
      <Route path='zipcodes' element={<ZipCodes />} />
    </Routes>
  )
}

const AdminRoutes = () => {
  return (
    <Routes>
      <Route index element={<SegmentQueue />} />
      <Route path='segments-queue' element={<SegmentQueue />} />
      <Route path='lead-file-status' element={<LeadFileStatus />} />
      <Route path='hip-leads' element={<ActiveSegments />} />
    </Routes>
  )
}

export const App = () => {
  return (
    <ErrorBoundary>
      <Providers>
        <CssBaseline />
        <SnackbarContainer />
        <Router>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/forgot' element={<ForgotPassword />} />
            <Route element={<Header />}>
              <Route path='inquiries/*' element={<InquiriesRoutes />} />
              <Route
                path='institution/:ipedId/inquiries/*'
                element={<InquiriesRoutes />}
              />
              <Route
                path='institution/:ipedId/candidates/*'
                element={<CandidatesRoutes />}
              />
              <Route
                path='institution/:ipedId/configuration/*'
                element={<ConfigurationRoutes />}
              />
              <Route
                path='admin/*'
                element={
                  <CheckPermissions permission={Permissions.SwitchInstitution}>
                    <AdminRoutes />
                  </CheckPermissions>
                }
              />
            </Route>
          </Routes>
        </Router>
      </Providers>
      <VersionInfo />
    </ErrorBoundary>
  )
}
