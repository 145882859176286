import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FilterHeader } from 'Components/shared/segmentfilters'
import { Verification } from 'Containers/shared/segmentfilters/timebased/verification'
import { Demographics } from 'Containers/shared/segmentfilters/timebased/demographics'
import { ProspectProfile } from 'Containers/shared/segmentfilters/timebased/prospectprofile'
import { useCreateSegmentMutation } from 'Services/segments/segments'
import { selectNewAnnualSegment } from 'Containers/inquiries/segment/NewAnnualSegmentSlice'
import { SidebarActionButtons } from 'Components/shared/segmentfilters/SidebarActionButtons'
import { AddAnnual } from 'Containers/inquiries/segment/AddAnnual'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { Loading } from 'Utils/loading'
import { ContractSource, WebSocketMethods } from 'Types/index'
import { useSocket } from 'Hooks/useSocket'
import { excludeKeys } from 'Containers/shared/segmentfilters/segmentUtils'
import { messageSent } from 'Slices/websocketSlice'
import type { RootState } from 'Utils/store'
import { selectGeography } from 'Containers/shared/segmentfilters/timebased/demographics/GeographySlice'

interface TimeBasedFilterProps {
  /** Boolean to show/hide sidebar */
  showSidebar: boolean
  /** Handle logic when closing the sidebar */
  handleCloseSidebar: () => void
}

export const TimeBasedFilter = ({
  showSidebar,
  handleCloseSidebar,
}: TimeBasedFilterProps) => {
  const dispatch = useDispatch()
  const { ipedId } = useParams()
  const {
    control,
    formState: { errors: formErrors, isValid },
    setError: setFormError,
    clearErrors: clearFormErrors,
  } = useForm({
    mode: 'onChange',
  })
  const timeBasedFilter = useSelector(
    (state: RootState) => state.timeBasedFilter
  )
  const geography = useSelector(selectGeography)
  const [resetKey, setResetKey] = useState(Date.now())

  const { contract, noOfLeads, segmentName } = useSelector(
    selectNewAnnualSegment
  )
  const [studentsAvailable, setStudentsAvailable] = useState(null)
  const { sendMessage } = useSocket(setStudentsAvailable)
  const { waitingForResponse: isFetchingStudentsAvailable } = useSelector(
    (state: RootState) => state.websocketStatus
  )

  const [createSegments, { isLoading }] = useCreateSegmentMutation()

  /** Disable form submission if any reqired field is not filled
   * (or) there are any form errors */
  const disableFormSubmit = !isValid || Object.keys(formErrors).length > 0

  const resetFilters = () => {
    dispatch({
      type: 'timeBasedFilter/reset',
    })
    setResetKey(Date.now())
  }

  const getAvailableStudents = () => {
    const message = {
      method: WebSocketMethods.students_available_count_contract_id,
      data: {
        contract_id: contract?.id,
        institution_id: ipedId,
        contract_source: ContractSource.inquiries,
        audience_type: contract?.audience_type,
        filter: buildFilterQuery(
          timeBasedFilter,
          excludeKeys(ContractSource.inquiries, contract?.audience_type),
          false
        ),
      },
      override_sources: ['inquiries']
    }

    if (geography.zipFile.id) {
      message.data['zip_id'] = geography.zipFile.id
      message.data['zip_filter_exclusive'] = false
    }

    sendMessage(JSON.stringify(message))
    dispatch(messageSent())
  }

  const activateSegment = () => {
    const filterQuery = buildFilterQuery(
      timeBasedFilter,
      excludeKeys(ContractSource.inquiries, contract?.audience_type),
      false
    )
    const segmentObj = {
      ipedId,
      name: segmentName,
      contract_id: contract.id,
      lead_total: noOfLeads,
      filter: filterQuery,
    }
    if (geography.zipFile.id) {
      segmentObj['zip_id'] = geography.zipFile.id
      segmentObj['zip_filter_exclusive'] = false
    }

    createSegments(segmentObj)
      .unwrap()
      .then(() => {
        handleCloseSidebar()
      })
  }

  const studentsCount = studentsAvailable?.hasOwnProperty('total_count') ? studentsAvailable.total_count : studentsAvailable

  return (
    <>
      {isLoading && <Loading />}
      <div
        style={{
          width: '396px',
        }}
      >
        <AddAnnual
          control={control}
          setFormError={setFormError}
          clearFormErrors={clearFormErrors}
          showSidebar={showSidebar}
        />
        <FilterHeader
          availableStudents={studentsCount || 0}
          isFetchingStudentsAvailable={isFetchingStudentsAvailable}
          resetFilters={resetFilters}
          disableHeader={contract?.id ? false : true}
          getAvailableStudents={getAvailableStudents}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            marginBottom: '100px',
          }}
        >
          <Demographics
            chosenItem={contract}
            control={control}
            resetKey={resetKey}
          />
          <ProspectProfile chosenItem={contract} resetKey={resetKey} />
          <Verification chosenItem={contract} />
        </div>

        <SidebarActionButtons
          handleSegmentAction={activateSegment}
          handleCloseSidebar={handleCloseSidebar}
          disableFormSubmit={disableFormSubmit || isLoading}
        />
      </div>
    </>
  )
}
