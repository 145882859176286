import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'

const initialState = {
  contractName: '',
  salesforceID: '',
  startDate: '',
  endDate: '',
  notes: '',
  leadCap: 0,
}

interface ContractConfigurationState {
  /** The name of the contract */
  contractName: string
  /** The salesforce ID of the contract */
  salesforceID: string
  /** The start date of the contract */
  startDate: string
  /** The end date of the contract */
  endDate: string
  /** The lead cap of the contract */
  leadCap: number
  /** The notes of the contract */
  notes: string
}

export const ContractConfigurationDetailsSlice = createSlice({
  name: 'timeBasedContractConfigurationDetails',
  initialState,
  reducers: {
    setSalesforceID: (state, action: PayloadAction<string>) => {
      state.salesforceID = action.payload
    },
    setServerSalesForceID: (state, action: PayloadAction<string>) => {
      state.salesforceID = action.payload
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload
    },
    setServerStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload
    },

    setServerEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload
    },
    setServerLeadCap: (state, action: PayloadAction<number>) => {
      state.leadCap = action.payload
    },
    setLeadCap: (state, action: PayloadAction<number>) => {
      state.leadCap = action.payload
    },

    setNotes: (state, action: PayloadAction<string>) => {
      state.notes = action.payload
    },

    setServerNotes: (state, action: PayloadAction<string>) => {
      state.notes = action.payload
    },

    setContractName: (state, action: PayloadAction<string>) => {
      state.contractName = action.payload
    },

    clearContractConfiguration: () => {
      return initialState
    },
  },
})

export const selectTimeBasedContractConfigurationDetails = createSelector(
  (state: RootState) =>
    state.timeBasedContractConfigurationDetails.contractName,
  (state: RootState) =>
    state.timeBasedContractConfigurationDetails.salesforceID,
  (state: RootState) => state.timeBasedContractConfigurationDetails.startDate,
  (state: RootState) => state.timeBasedContractConfigurationDetails.endDate,
  (state: RootState) => state.timeBasedContractConfigurationDetails.leadCap,
  (state: RootState) => state.timeBasedContractConfigurationDetails.notes,
  (
    contractName,
    salesforceID,
    startDate,
    endDate,
    leadCap,
    notes
  ): ContractConfigurationState => {
    return {
      contractName,
      salesforceID,
      startDate,
      endDate,
      leadCap,
      notes,
    }
  }
)

export const {
  setContractName,
  setSalesforceID,
  setServerSalesForceID,
  setStartDate,
  setServerStartDate,
  setEndDate,
  setServerEndDate,
  setLeadCap,
  setServerLeadCap,
  setNotes,
  setServerNotes,
  clearContractConfiguration,
} = ContractConfigurationDetailsSlice.actions

export default ContractConfigurationDetailsSlice.reducer
