import { ContractType, ContractSource, ContractStatus } from 'Types/index'

export interface Contract {
  id: string
  ipedId: string
  created_at: string
  label: string
  contract_type: ContractType
  audience_type: string
  purchase_type?: string
  salesforce_id: string
  lead_cap: number
  source?: ContractSource
  start_date: string
  end_date: string
  notes: string
  status: ContractStatus
  fields_to_export: string[]
  delivery_cadence: string[]
  delivery_model: string
  look_back_period?: number
}
/** Get all contracts for an institution */

export interface GetContractsApiResponse {
  data: GetContractsTransformedResponse
}

export interface GetContractsTransformedResponse {
  records: Array<Contract>
  total_pages: number
  total_records: number
}

export const transformContractsQueryResponse = (
  response: GetContractsApiResponse
) => {
  const contracts: GetContractsTransformedResponse = {
    records: response.data.records,
    total_pages: response.data.total_pages,
    total_records: response.data.total_records,
  }
  return contracts
}

export const contractLabelSearchQuery = (label: string) => ({
  query: {
    filterQuery: {
      properties: [
        {
          properties: [
            {
              column: 'label',
              op: 'eq',
              value: label,
            },
          ],
          operation: 'AND',
        },
      ],
      operation: 'AND',
    },
  },
})

export const contractsQuery = ({ ipedId, page, size, order, filter }) => ({
  url: `institutions/${ipedId}/contracts?page=${page}&size=${size}&sort_by=audience_type::${order}&filter=${filter}`,
})

/** Get a contract by id */

export interface ContractApiResponse {
  data: Contract
}

export const transformContractByIdQueryResponse = (
  response: ContractApiResponse
): Contract => {
  return response.data
}

export const getContractByIdquery = ({ ipedId, contractId }) => ({
  url: `institutions/${ipedId}/contracts/${contractId}`,
})

export const searchContractByLabel = ({ ipedId, filterQuery }) => ({
  url: `institutions/${ipedId}/contracts?filter=${filterQuery}`,
})

export const getSubscribedNotifications = ({ ipedId, contractId }) => ({
  url: `institutions/${ipedId}/contracts/${contractId}/notifications`,
})

export const createContract = ({ ipedId, ...args }) => ({
  url: `institutions/${ipedId}/contracts`,
  method: 'POST',
  body: args,
})

export const addDestinationsToContract = ({
  ipedId,
  contractId,
  destinationIds,
}) => ({
  url: `institutions/${ipedId}/contracts/${contractId}/destinations`,
  method: 'PATCH',
  body: { destination_ids: destinationIds },
})

export const getUserContractNotifications = ({ ipedId, contractId }) => ({
  url: `institutions/${ipedId}/contracts/${contractId}/notifications`,
  method: 'GET',
})

export const subscribeUsertoContractNotifications = ({
  ipedId,
  contractId,
  accountIdsEnabled,
}) => ({
  url: `institutions/${ipedId}/contracts/${contractId}/notifications`,
  method: 'PATCH',
  body: { account_ids_enabled: accountIdsEnabled },
})

export const updateContract = ({ ipedId, contractId, contract }) => ({
  url: `institutions/${ipedId}/contracts/${contractId}`,
  method: 'PUT',
  body: contract,
})
