import React from 'react'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
  ContractSection,
  ContractSectionHeader,
  ContractConfigurationFieldContainer,
} from 'Components/shared/contracts'
import {
  AddFileButton,
  Deduplicates as DeduplicatesComponent,
  DeduplicatesField,
} from 'Components/shared/contracts/deduplicates'
import { Loading } from 'Utils/loading'
import useFileUpload from 'Hooks/useFileUpload'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { FileCategory } from 'Types/fileCategory'

const Added = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark,
}))

const columnData = [
  {
    columnName: 'File Name',
    columnWidth: 155,
    sortOrder: 'ASC',
  },
  {
    columnName: '',
    columnWidth: 20,
    sortOrder: 'ASC',
  },
]

interface DeduplicatesContainerProps {
  fileName?: String
}

export const DeduplicatesContainer = ({
  fileName,
}: DeduplicatesContainerProps) => {
  const { ipedId } = useParams()
  const {
    isInvalidSize,
    isFileAdded,
    isUploadingData,
    isUploadingFile,
    inputRef,
    handleAddFileClick,
    handleFileChange,
  } = useFileUpload(ipedId, FileCategory.ContractDeduplication)

  const { hasPermission: canUploadFile } = useHasPermission(
    Permissions.AddContractDedup
  )

  const rows = [['', fileName, isFileAdded ? <Added>Added</Added> : '']]

  if (isUploadingData || isUploadingFile) return <Loading />

  return (
    <ContractSection>
      <ContractSectionHeader label={'De-duplicates'} />
      <ContractConfigurationFieldContainer>
        <DeduplicatesField id='add-deduplicate' label='De-duplicates'>
          {canUploadFile && (
            <AddFileButton onClick={handleAddFileClick}>
              <input
                ref={inputRef}
                type='file'
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept='.csv, .txt'
              />
            </AddFileButton>
          )}
        </DeduplicatesField>
        <DeduplicatesComponent
          columnData={columnData}
          rows={rows}
          invalidSize={isInvalidSize}
        />
      </ContractConfigurationFieldContainer>
    </ContractSection>
  )
}
