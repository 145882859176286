import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import { Input, ErrorMessage } from 'Components/shared/input'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import EabLogo from 'Assets/images/eab_logo.svg'
import { useState, useCallback } from 'react'
import { isValidEmail } from 'Utils/validation'

const Container = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  minHeight: '670px',
}))

const SSOContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '30px',
}))

const LoginHeading = styled('div')(({ theme }) => ({
  color: theme.palette.secondary['100'],
  fontSize: '1.5rem',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '30px',
}))

const LoginSubHeading = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '0.875rem',
  lineHeight: '18px',
  marginTop: '13px',
  marginBottom: '42px',
}))

const CustomLogin = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
}))

const LoginBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  gap: '30px',
  borderRadius: '8px',
  boxShadow: `0px 4px 8px ${alpha(theme.palette.secondary['100'], 0.1)}`,
  padding: '30px',
  width: 'min(885px, 80%)',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: '63px',
    padding: '84px 99px 66px 99px',
    '& > *': {
      width: '280px',
    },
  },
}))

const LoginBtnText = styled('span')(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '0.875rem',
  height: '18px',
  lineheight: '18px',
  textalign: 'center',
  width: '36px',
}))

const SSOLoginBtnText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '18px',
  textAlign: 'center',
}))

const InputFlexContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
}))

const ForgotPassword = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '0.875rem',
  lineHeight: '18px',
  marginTop: '7px',
  marginBottom: '20px',
  textDecoration: 'none',
}))

const ForgotPasswordText = styled('b')(({ theme }) => ({
  color: theme.palette.primary.dark
}))

const NeedHelp = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  marginTop: '62px',
  textDecorationLine: 'underline',
}))

const Divider = styled('div')(({ theme }) => ({
  background: theme.palette.grey['300'],
  height: '1px',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    alignSelf: 'center',
    height: '254px',
    width: '1px',
  },
}))

const LoginButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '100%',
  height: '48px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.9)}`,
  },
}))

const SSOLoginButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: `1.5px solid ${theme.palette.primary.main}`,
  height: '42px',
  width: '100%',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.2)}`,
  },
  '&:active': {
    position: 'relative',
    top: '1px',
    left: '1px',
  },
}))

const CustomLink = styled(Link)(() => ({
  textDecoration: 'none',
}))

interface LoginCredentials {
  email: string;
  password: string;
}

interface LoginProps {
  /** Function to handle login with Microsoft SSO */
  onSSOClick: () => void
  onLoginClick?: (credentials: LoginCredentials) => void;
  loginError?: boolean
}

export const Login = ({ onSSOClick, onLoginClick, loginError }: LoginProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validationError, setValidationError] = useState(undefined);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLoginClick = useCallback(() => {
    if (!email || !password) {
      setValidationError('Missing required fields')
    } else if(!isValidEmail(email)) {
      setValidationError('Invalid email')
    }else {
      setValidationError(undefined)
      onLoginClick({email, password})
    }
  }, [email, password]);

  const border = (loginError || validationError) ? '1px solid #e34c4c' : undefined

  return (
    <Container>
      <LoginBox>
        <CustomLogin>
          <LoginHeading>Login</LoginHeading>
          <LoginSubHeading>Enter your email and address below</LoginSubHeading>
          <InputFlexContainer>
            <Input
              placeholder='Email'
              style={{ width: '100%' }}
              border={border}
              value={email}
              onChange={handleEmailChange}
            />
            {loginError && <ErrorMessage style={{ margin: 0 }}>Invalid credentials</ErrorMessage>}
            {validationError && <ErrorMessage style={{ margin: 0 }}>{validationError}</ErrorMessage>}
            <Input
              type='password'
              placeholder='Password'
              style={{ width: '100%' }}
              border={border}
              value={password}
              onChange={handlePasswordChange}
            />
          </InputFlexContainer>

          <ForgotPassword>
            Did you <CustomLink to={'/forgot'}><ForgotPasswordText>forget your password?</ForgotPasswordText></CustomLink>
          </ForgotPassword>

          <LoginButton onClick={handleLoginClick}>
            <LoginBtnText>Login</LoginBtnText>
          </LoginButton>

          <CustomLink to={'/loginhelp'} style={{ alignSelf: 'center', pointerEvents: 'none' }}>
            <NeedHelp>Need Help logging in?</NeedHelp>
          </CustomLink>
        </CustomLogin>
        <Divider />
        <SSOContainer>
          <SSOLoginButton onPointerDown={onSSOClick}>
            <SSOLoginBtnText>Login with Microsoft</SSOLoginBtnText>
          </SSOLoginButton>
          <EabLogo />
        </SSOContainer>
      </LoginBox>
    </Container>
  )
}
