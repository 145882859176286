import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import {
  ContractSection,
  ContractSectionHeader,
  ContractConfigurationFieldContainer,
} from 'Components/shared/contracts'
import { SegmentsList } from 'Containers/candidates/contracts/segments/SegmentsList'
import { DialogField } from 'Components/shared/dialog'
import { Input } from 'Components/shared/input'
import { DatePickerField } from 'Components/shared/datepicker'
import { DatePickerContainer as DatePicker } from 'Containers/shared/datepicker/DatePicker'
import { stringifyContractDate, formatDate } from 'Utils/dateUtils'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { ContractSource, AudienceTypeHelper } from 'Types/index'
import { useGetAudienceTypesQuery } from 'Services/global/audienceTypes'
import { Loading } from 'Utils/loading'

const ContractValue = styled('div')(({ theme, disabledContract }) => ({
  color: disabledContract
    ? theme.palette.primary.dark
    : theme.palette.secondary['100'],
  fontSize: '0.875rem',
  lineHeight: '21px',
}))

interface ContractConfigurationProps {
  contract: any
  contractName: string
  disabledContract: boolean
  contractSource: ContractSource
  salesforceID?: string
  control: any
  setSalesforceID?: (salesforceID: string) => any
  setContractName?: (contractName: string) => any
  setServerSalesForceID?: (salesforceID: string) => any
  setStartDate?: (startDate: string) => any
  setServerStartDate?: (startDate: string) => any
  setEndDate?: (endDate: string) => any
  setServerEndDate?: (endDate: string) => any
  setLeadCap?: (leadCap: number) => any
  setServerLeadCap?: (leadCap: number) => any
  setNotes?: (notes: string) => any
  setServerNotes?: (notes: string) => any
  startDate?: string
  endDate?: string
  leadCap?: number
  notes: string
}

export const ContractConfiguration = ({
  contract,
  contractName,
  disabledContract: disabledContractProp,
  contractSource,
  salesforceID,
  control,
  startDate,
  endDate,
  leadCap,
  notes,
  setSalesforceID,
  setContractName,
  setStartDate,
  setEndDate,
  setLeadCap,
  setServerLeadCap,
  setServerSalesForceID,
  setServerStartDate,
  setServerEndDate,
  setNotes,
  setServerNotes,
}: ContractConfigurationProps) => {
  const dispatch = useDispatch()

  const { timeBasedContractState: {isDuplicating} } = useSelector((state: any) => state)

  const disabledContract = disabledContractProp && !isDuplicating

  const { hasPermission: canUpdateContractConfiguration } = useHasPermission(
    Permissions.UpdateContractConfiguration
  )

  const { hasPermission: canViewNotes } = useHasPermission(
    Permissions.ViewContractNotes
  )
  const { data: audienceTypes, isFetching: isFetchingAudienceTypes } =
    useGetAudienceTypesQuery(contractSource)

  useEffect(() => {
    if (contract) {
      dispatch(setServerStartDate(stringifyContractDate(contract?.start_date)))
      dispatch(setServerEndDate(stringifyContractDate(contract?.end_date)))
      setSalesforceID &&
        dispatch(setServerSalesForceID(contract?.salesforce_id))
      setServerLeadCap && dispatch(setServerLeadCap(contract?.lead_cap))
      dispatch(setServerNotes(contract?.notes))
    }
  }, [contract])

  if (isFetchingAudienceTypes) return <Loading />
  return (
    <ContractSection>
      <ContractSectionHeader label={'Contract Configuration'} />
      <ContractConfigurationFieldContainer>
        {isDuplicating && (<DialogField id='add-contract-name' label='*Contract Name'>
          <Input
            id='add-contract-name'
            name='contractName'
            placeholder='Add Contract Name'
            control={control}
            rules={{
              required: 'Contract Name is a required field',
            }}
            style={{ width: '401px' }}
            value={contractName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(setContractName(e.target.value))
            }}
          />
        </DialogField>)}
        {contractSource === ContractSource.inquiries && (
          <DialogField id='salesforceID' label='*Salesforce ID'>
            {canUpdateContractConfiguration ? (
              <Input
                id='add-salesforce-id'
                placeholder='Add Salesforce ID'
                name='salesforceID'
                value={salesforceID}
                style={{ width: '401px' }}
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/,
                    message:
                      'Salesforce ID should contain only letters and numbers',
                  },
                  minLength: {
                    value: 11,
                    message:
                      'Salesforce ID should have a minimum length of 11 characters',
                  },
                  maxLength: {
                    value: 18,
                    message: 'Salesforce ID should have a maximum length of 18',
                  },
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(setSalesforceID(e.target.value))
                }}
                disabled={disabledContract}
              />
            ) : (
              <ContractValue>{salesforceID}</ContractValue>
            )}
          </DialogField>
        )}

        <DialogField id='audience-type' label='Audience Type'>
          <ContractValue disabledContract={disabledContract}>
            {AudienceTypeHelper.getReadableString(
              audienceTypes,
              contract['audience_type']
            )}
          </ContractValue>
        </DialogField>

        {contract['purchase_type']?.length > 0 && (
          <DialogField id='purchase-type' label='Purchase Type'>
            <ContractValue disabledContract={disabledContract}>
              {contract['purchase_type']}
            </ContractValue>
          </DialogField>
        )}

        {contractSource === ContractSource.inquiries && (
          <DialogField id='contract-lead-cap' label='*Contract Lead Cap'>
            {disabledContract || !canUpdateContractConfiguration ? (
              <ContractValue disabledContract={disabledContract}>
                {contract['lead_cap']}
              </ContractValue>
            ) : (
              <Input
                id='update-contract-lead-cap'
                value={String(leadCap)}
                name='leadCap'
                control={control}
                rules={{
                  required: 'Contract Lead Cap is a required field',
                }}
                placeholder='0'
                style={{ width: '229px' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(setLeadCap(Number(e.target.value)))
                }
              />
            )}
          </DialogField>
        )}

        <DatePickerField id='update-delivery-period' label='*Delivery Period'>
          {disabledContract || !canUpdateContractConfiguration ? (
            <ContractValue disabledContract={disabledContract}>
              {startDate} to {endDate}
            </ContractValue>
          ) : (
            <>
              <DatePicker
                label={'Starting Date'}
                name='startDate'
                value={startDate}
                setValue={setStartDate}
                onChange={(newValue) => {
                  dispatch(setStartDate(formatDate(newValue)))
                }}
                disablePast={false}
                disabled={disabledContract}
                control={control}
                rules={{
                  required: 'Start Date is a required field',
                }}
              />
              <DatePicker
                label={'End Date'}
                name='endDate'
                value={endDate}
                setValue={setEndDate}
                onChange={(newValue) => {
                  dispatch(setEndDate(formatDate(newValue)))
                }}
                minDate={new Date(startDate)}
                disablePast={true}
                disabled={disabledContract}
                control={control}
                rules={{
                  required: 'End Date is a required field',
                }}
              />
            </>
          )}
        </DatePickerField>

        <DialogField id='notes-field' label='Notes' alignSelf='flex-start'>
          {canViewNotes ? (
            <Input
              id='add-contract-notes'
              placeholder='Add Notes'
              value={notes}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(setNotes(e.target.value))
              }}
              multiline
              rows={5}
              style={{ width: '504px' }}
              disabled={disabledContract}
            />
          ) : (
            <ContractValue>{notes}</ContractValue>
          )}
        </DialogField>

        {contractSource === ContractSource.candidates && (
          <SegmentsList
            contract={contract}
            disabledContract={disabledContract}
          />
        )}
      </ContractConfigurationFieldContainer>
    </ContractSection>
  )
}
