import React from 'react'
import {useSelector} from 'react-redux'
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { TableVisualization } from './';
export const StudentVisualization = ({chartType, studentMetric, metricTransform = null, metricLabel, chartLabel, seriesTransform = null, secondMetric, secondTransform = null, limit}) => {
    const availableStudents = useSelector(({websocketStatus}) => websocketStatus.availableStudents)
    
    const availableStudentsByMetric = []
    const secondMetricSet = new Set()
    availableStudents?.length && availableStudents?.forEach((student) => {
        const {count} = student
        const metric = student[studentMetric]
        const secondary = student[secondMetric]
        const metricIndex = availableStudentsByMetric.findIndex(el => el.label === metric)
        if(secondary) secondMetricSet.add(secondary)
        if(metricIndex > -1) {
            availableStudentsByMetric[metricIndex].value += count
            if(secondMetric) availableStudentsByMetric[metricIndex][secondary] = count + (availableStudentsByMetric[metricIndex][secondary] || 0)
        } else {
            availableStudentsByMetric.push({id: `${metric}-metric-id`, value: count, label: metric, arcLabel: (item) => `${item.label} (${item.value})`,
            arcLabelMinAngle: 45, [secondary]: count})
        }
    })

    const transfromedSeries = limit && availableStudentsByMetric.length > 0  ? [...availableStudentsByMetric].sort((a, b) => b.value - a.value)?.slice(0, 10) : chartType === 'pie' 
    ? availableStudentsByMetric.map(studentType => ({...studentType, arcLabel: (item) => `${item.label} (${item.value})`, arcLabelMinAngle: 45, label: `${studentType.label} ${Math.round(studentType.value / 
        availableStudentsByMetric.reduce((acc, student) => acc + student.value, 0) * 100)}%`}))
    : availableStudentsByMetric
    
    if (transfromedSeries?.length === 0) return <div>No data available</div>
    
    if(chartType === 'pie') {
        return (
            <PieChart
            series={[{data: transfromedSeries}]}
            height={400}
            width={400}
          />
        )
    }
    if(chartType === 'bar') {
        return (
            <BarChart
            dataset={transfromedSeries}
            yAxis={[{ scaleType: 'band', dataKey: 'label', label: `Student ${metricLabel}` }]}
            xAxis={[{ label: 'Students' }]}
            series={[{ dataKey: 'value', label: chartLabel}]}
            layout="horizontal"
            height={400}
            width={400}
          />
        )
    }
    if(chartType === 'table') {
        return (
            <TableVisualization rows={transfromedSeries} columns={Array.from(secondMetricSet)} title={secondMetric ? "High School Graduation Class" : ""} label={metricLabel} />
        )
    }
    return <div>No Visualization available</div>
}