import React, { useCallback, memo } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {
  setDestinationLabel,
  setDestinationType,
  setDestinationPath,
  setHostName,
  setLeadDownloadPreference,
  setisLeadDownloadZip,
  setAuthType,
  setBasicAuthUserName,
  setBasicAuthPassword,
  setSshUserName,
  setSshKeyId,
  clearAddDestination,
  selectDestination,
} from './AddDestinationSlice'
import { AddDestination as AddDestinationComponent } from 'Components/configuration/destinations/addDestination/AddDestination'
import { useAddDestinationMutation } from 'Services/configuration/destinations'
import { AuthenticationType } from '../AuthenticationType'
import { Loading } from 'Utils/loading'

interface AddDestinationProps {
  /** To show the add destination dialog or not */
  addDestinationDialog: boolean
  /** To toggle the add destination dialog visibility */
  setAddDestinationDialog: () => void
}

export const AddDestination = memo(
  ({ addDestinationDialog, setAddDestinationDialog }: AddDestinationProps) => {
    const dispatch = useDispatch()
    const { ipedId } = useParams()
    const {
      control,
      formState: { errors: formErrors, isValid },
      unregister,
    } = useForm({
      mode: 'onChange',
    })
    const {
      label,
      destinationType,
      destinationPath,
      hostName,
      authType,
      basicAuthUserName,
      basicAuthPassword,
      sshUserName,
      sshKeyId,
      leadDownloadPreference,
      isLeadDownloadZip,
    } = useSelector(selectDestination)
    const destinationTypeMap = { SFTP: 'sql_sftp', Panto: 'sql_panto' }
    const [addDestination, { isLoading: isAddingDestination }] =
      useAddDestinationMutation()

    const setLabel = useCallback((value: string) => {
      dispatch(setDestinationLabel(value))
    }, [])

    const setType = useCallback((type: string) => {
      dispatch(setDestinationType(type))
    }, [])

    const setPath = useCallback((path: string) => {
      dispatch(setDestinationPath(path))
    }, [])

    const setHost = useCallback((host: string) => {
      dispatch(setHostName(host))
    }, [])

    const setAuthenticationType = useCallback((type: string) => {
      dispatch(setAuthType(type))
      if (type === 'SSH Key') {
        unregister(['basicUsername', 'basicPassword'])
      }
    }, [])

    const setDestinationBasicAuthUserName = useCallback((userName: string) => {
      dispatch(setBasicAuthUserName(userName))
    }, [])

    const setDestinationBasicAuthPassword = useCallback((password: string) => {
      dispatch(setBasicAuthPassword(password))
    }, [])

    const setDestinationSshUserName = useCallback((username: string) => {
      dispatch(setSshUserName(username))
    }, [])

    const setDestinationSshKeyId = useCallback((id: string) => {
      dispatch(setSshKeyId(id))
    }, [])

    const setDownloadPreference = useCallback((preference: string) => {
      dispatch(setLeadDownloadPreference(preference))
    }, [])

    const setIsZip = useCallback((isZip: boolean) => {
      dispatch(setisLeadDownloadZip(isZip))
    }, [])

    const clearAddDestinationValues = useCallback(() => {
      dispatch(clearAddDestination())
    }, [])

    const addDestinationValues = useCallback(() => {
      const authConDetails =
        authType === 'Password'
          ? {
              sftp_username: basicAuthUserName,
              sftp_password: basicAuthPassword,
            }
          : {
              sftp_username: sshUserName,
            }

      addDestination({
        ipedId,
        label: label,
        connection_type: destinationTypeMap[destinationType],
        path: destinationPath,
        ssh_key_id: sshKeyId,
        host_name: hostName,
        user_name: authConDetails['sftp_username'],
        con_details: {
          sftp_host: hostName,
          sftp_port: '22',
          ...authConDetails,
        },
        lead_download_preference: leadDownloadPreference,
        lead_download_is_zip: isLeadDownloadZip,
      })
      closeDialog()
    }, [
      authType,
      basicAuthUserName,
      basicAuthPassword,
      sshUserName,
      sshKeyId,
      label,
      ipedId,
      destinationType,
      destinationPath,
      hostName,
      leadDownloadPreference,
      isLeadDownloadZip,
    ])

    const closeDialog = useCallback(() => {
      setAddDestinationDialog()
      clearAddDestinationValues()
    }, [])

    const disableFormSubmit = !isValid || Object.keys(formErrors).length > 0

    if (isAddingDestination) return <Loading />

    return (
      <AddDestinationComponent
        addDestinationDialog={addDestinationDialog}
        closeDialog={closeDialog}
        setLabel={setLabel}
        setType={setType}
        setPath={setPath}
        setHost={setHost}
        setDownloadPreference={setDownloadPreference}
        setIsZip={setIsZip}
        addDestinationValues={addDestinationValues}
        disableSubmit={disableFormSubmit}
        control={control}
      >
        <AuthenticationType
          ipedId={ipedId}
          authType={authType}
          basicAuthUserName={basicAuthUserName}
          basicAuthPassword={basicAuthPassword}
          sshUserName={sshUserName}
          sshKeyId={sshKeyId}
          setAuthenticationType={setAuthenticationType}
          setDestinationBasicAuthUserName={setDestinationBasicAuthUserName}
          setDestinationBasicAuthPassword={setDestinationBasicAuthPassword}
          setDestinationSshUserName={setDestinationSshUserName}
          setDestinationSshKeyId={setDestinationSshKeyId}
          control={control}
        />
      </AddDestinationComponent>
    )
  }
)
