export enum Permissions {
  AddContractDedup = 'add-contract-dedup',
  AddContractDestination = 'add-contract-destination',
  AddInstitution = 'add-institution',
  AddSegment = 'add-segment',
  AddUser = 'add-user',
  AddUserRole = 'add-user-role',
  AdminDeleteSegment = 'admin-delete-segment',
  CreateContract = 'create-contract',
  CreateDestination = 'create-destination',
  CreateSegmentSupplement = 'create-segment-supplement',
  CreateSshkey = 'create-sshkey',
  DeleteContract = 'delete-contract',
  DeleteContractDedupFile = 'delete-contract-dedup-file',
  DeleteContractDestination = 'delete-contract-destination',
  DeleteDestination = 'delete-destination',
  DeleteInstitution = 'delete-institution',
  DeleteManySegments = 'delete-many-segments',
  DeleteManyUserRoles = 'delete-many-user-roles',
  DeleteSshkey = 'delete-sshkey',
  DeleteUser = 'delete-user',
  DeleteUserRole = 'delete-user-role',
  DownloadContractDeliveriesFile = 'download-contract-deliveries-file',
  DownloadFile = 'download-file',
  GenerateUploadUrl = 'generate-upload-url',
  GetAllMyPermissions = 'get-all-my-permissions',
  GetDeduplications = 'get-deduplications',
  GetDeduplicationsForContract = 'get-deduplications-for-contract',
  GetPermissionsForUser = 'get-permissions-for-user',
  IntrospectAnyModel = 'introspect-any-model',
  ProcessOnDemandPendingSegments = 'process-on-demand-pending-segments',
  PutSegment = 'put-segment',
  ReplaceContractDedupFile = 'replace-contract-dedup-file',
  SubscribeUsersToContractNotifications = 'subscribe-users-to-contract-notifications',
  SwitchInstitution = 'switch-institution',
  TestDestination = 'test-destination',
  TransformFilterExpr = 'transform-filter-expr',
  UpdateContract = 'update-contract',
  UpdateContractConfiguration = 'update-contract-configuration',
  UpdateContractDestinations = 'update-contract-destinations',
  UpdateDeliveryCriteria = 'update-delivery-criteria',
  UpdateDestination = 'update-destination',
  UpdateInstitution = 'update-institution',
  UpdateSegment = 'update-segment',
  UpdateSegmentSupplement = 'update-segment-supplement',
  UpdateUploadFile = 'update-upload-file',
  UpdateUser = 'update-user',
  ViewAllContracts = 'view-all-contracts',
  ViewAllContractsDeliveries = 'view-all-contracts-deliveries',
  ViewAllDestinations = 'view-all-destinations',
  ViewAllFiles = 'view-all-files',
  ViewAllInstitutions = 'view-all-institutions',
  ViewAllOnDemandPendingSegments = 'view-all-on-demand-pending-segments',
  ViewAllPendingSegments = 'view-all-pending-segments',
  ViewAllRoles = 'view-all-roles',
  ViewAllSegmentsForContract = 'view-all-segments-for-contract',
  ViewAllSegmentsForInstitution = 'view-all-segments-for-institution',
  ViewAllUserRoles = 'view-all-user-roles',
  ViewAllUsers = 'view-all-users',
  ViewContract = 'view-contract',
  ViewContractNotes = 'view-contract-notes',
  ViewContractsIntrospection = 'view-contracts-introspection',
  ViewDedupNotes = 'view-dedup-notes',
  ViewDestination = 'view-destination',
  ViewInstitution = 'view-institution',
  ViewRegistrationIntrospection = 'view-registration-introspection',
  ViewSegment = 'view-segment',
  ViewSegmentAvailableStudentsForContract = 'view-segment-available-students-for-contract',
  ViewSegmentDetails = 'view-segment-details',
  ViewSshkeyDestinations = 'view-sshkey-destinations',
  ViewSshkeys = 'view-sshkeys',
  ViewSubscribedUsersToContractNotifications = 'view-subscribed-users-to-contract-notifications',
  ViewUser = 'view-user',
  ViewEnhancedCandidatesSegmentDetails = 'view-enhanced-candidates-segment-details',
  ViewEnhancedCandidatesExploreSegments = 'view-enhanced-candidates-explore-segments',
}
