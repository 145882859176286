import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import Button from '@mui/material/Button'
import {
  Body,
  BodyActions,
  BodyHeader,
  BodyContent,
  ContractHeaderLabel,
} from 'Components/shared/body'
import { ContractConfiguration } from 'Containers/shared/contracts/contractconfiguration'
import { DeliveryCriteria } from 'Containers/shared/contracts/deliverycriteria'
import { DeduplicatesContainer } from 'Containers/shared/contracts/deduplicates'
import {
  setContractName,
  setAudienceType,
  setPurchaseType,
  setStartDate,
  setEndDate,
  setNotes,
  selectOnDemandContractConfiguration,
  clearContractConfiguration,
} from './OnDemandContractConfigurationSlice'
import {
  setAllDestinations,
  setSelectedDestinations,
  setSelectedFileFormat,
  setSelectedFields,
  setSelectedLeads,
  selectOnDemandDeliveryCriteria,
  setSelectedLookbackPeriod,
  clearOnDemandDeliveryCriteria,
} from './OnDemandDeliveryCriteriaSlice'

import {
  clearDeduplicatesFile,
  selectDeduplicatesFile,
} from 'Containers/shared/contracts/deduplicates/DeduplicatesSlice'

import {
  useCreateContractMutation,
  useAddDestinationsToContractMutation,
  useSubscribeUsertoContractNotificationsMutation,
  useLazySearchContractByLabelQuery,
} from 'Services/contracts/candidatesContracts'
import { formatContractDate } from 'Utils/dateUtils'
import { contractLabelSearchQuery } from 'Services/contracts/contractsUtils'
import {
  useAddDeduplicateFileMutation,
  useUpdateFileStatusMutation,
} from 'Services/contracts/deduplicates'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { DaysHumanToNumber } from 'Utils/dateUtils'
import { Loading } from 'Utils/loading'
import { useGetAudienceTypesQuery } from 'Services/global/audienceTypes'
import { ContractSource, ContractType, ContractTypeReadable } from 'Types/index'

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const CancelButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.4)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const AddButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
    border: 'none',
  },
  [theme.breakpoints.up('md')]: {
    width: '104px',
  },
}))

export const AddCandidatesContract = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { ipedId } = useParams()
  const [addDestinationsToContract, { isLoading: isAddingDestinations }] =
    useAddDestinationsToContractMutation()
  const [
    subscribeUsertoContractNotifications,
    { isLoading: isSubscribingUsers },
  ] = useSubscribeUsertoContractNotificationsMutation()

  const {
    control,
    formState: { errors: formErrors, isValid },
    unregister,
    setError: setFormError,
    clearErrors: clearFormErrors,
  } = useForm({
    mode: 'onChange',
  })
  const {
    contractName,
    audienceType,
    purchaseType,
    startDate,
    endDate,
    notes,
  } = useSelector(selectOnDemandContractConfiguration)

  const { fileId, fileName } = useSelector(selectDeduplicatesFile)

  const [
    createContract,
    {
      isLoading: isCreatingContract,
      data: contractData,
      isSuccess: isContractCreated,
      isError,
    },
  ] = useCreateContractMutation()

  const [fetchContractRecords, { data: contractRecords }] =
    useLazySearchContractByLabelQuery()
  const { data: audienceTypes, isFetching: isFetchingAudienceTypes } =
    useGetAudienceTypesQuery(ContractSource.candidates)

  const [addDeduplicate, { isLoading: isAddingDeduplicate }] =
    useAddDeduplicateFileMutation()
  const [updateFileStatus, { isLoading: isUpdatingFileStatus }] =
    useUpdateFileStatusMutation()

  const {
    allDestinations,
    selectedDestinations,
    allFileFormats,
    selectedFileFormat,
    selectedFields,
    allLeads,
    selectedLeads,
    allLookbackPeriods,
    selectedLookbackPeriod,
  } = useSelector(selectOnDemandDeliveryCriteria)

  const addContract = () => {
    createContract({
      ipedId,
      source: ContractSource.candidates,
      contract_type: ContractType.on_demand,
      fields_to_export: selectedFields,
      start_date: formatContractDate(startDate),
      end_date: formatContractDate(endDate),
      label: contractName,
      notes: notes,
      audience_type: audienceType,
      look_back_period: DaysHumanToNumber[selectedLookbackPeriod],
      ...(purchaseType && { purchase_type: purchaseType }),
    })
  }

  const clearContractValues = () => {
    dispatch(clearContractConfiguration())
    dispatch(clearOnDemandDeliveryCriteria())
    dispatch(clearDeduplicatesFile())
  }

  useEffect(() => {
    dispatch(clearDeduplicatesFile())
  }, [])

  useEffect(() => {
    //If contract is created, do the following
    if (isContractCreated) {
      //add destinations to contract
      if (selectedDestinations.length > 0) {
        addDestinationsToContract({
          contractId: contractData.data.id,
          ipedId,
          destinationIds: selectedDestinations.map(
            (destination: any) => destination.id
          ),
        })
      }
      //subscribe user to contract notifications
      if (selectedLeads.length > 0) {
        subscribeUsertoContractNotifications({
          contractId: contractData.data.id,
          ipedId,
          accountIdsEnabled: selectedLeads,
        })
      }
      if (fileId) {
        /** If there is a file id, call file status update to update
         * the file status. Once the file status update call returns,
         * call the deduplicate api **/

        updateFileStatus({
          ipedId,
          fileId: fileId,
        }).then(() => {
          addDeduplicate({
            ipedId,
            contractId: contractData.data.id,
            fileId: fileId,
          })
        })
      }
      //clear redux slice
      clearContractValues()
      //navigate to candidates page
      navigate(`/institution/${ipedId}/candidates`)
    }
  }, [isContractCreated])

  useEffect(() => {
    fetchContractRecords({
      ipedId: ipedId,
      filterQuery: buildFilterQuery(contractLabelSearchQuery(contractName)),
    })
  }, [contractName])

  useEffect(() => {
    if (contractRecords?.data?.total_records > 0) {
      setFormError(
        'root.contractName',
        {
          type: 'manual',
          message: 'Duplicate contract name. Please enter a unique name.',
        },

        { shouldFocus: true }
      )
    } else {
      clearFormErrors('root.contractName')
    }
  }, [contractRecords])

  const disableFormSubmit = !isValid || Object.keys(formErrors).length > 0

  if (
    isCreatingContract ||
    isAddingDestinations ||
    isSubscribingUsers ||
    isUpdatingFileStatus ||
    isAddingDeduplicate ||
    isFetchingAudienceTypes
  )
    return <Loading />

  return (
    <Body>
      <BodyHeader>
        <ContractHeaderLabel
          label={`Add ${ContractTypeReadable.on_demand} Contract`}
        />
        <BodyActions>
          <CancelButton
            onClick={() => {
              clearContractValues()
              navigate(`/institution/${ipedId}/candidates`)
            }}
          >
            Cancel
          </CancelButton>
          <AddButton disabled={disableFormSubmit} onClick={addContract}>
            Add
          </AddButton>
        </BodyActions>
      </BodyHeader>
      <BodyContent>
        <ContractConfiguration
          audienceTypes={audienceTypes}
          contractName={contractName}
          audienceType={audienceType}
          purchaseType={purchaseType}
          startDate={startDate}
          endDate={endDate}
          notes={notes}
          contractSource={ContractSource.candidates}
          control={control}
          setContractName={setContractName}
          setAudienceType={setAudienceType}
          setPurchaseType={setPurchaseType}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setNotes={setNotes}
        />
        <DeliveryCriteria
          audienceType={audienceType}
          allFileFormats={allFileFormats}
          allDestinations={allDestinations}
          selectedDestinations={selectedDestinations}
          selectedFileFormat={selectedFileFormat}
          selectedFields={selectedFields}
          allLeads={allLeads}
          selectedLeads={selectedLeads}
          allLookbackPeriods={allLookbackPeriods}
          selectedLookbackPeriod={selectedLookbackPeriod}
          contractSource={ContractSource.candidates}
          control={control}
          setAllDestinations={setAllDestinations}
          setSelectedDestinations={setSelectedDestinations}
          setSelectedFileFormat={setSelectedFileFormat}
          setSelectedFields={setSelectedFields}
          setSelectedLeads={setSelectedLeads}
          setSelectedLookbackPeriod={setSelectedLookbackPeriod}
        />
        <DeduplicatesContainer fileName={fileName} />
      </BodyContent>
    </Body>
  )
}
