import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import {
  DeduplicatesApi,
  UploadFileApiResponse,
  GetDeduplicatesForContract,
} from 'Services/contracts/deduplicates'

interface File {
  fileId: string
  fileName: string
}

const initialState: File = {
  fileId: '',
  fileName: '',
}

export const DeduplicatesSlice = createSlice({
  name: 'deduplicate',
  initialState,
  reducers: {
    setFileId: (state, action: PayloadAction<string>) => {
      state.fileId = action.payload
    },
    setFileName: (state, action: PayloadAction<string>) => {
      state.fileName = action.payload
    },
    clearDeduplicatesFile: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      DeduplicatesApi.endpoints.getUploadFileData.matchFulfilled,
      (state: File, { payload }: PayloadAction<UploadFileApiResponse>) => {
        if (payload?.data?.file_info?.id) {
          state.fileId = payload.data.file_info.id
          state.fileName = payload.data.file_info.name
        }
      }
    ),
      builder.addMatcher(
        DeduplicatesApi.endpoints.getDeduplicatesForContract.matchFulfilled,
        (
          state: File,
          { payload }: PayloadAction<GetDeduplicatesForContract>
        ) => {
          if (payload?.data[0]?.file_id) {
            state.fileId = payload.data[0].file_id
            state.fileName = payload.data[0].label
          }
        }
      )
  },
})

export const selectDeduplicatesFile = createSelector(
  (state: RootState) => state.deduplicate.fileId,
  (state: RootState) => state.deduplicate.fileName,
  (fileId, fileName): File => {
    return {
      fileId,
      fileName,
    }
  }
)

export const { setFileId, setFileName, clearDeduplicatesFile } =
  DeduplicatesSlice.actions

export default DeduplicatesSlice.reducer
