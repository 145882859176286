import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'

import { Destination } from 'Services/configuration/destinations'
import { FileFormat, LookBackPeriod } from 'Types/index'

interface DeliveryCriteriaState {
  allDestinations: Destination[]
  selectedDestinations: Destination[]
  allFileFormats: string[]
  selectedFileFormat: FileFormat
  selectedFields: string[]
  allLeads: string[]
  selectedLeads: string[]
  allLookbackPeriods: string[]
  selectedLookbackPeriod: LookBackPeriod
}

export const initialState: DeliveryCriteriaState = {
  allDestinations: [],
  selectedDestinations: [],
  selectedFileFormat: FileFormat.CSV,
  selectedFields: [],
  allLeads: [],
  selectedLeads: [],
  allFileFormats: Object.values(FileFormat),
  allLookbackPeriods: Object.values(LookBackPeriod),
  selectedLookbackPeriod: LookBackPeriod.two_years,
}

export const deliveryCriteriaSlice = createSlice({
  name: 'onDemandDeliveryCriteriaDetails',
  initialState,
  reducers: {
    setAllDestinations: (state, action: PayloadAction<Destination[]>) => {
      state.allDestinations = action.payload
    },

    setSelectedDestinations: (state, action: PayloadAction<Destination[]>) => {
      state.selectedDestinations = action.payload
    },
    setServerSelectedDestinations: (
      state,
      action: PayloadAction<Destination[]>
    ) => {
      state.selectedDestinations = action.payload
    },

    setSelectedFileFormat: (state, action: PayloadAction<FileFormat>) => {
      state.selectedFileFormat = action.payload
    },

    setServerSelectedFileFormat: (state, action: PayloadAction<FileFormat>) => {
      state.selectedFileFormat = action.payload
    },

    setSelectedFields: (state, action: PayloadAction<string[]>) => {
      state.selectedFields = action.payload
    },

    setServerSelectedFields: (state, action: PayloadAction<string[]>) => {
      state.selectedFields = action.payload
    },

    setAllLeads: (state, action: PayloadAction<string[]>) => {
      state.allLeads = action.payload
    },

    setSelectedLeads: (state, action: PayloadAction<string[]>) => {
      state.selectedLeads = action.payload
    },

    setServerSelectedLeads: (state, action: PayloadAction<string[]>) => {
      state.selectedLeads = action.payload
    },

    setSelectedLookbackPeriod: (
      state,
      action: PayloadAction<LookBackPeriod>
    ) => {
      state.selectedLookbackPeriod = action.payload
    },

    setServerLookbackPeriod: (state, action: PayloadAction<LookBackPeriod>) => {
      state.selectedLookbackPeriod = action.payload
    },

    clearOnDemandDeliveryCriteria: () => {
      return initialState
    },
  },
})

export const selectOnDemandDeliveryCriteria = createSelector(
  (state: RootState) => state.onDemandDeliveryCriteriaDetails.allDestinations,
  (state: RootState) =>
    state.onDemandDeliveryCriteriaDetails.selectedDestinations,
  (state: RootState) =>
    state.onDemandDeliveryCriteriaDetails.selectedFileFormat,

  (state: RootState) => state.onDemandDeliveryCriteriaDetails.selectedFields,
  (state: RootState) => state.onDemandDeliveryCriteriaDetails.allLeads,
  (state: RootState) => state.onDemandDeliveryCriteriaDetails.selectedLeads,
  (state: RootState) =>
    state.onDemandDeliveryCriteriaDetails.selectedLookbackPeriod,

  (
    allDestinations,
    selectedDestinations,
    selectedFileFormat,
    selectedFields,
    allLeads,
    selectedLeads,
    selectedLookbackPeriod
  ): DeliveryCriteriaState => {
    return {
      allDestinations,
      selectedDestinations,
      selectedFileFormat,
      selectedFields,
      allLeads,
      selectedLeads,
      selectedLookbackPeriod,
      allFileFormats: Object.values(FileFormat),
      allLookbackPeriods: Object.values(LookBackPeriod),
    }
  }
)

export const {
  setAllDestinations,
  setSelectedDestinations,
  setServerSelectedDestinations,
  setSelectedFileFormat,
  setServerSelectedFileFormat,
  setSelectedFields,
  setServerSelectedFields,
  setSelectedLeads,
  setServerSelectedLeads,
  setSelectedLookbackPeriod,
  setServerLookbackPeriod,
  clearOnDemandDeliveryCriteria,
} = deliveryCriteriaSlice.actions

export default deliveryCriteriaSlice.reducer
