import React, { useMemo, useState, memo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useGetSegmentsForContractQuery } from 'Services/segments/segments'
import { SegmentsList as SegmentsListComponent } from 'Components/candidates/contracts/segments'
import { SegmentsDetails } from 'Containers/shared/contracts/segments/SegmentsDetails'

import { Loading } from 'Utils/loading'
import { useToggle } from 'Hooks/useToggle'
import { ContractSource, SegmentAction } from 'Types/index'

const columnData = [
  {
    columnName: 'Segment Name',
    columnWidth: 250,
    sortOrder: 'ASC',
  },
  { columnName: 'Leads Delivered', columnWidth: 100, sortOrder: 'ASC' },
  { columnName: 'Price per Lead', columnWidth: 100, sortOrder: 'ASC' },
]

interface SegmentsListProps {
  contract: any
  disabledContract: boolean
}

export const SegmentsList = memo(
  ({ contract, disabledContract }: SegmentsListProps) => {
    const navigate = useNavigate()
    const [segmentDetails, setSegmentDetails] = useToggle(false)
    const [rowIdx, setRowIdx] = useState(-1)
    const { ipedId } = useParams()
    const { data: segments, isLoading: isLoadingSegments } =
      useGetSegmentsForContractQuery({
        ipedId,
        contractId: contract.id,
      })

    const flattenSegments = useMemo(() => {
      let totalLeadsDelivered = 0
      let segmentRows = []
      if (segments && contract) {
        segments?.forEach((segment: any) => {
          segmentRows.push([
            segment.id,
            segment.name,
            segment.leads_delivered
              ? segment.leads_delivered.toLocaleString()
              : 0,
            segment.price_per_lead,
          ])
          totalLeadsDelivered += segment.leads_delivered
            ? segment.leads_delivered
            : 0
        })
        segmentRows.push([
          '',
          'Total',
          totalLeadsDelivered.toLocaleString(),
          '',
        ])
      }
      return { segmentRows, totalLeadsDelivered }
    }, [segments, contract])

    const handleRowClick = (rowIdx: number) => {
      setSegmentDetails()
      setRowIdx(rowIdx)
    }

    const closeSegmentDetailsDialog = () => {
      setSegmentDetails()
    }

    const handleDuplicateSegment = () => {
      closeSegmentDetailsDialog()
      navigate(
        `/institution/${ipedId}/candidates/contracts/segment/${segments[rowIdx].id}` +
          `?action=${SegmentAction.duplicate}`
      )
    }
    if (isLoadingSegments) return <Loading />

    return (
      <>
        <SegmentsListComponent
          segments={segments}
          flattenSegments={flattenSegments.segmentRows}
          columnData={columnData}
          handleRowClick={handleRowClick}
        />
        {segmentDetails && segments[rowIdx] && (
          <SegmentsDetails
            segmentDetailsDialog={segmentDetails}
            closeSegmentDetailsDialog={closeSegmentDetailsDialog}
            handleDuplicateSegment={handleDuplicateSegment}
            contract={contract}
            contractSource={ContractSource.candidates}
            chosenSegment={segments[rowIdx]}
            segmentLeadCapTotal={segments[rowIdx].lead_total}
            disabledContract={disabledContract}
          />
        )}
      </>
    )
  }
)
