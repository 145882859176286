import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { AudienceTypeHelper } from 'Types/index'
import type { RootState } from 'Utils/store'

const initialState = {
  contractName: '',
  audienceType: AudienceTypeHelper.getNone(),
  purchaseType: '',
  startDate: '',
  endDate: '',
  notes: '',
}

interface ContractConfigurationState {
  /** The name of the contract */
  contractName: string
  /** The type of audience */
  audienceType: string
  /** The type of purchase */
  purchaseType: string
  /** The start date of the contract */
  startDate: string
  /** The end date of the contract */
  endDate: string
  /** The notes of the contract */
  notes: string
}

export const ContractConfigurationSlice = createSlice({
  name: 'onDemandContractConfiguration',
  initialState,
  reducers: {
    setContractName: (state, action: PayloadAction<string>) => {
      state.contractName = action.payload
    },
    setAudienceType: (state, action: PayloadAction<string>) => {
      state.audienceType = action.payload
    },
    setPurchaseType: (state, action: PayloadAction<string>) => {
      state.purchaseType = action.payload
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload
    },

    setNotes: (state, action: PayloadAction<string>) => {
      state.notes = action.payload
    },
    clearContractConfiguration: () => {
      return initialState
    },
  },
})

export const selectOnDemandContractConfiguration = createSelector(
  (state: RootState) => state.onDemandContractConfiguration.contractName,
  (state: RootState) => state.onDemandContractConfiguration.audienceType,
  (state: RootState) => state.onDemandContractConfiguration.purchaseType,
  (state: RootState) => state.onDemandContractConfiguration.startDate,
  (state: RootState) => state.onDemandContractConfiguration.endDate,
  (state: RootState) => state.onDemandContractConfiguration.notes,
  (
    contractName,
    audienceType,
    purchaseType,
    startDate,
    endDate,
    notes
  ): ContractConfigurationState => {
    return {
      contractName,
      audienceType,
      purchaseType,
      startDate,
      endDate,
      notes,
    }
  }
)

export const {
  setContractName,
  setAudienceType,
  setPurchaseType,
  setStartDate,
  setEndDate,
  setNotes,
  clearContractConfiguration,
} = ContractConfigurationSlice.actions

export default ContractConfigurationSlice.reducer
