import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'Utils/store'

interface Destination {
  label: string
  destinationType: string
  hostName: string
  destinationPath: string
  authType: string
  basicAuthUserName: string
  basicAuthPassword: string
  sshUserName: string
  sshKeyId: string
  leadDownloadPreference: string
  isLeadDownloadZip: boolean
}

const initialState: Destination = {
  label: '',
  destinationType: 'SFTP',
  hostName: '',
  destinationPath: '',
  authType: '',
  basicAuthUserName: '',
  basicAuthPassword: '',
  sshUserName: '',
  sshKeyId: '',
  leadDownloadPreference: 'Comma Separated Values',
  isLeadDownloadZip: false,
}

export const ManageDestinationSlice = createSlice({
  name: 'manageDestination',
  initialState,
  reducers: {
    setDestinationLabel: (state, action: PayloadAction<string>) => {
      state.label = action.payload
    },
    setDestinationType: (state, action: PayloadAction<string>) => {
      state.destinationType = action.payload
    },
    setHostName: (state, action: PayloadAction<string>) => {
      state.hostName = action.payload
    },
    setDestinationPath: (state, action: PayloadAction<string>) => {
      state.destinationPath = action.payload
    },
    setLeadDownloadPreference: (state, action: PayloadAction<string>) => {
      state.leadDownloadPreference = action.payload
    },
    setisLeadDownloadZip: (state, action: PayloadAction<boolean>) => {
      state.isLeadDownloadZip = action.payload
    },

    setAuthType: (state, action: PayloadAction<string>) => {
      state.authType = action.payload
    },

    setBasicAuthUserName: (state, action: PayloadAction<string>) => {
      state.basicAuthUserName = action.payload
    },
    setBasicAuthPassword: (state, action: PayloadAction<string>) => {
      state.basicAuthPassword = action.payload
    },
    setSshUserName: (state, action: PayloadAction<string>) => {
      state.sshUserName = action.payload
    },
    setSshKeyId: (state, action: PayloadAction<string>) => {
      state.sshKeyId = action.payload
    },

    clearManageDestination: () => {
      return initialState
    },
  },
})

export const selectDestination = createSelector(
  ({ manageDestination }: RootState) => manageDestination.label,
  ({ manageDestination }: RootState) => manageDestination.destinationType,
  ({ manageDestination }: RootState) => manageDestination.destinationPath,
  ({ manageDestination }: RootState) => manageDestination.hostName,
  ({ manageDestination }: RootState) => manageDestination.authType,
  ({ manageDestination }: RootState) => manageDestination.basicAuthUserName,
  ({ manageDestination }: RootState) => manageDestination.basicAuthPassword,
  ({ manageDestination }: RootState) => manageDestination.sshUserName,
  ({ manageDestination }: RootState) => manageDestination.sshKeyId,
  ({ manageDestination }: RootState) =>
    manageDestination.leadDownloadPreference,
  ({ manageDestination }: RootState) => manageDestination.isLeadDownloadZip,
  (
    label,
    destinationType,
    destinationPath,
    hostName,
    authType,
    basicAuthUserName,
    basicAuthPassword,
    sshUserName,
    sshKeyId,
    leadDownloadPreference,
    isLeadDownloadZip
  ) => ({
    label,
    destinationType,
    destinationPath,
    hostName,
    authType,
    basicAuthUserName,
    basicAuthPassword,
    sshUserName,
    sshKeyId,
    leadDownloadPreference,
    isLeadDownloadZip,
  })
)

export const {
  setDestinationLabel,
  setDestinationType,
  setDestinationPath,
  setHostName,
  setAuthType,
  setBasicAuthUserName,
  setBasicAuthPassword,
  setSshUserName,
  setSshKeyId,
  setLeadDownloadPreference,
  setisLeadDownloadZip,
  clearManageDestination,
} = ManageDestinationSlice.actions

export default ManageDestinationSlice.reducer
