import React, { memo } from 'react'
import { styled } from '@mui/material/styles'
import {
  ContractSection,
  ContractSectionHeader,
} from 'Components/shared/contracts'
import { Table } from 'Components/shared/table'

const NoSegments = styled('div')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.primary.dark,
}))

interface SegmentsListProps {
  segments: any
  flattenSegments: any
  columnData: any
  handleRowClick: (rowIdx: number) => void
}

export const SegmentsList = memo(
  ({
    segments,
    flattenSegments,
    columnData,
    handleRowClick,
  }: SegmentsListProps) => {
    return (
      <ContractSection>
        <ContractSectionHeader label={'Segments'} />
        {segments && segments.length > 0 ? (
          <Table
            width={'778px'}
            height={'248px'}
            columnData={columnData}
            rows={flattenSegments}
            variant='striped'
            handleRowClick={handleRowClick}
            disabled={false}
          />
        ) : (
          <NoSegments>No segments have been added to this contract</NoSegments>
        )}
      </ContractSection>
    )
  }
)
