import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'

import { UsersApi } from 'Services/configuration/users'
import { DestinationsApi } from 'Services/configuration/destinations'
import { SegmentsApi } from 'Services/segments/segments'
import { FilesApi } from 'Services/files/files'
import { SSHKeyApi } from 'Services/configuration/sshkeys'
import { InquiriesContractsApi } from 'Services/contracts/inquiriesContracts'
import { CandidateContractsApi } from 'Services/contracts/candidatesContracts'
import { HipLeadsApi } from 'Services/admin/hipLeads'
import { setSnackbar } from 'Containers/shared/snackbar/SnackbarSlice'

export const SnackbarMiddleware = createListenerMiddleware()

/** Users */
SnackbarMiddleware.startListening({
  matcher: UsersApi.endpoints.addUser.matchFulfilled,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(setSnackbar({ message: 'User has been Added' }))
  },
})

SnackbarMiddleware.startListening({
  matcher: UsersApi.endpoints.deleteUser.matchFulfilled,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(setSnackbar({ message: 'User has been Removed' }))
  },
})

/** Destinations */
SnackbarMiddleware.startListening({
  matcher: DestinationsApi.endpoints.addDestination.matchFulfilled,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(setSnackbar({ message: 'Destination has been Added' }))
  },
})

SnackbarMiddleware.startListening({
  matcher: DestinationsApi.endpoints.updateDestination.matchFulfilled,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(
      setSnackbar({ message: 'Destination has been Updated' })
    )
  },
})

SnackbarMiddleware.startListening({
  matcher: DestinationsApi.endpoints.deleteDestination.matchFulfilled,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(
      setSnackbar({ message: 'Destination has been Deleted' })
    )
  },
})

/** Segments */
SnackbarMiddleware.startListening({
  matcher: SegmentsApi.endpoints.createSegment.matchFulfilled,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(setSnackbar({ message: 'Segment has been Activated' }))
  },
})

SnackbarMiddleware.startListening({
  matcher: SegmentsApi.endpoints.updateSegment.matchFulfilled,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(setSnackbar({ message: 'Segment has been Updated' }))
  },
})

SnackbarMiddleware.startListening({
  matcher: SegmentsApi.endpoints.deactivateSegment.matchFulfilled,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(
      setSnackbar({ message: 'Segment has been Deactivated' })
    )
  },
})

SnackbarMiddleware.startListening({
  matcher: SegmentsApi.endpoints.deleteSegment.matchFulfilled,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(setSnackbar({ message: 'Segment has been Deleted' }))
  },
})

/** Files */
SnackbarMiddleware.startListening({
  matcher: FilesApi.endpoints.getDownloadFile.matchFulfilled,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(setSnackbar({ message: 'File has been Downloaded' }))
  },
})

/** SSH Key */
SnackbarMiddleware.startListening({
  matcher: SSHKeyApi.endpoints.createSSHKeyPair.matchFulfilled,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(
      setSnackbar({ message: 'SSH Key Pair has been Created' })
    )
  },
}),
  SnackbarMiddleware.startListening({
    matcher: SSHKeyApi.endpoints.deleteSSHKeyPair.matchFulfilled,
    effect: (action, listenerApi) => {
      listenerApi.dispatch(
        setSnackbar({ message: 'SSH Key Pair has been Deleted' })
      )
    },
  })

/** Contracts */
SnackbarMiddleware.startListening({
  matcher: InquiriesContractsApi.endpoints.createContract.matchFulfilled,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(
      setSnackbar({ message: 'Annual Contract has been Created' })
    )
  },
}),
  SnackbarMiddleware.startListening({
    matcher: CandidateContractsApi.endpoints.createContract.matchFulfilled,
    effect: (action, listenerApi) => {
      listenerApi.dispatch(
        setSnackbar({ message: 'On Demand Contract has been Created' })
      )
    },
  }),
  SnackbarMiddleware.startListening({
    matcher: InquiriesContractsApi.endpoints.updateContract.matchFulfilled,
    effect: (action, listenerApi) => {
      listenerApi.dispatch(
        setSnackbar({ message: 'Annual Contract has been Updated' })
      )
    },
  }),
  SnackbarMiddleware.startListening({
    matcher: CandidateContractsApi.endpoints.updateContract.matchFulfilled,
    effect: (action, listenerApi) => {
      listenerApi.dispatch(
        setSnackbar({ message: 'The Contract has been Updated' })
      )
    },
  }),
  /** Admin - HIP Leads */

  SnackbarMiddleware.startListening({
    matcher: HipLeadsApi.endpoints.addSegment.matchFulfilled,
    effect: (_, listenerApi) => {
      listenerApi.dispatch(setSnackbar({ message: 'Segment has been added' }))
    },
  }),
  SnackbarMiddleware.startListening({
    matcher: HipLeadsApi.endpoints.updateSegment.matchFulfilled,
    effect: (_, listenerApi) => {
      listenerApi.dispatch(setSnackbar({ message: 'Segment has been updated' }))
    },
  }),
  SnackbarMiddleware.startListening({
    matcher: HipLeadsApi.endpoints.setActiveForSegment.matchFulfilled,
    effect: (action, listenerApi) => {
      const segmentStatus =
        (action?.payload as any)?.data?.status === 'active'
          ? 'activated'
          : 'deactivated'
      listenerApi.dispatch(
        setSnackbar({ message: `Segment has been ${segmentStatus}` })
      )
    },
  }),
  /** Errors */
  SnackbarMiddleware.startListening({
    matcher: isAnyOf(
      UsersApi.endpoints.addUser.matchRejected,
      UsersApi.endpoints.deleteUser.matchRejected,
      DestinationsApi.endpoints.addDestination.matchRejected,
      DestinationsApi.endpoints.updateDestination.matchRejected,
      DestinationsApi.endpoints.deleteDestination.matchRejected,
      SegmentsApi.endpoints.createSegment.matchRejected,
      SegmentsApi.endpoints.updateSegment.matchRejected,
      SegmentsApi.endpoints.deactivateSegment.matchRejected,
      SegmentsApi.endpoints.deleteSegment.matchRejected,
      FilesApi.endpoints.getDownloadFile.matchRejected,
      SSHKeyApi.endpoints.createSSHKeyPair.matchRejected,
      SSHKeyApi.endpoints.deleteSSHKeyPair.matchRejected,
      InquiriesContractsApi.endpoints.createContract.matchRejected,
      CandidateContractsApi.endpoints.createContract.matchRejected,
      InquiriesContractsApi.endpoints.updateContract.matchRejected,
      CandidateContractsApi.endpoints.updateContract.matchRejected,
      HipLeadsApi.endpoints.addSegment.matchRejected,
      HipLeadsApi.endpoints.setActiveForSegment.matchRejected,
      HipLeadsApi.endpoints.updateSegment.matchRejected
    ),
    effect: (action, listenerApi) => {
      const payload = action.payload as any
      const errorMessage = payload?.data?.message || payload?.message
      if (errorMessage) {
        listenerApi.dispatch(
          setSnackbar({
            message: `Error: ${errorMessage}`,
            error: true,
          })
        )
      } else {
        listenerApi.dispatch(
          setSnackbar({
            message: 'Something went wrong. Please try again',
            error: true,
          })
        )
      }
    },
  })
