import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import Button from '@mui/material/Button'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import {
  ContractType,
  ContractSource,
  ContractStatus,
  AudienceTypeHelper,
} from 'Types/index'
import { setDuplicating } from 'Containers/inquiries/contractdetails/TimeBasedContractStateSlice'
import { useGetAudienceTypesQuery } from 'Services/global/audienceTypes'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { contractLabelSearchQuery } from 'Services/contracts/contractsUtils'




import {
  useGetInquiryContractByIdQuery,
  useGetDestinationsForContractQuery,
  useCreateContractMutation,
  useUpdateContractMutation,
  useAddDestinationsToContractMutation,
  useSubscribeUsertoContractNotificationsMutation,
  useLazySearchContractByLabelQuery,
} from 'Services/contracts/inquiriesContracts'
import { useGetAllDestinationsQuery } from 'Services/configuration/destinations'
import { ContractDetails as ContractDetailsComponent } from 'Components/shared/contracts/contractdetails'

import { Body, BodyActions,
  BodyHeader,
  ContractHeaderLabel } from 'Components/shared/body'
import {
  setContractName as setExistingContractName,
  setSalesforceID as setExistingSalesforceID,
  setServerSalesForceID as setExistingServerSalesForceID,
  setStartDate as setExistingStartDate,
  setServerStartDate as setExistingServerStartDate,
  setEndDate as setExistingEndDate,
  setServerEndDate as setExistingServerEndDate,
  setLeadCap as setExistingLeadCap,
  setServerLeadCap as setExistingServerLeadCap,
  setNotes as setExistingNotes,
  setServerNotes as setExistingServerNotes,
  selectTimeBasedContractConfigurationDetails as selectExistingTimeBasedContractConfigurationDetails,
  clearContractConfiguration as clearExistingContractConfiguration,
} from 'Containers/inquiries/contractdetails/TimeBasedContractConfigurationSlice'

import {
  setAllDestinations as setExistingAllDestinations,
  setSelectedDestinations as setExistingSelectedDestinations,
  setServerSelectedDestinations as setExistingServerSelectedDestinations,
  setSelectedFileFormat as setExistingSelectedFileFormat,
  setServerSelectedFileFormat as setExistingServerSelectedFileFormat,
  setSelectedDeliveryCadence as setExistingSelectedDeliveryCadence,
  setServerSelectedDeliveryCadence as setExistingServerSelectedDeliveryCadence,
  setSelectedDeliveryModel as setExistingSelectedDeliveryModel,
  setServerSelectedDeliveryModel as setExistingServerSelectedDeliveryModel,
  setSelectedFields as setExistingSelectedFields,
  setServerSelectedFields as setExistingServerSelectedFields,
  setSelectedLeads as setExistingSelectedLeads,
  setServerSelectedLeads as setExistingServerSelectedLeads,
  clearTimeBasedDeliveryCriteria as clearExistingTimeBasedDeliveryCriteria,
  selectTimeBasedDeliveryCriteriaDetails as selectExistingTimeBasedDeliveryCriteriaDetails,
} from 'Containers/inquiries/contractdetails/TimeBasedDeliveryCriteriaSlice'

import {
  setContractName as setNewContractName,
  setAudienceType as setNewAudienceType,
  setLeadCap as setNewLeadCap,
  setStartDate as setNewStartDate,
  setEndDate as setNewEndDate,
  setNotes as setNewNotes,
  setSalesforceID as setNewSalesforceID,
  selectTimeBasedContractConfiguration as selectNewTimeBasedContractConfigurationDetails,
  clearContractConfiguration as clearNewContractConfiguration,
  setAudienceType,
} from 'Containers/inquiries/contracts/TimeBasedContractConfigurationSlice'
  
import {
  setAllDestinations as setNewAllDestinations,
  setSelectedDestinations as setNewSelectedDestinations,
  setSelectedFileFormat as setNewSelectedFileFormat,
  setSelectedDeliveryCadence as setNewSelectedDeliveryCadence,
  setSelectedDeliveryModel as setNewSelectedDeliveryModel,
  setSelectedFields as setNewSelectedFields,
  setSelectedLeads as setNewSelectedLeads,
  selectTimeBasedDeliveryCriteria as selectNewTimeBasedDeliveryCriteriaDetails,
  clearTimeBasedDeliveryCriteria as clearNewTimeBasedDeliveryCriteria,
} from 'Containers/inquiries/contracts/TimeBasedDeliveryCriteriaSlice'

import { resetDisableUpdate as resetExistingDisableUpdate } from 'Containers/inquiries/contractdetails/TimeBasedContractStateSlice'

import { Loading } from 'Utils/loading'
import { RootState } from 'Utils/store'
import { SegmentsList as InquiriesSegmentsList } from 'Containers/inquiries/contracts/segments/SegmentsList'
import { ContractConfiguration } from 'Containers/shared/contracts/contractdetails/ContractConfiguration'
import { DeliveryCriteria } from 'Containers/shared/contracts/contractdetails/DeliveryCriteria'
import { formatContractDate } from 'Utils/dateUtils'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { de } from 'date-fns/locale'

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const CancelButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.4)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const AddButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
    border: 'none',
  },
  [theme.breakpoints.up('md')]: {
    width: '104px',
  },
}))

export const ContractForm = () => {
  const dispatch = useDispatch()
  const fullParamsObject = useParams()
  const { contractId, ipedId } = fullParamsObject
  const navigate = useNavigate()
  const [segmentLeadCapTotal, setSegmentLeadCapTotal] = useState(0)

  let setAllDestinations, setServerSelectedDestinations, selectTimeBasedContractConfigurationDetails, 
  selectTimeBasedDeliveryCriteriaDetails, clearContractConfiguration, clearTimeBasedDeliveryCriteria, resetDisableUpdate,
  deliveryObject, configurationObject; 
  if (contractId === 'new') {
    selectTimeBasedContractConfigurationDetails = selectNewTimeBasedContractConfigurationDetails
    selectTimeBasedDeliveryCriteriaDetails = selectNewTimeBasedDeliveryCriteriaDetails
    clearContractConfiguration = clearNewContractConfiguration
    clearTimeBasedDeliveryCriteria = clearNewTimeBasedDeliveryCriteria
    setAllDestinations = setNewAllDestinations
    configurationObject = {
      contractSource: ContractSource.inquiries,
      setContractName: setNewContractName,
      setEndDate: setNewEndDate,
      setLeadCap: setNewLeadCap,
      setNotes: setNewNotes,
      setSalesforceID: setNewSalesforceID,
      setStartDate: setNewStartDate,
      setAudienceType: setNewAudienceType,
    }
    deliveryObject = {
      contractType: ContractType.annual,
      setAllDestinations,
      setSelectedDeliveryCadence: setNewSelectedDeliveryCadence,
      setSelectedDeliveryModel: setNewSelectedDeliveryModel,
      setSelectedDestinations: setNewSelectedDestinations,
      setSelectedFields: setNewSelectedFields,
      setSelectedFileFormat: setNewSelectedFileFormat,
      setSelectedLeads: setNewSelectedLeads
    }
    
  } else {
        selectTimeBasedContractConfigurationDetails = selectExistingTimeBasedContractConfigurationDetails
        selectTimeBasedDeliveryCriteriaDetails = selectExistingTimeBasedDeliveryCriteriaDetails
        clearContractConfiguration = clearExistingContractConfiguration
        clearTimeBasedDeliveryCriteria = clearExistingTimeBasedDeliveryCriteria
        resetDisableUpdate = resetExistingDisableUpdate
        setServerSelectedDestinations = setExistingServerSelectedDestinations
        setAllDestinations = setExistingAllDestinations

        configurationObject = {
          contractSource: ContractSource.inquiries,
          setContractName: setExistingContractName,
          setEndDate: setExistingEndDate,
          setLeadCap: setExistingLeadCap,
          setNotes: setExistingNotes,
          setSalesforceID: setExistingSalesforceID,
          setServerEndDate: setExistingServerEndDate,
          setServerLeadCap: setExistingServerLeadCap,
          setServerNotes: setExistingServerNotes,
          setServerSalesForceID: setExistingServerSalesForceID,
          setServerStartDate: setExistingServerStartDate,
          setStartDate: setExistingStartDate,
        }

        deliveryObject = {
          contractType: ContractType.annual,
          setAllDestinations,
          setServerSelectedDeliveryCadence: setExistingServerSelectedDeliveryCadence,
          setServerSelectedDeliveryModel: setExistingServerSelectedDeliveryModel,
          setServerSelectedDestinations,
          setServerSelectedFields: setExistingServerSelectedFields,
          setServerSelectedFileFormat: setExistingServerSelectedFileFormat,
          setServerSelectedLeads: setExistingServerSelectedLeads,
          setSelectedDeliveryCadence: setExistingSelectedDeliveryCadence,
          setSelectedDeliveryModel: setExistingSelectedDeliveryModel,
          setSelectedDestinations: setExistingSelectedDestinations,
          setSelectedFields: setExistingSelectedFields,
          setSelectedFileFormat: setExistingSelectedFileFormat,
          setSelectedLeads: setExistingSelectedLeads
        }
  }
  
  const disableUpdate = useSelector(
    ({ timeBasedContractState }: RootState) =>
      timeBasedContractState.disableUpdate
  )
  const { hasPermission: canUpdateContract, isFetching } = useHasPermission(
    Permissions.UpdateContract
  )

  const { contractName, audienceType, salesforceID, startDate, endDate, leadCap, notes } = useSelector(
    selectTimeBasedContractConfigurationDetails
  )

  configurationObject.contractName = contractName
  configurationObject.salesforceID = salesforceID
  configurationObject.startDate = startDate
  configurationObject.endDate = endDate
  configurationObject.leadCap = leadCap
  configurationObject.notes = notes


  const {
    allDestinations,
    allDeliveryCadence,
    selectedDestinations,
    selectedFileFormat,
    selectedDeliveryCadence,
    selectedDeliveryModel,
    selectedFields,
    allLeads,
    selectedLeads,
    allFileFormats,
    allDeliveryModel,
  } = useSelector(selectTimeBasedDeliveryCriteriaDetails)

  const { timeBasedContractState: {isDuplicating} } = useSelector((state: any) => state)

  const [
    createContract,
    { isLoading: isCreatingContract, data: contractData, isSuccess: isContractCreated },
  ] = useCreateContractMutation()

  /** Get Contract Information */
  const { data: inquiriesContract, isLoading: isLoadingInquiriesContracts } =
    useGetInquiryContractByIdQuery({
      ipedId,
      contractId,
    })

  /** Get all destinations and already selected destinations for the contract */

  const { data: allDestinationsInfo, isLoading: isGettingAlldestinations } =
    useGetAllDestinationsQuery({
      ipedId,
    })

  const { data: selectedDestionationsInfo, isLoading: isLoadingDestinations } =
    useGetDestinationsForContractQuery({
      ipedId,
      contractId: contractId,
    })


  const { data: audienceTypes, isFetching: isFetchingAudienceTypes } =
    useGetAudienceTypesQuery(ContractSource.inquiries)
  useEffect(() => {
    if (allDestinationsInfo) {
      dispatch(setAllDestinations(allDestinationsInfo))
    }
  }, [allDestinationsInfo])

  const [fetchContractRecords, { data: contractRecords }] =
  useLazySearchContractByLabelQuery()

  useEffect(() => {
    if (selectedDestionationsInfo && contractId !== 'new') {
      dispatch(setServerSelectedDestinations(selectedDestionationsInfo?.data))
    }
  }, [selectedDestionationsInfo])

  const {
    control,
    formState: { errors: formErrors, isValid },
    setError: setFormError,
    clearErrors: clearFormErrors,
  } = useForm({
    mode: 'onChange',
  })

  const [
    updateContract,
    { isLoading: isUpdatingContract, isSuccess: isContractUpdated },
  ] = useUpdateContractMutation()

  const [addDestinationsToContract] = useAddDestinationsToContractMutation()

  const [subscribeUsertoContractNotifications] =
    useSubscribeUsertoContractNotificationsMutation()

  const disabledContract =
    [ContractStatus.expired, ContractStatus.completed].includes(
      inquiriesContract?.status
    ) || !canUpdateContract

  const clearContractValues = () => {
    dispatch(clearContractConfiguration())
    dispatch(clearTimeBasedDeliveryCriteria())
    contractId !== 'new' && dispatch(resetDisableUpdate())
  }

  const goBack = useCallback(() => {
    navigate(-1)
    clearContractValues()
  }, [])

  const handleOnClick = async () => {
    if(!isDuplicating && contractId !== 'new') {
    updateContract({
      ipedId,
      contractId: inquiriesContract.id,
      contract: {
        source: ContractSource.inquiries,
        contract_type: ContractType.annual,
        fields_to_export: selectedFields,
        lead_cap: leadCap,
        salesforce_id: salesforceID,
        label: inquiriesContract.label,
        notes: notes,
        start_date: formatContractDate(startDate),
        end_date: formatContractDate(endDate),
        audience_type: inquiriesContract.audience_type,
        delivery_cadence: selectedDeliveryCadence,
        delivery_model: selectedDeliveryModel,
        look_back_period: 720,
      },
    })
    } else {
      try {
      const {data: {data: {id: newContractId}}} = await createContract({
        ipedId,
        source: ContractSource.inquiries,
        contract_type: ContractType.annual,
        fields_to_export: selectedFields,
        lead_cap: leadCap,
        salesforce_id: salesforceID,
        label: contractName,
        notes: notes,
        start_date: formatContractDate(startDate),
        end_date: formatContractDate(endDate),
        audience_type: contractId === 'new' ? audienceType : inquiriesContract.audience_type,
        delivery_cadence: selectedDeliveryCadence,
        delivery_model: contractId === 'new' ? 'lead_cap_reached' : selectedDeliveryModel,
        look_back_period: 720,
      })
      dispatch(setDuplicating(false))
      navigate(
        `/institution/${ipedId}/inquiries/contracts/${newContractId}`
      )
      } catch (error) {
        console.error(error)
      }

    }
  }

  useEffect(() => {
    //If contract is updated, do the following
    if (isContractUpdated) {
      //add destinations to contract
      const destinationIds =
        selectedDestinations?.length > 0
          ? selectedDestinations.map((destination: any) => destination.id)
          : []
      addDestinationsToContract({
        contractId: contractId,
        ipedId,
        destinationIds,
      })

      //subscribe user to contract notifications
      const accountIdsEnabled = selectedLeads?.length > 0 ? selectedLeads : []
      subscribeUsertoContractNotifications({
        contractId: contractId,
        ipedId,
        accountIdsEnabled,
      })
    }
  }, [isContractUpdated])

  useEffect(() => {
    //If contract is created, do the following
    if (isContractCreated) {
      //add destinations to contract
      if (selectedDestinations.length > 0) {
        addDestinationsToContract({
          contractId: contractData.data.id,
          ipedId,
          destinationIds: selectedDestinations.map(
            (destination: any) => destination.id
          ),
        })
      }
      if (selectedLeads.length > 0) {
        //subscribe user to contract notifications
        subscribeUsertoContractNotifications({
          contractId: contractData.data.id,
          ipedId,
          accountIdsEnabled: selectedLeads,
        })
      }
      //clear redux slice
      clearContractValues()
      //navigate to inquiries page
      navigate(`/institution/${ipedId}/inquiries`)
    }
  }, [isContractCreated])

  useEffect(() => {
    if (contractId !== 'new' && leadCap < segmentLeadCapTotal) {
      setFormError(
        'root.leadCap',
        {
          type: 'manual',
          message: 'lead cap lower than segment total',
        },

        { shouldFocus: true }
      )
    } else {
      clearFormErrors('root.leadCap')
    }
  }, [leadCap])

  useEffect(() => {
    fetchContractRecords({
      ipedId,
      filterQuery: buildFilterQuery(contractLabelSearchQuery(contractName)),
    })
  }, [contractName])

  const disableFormSubmit = Object.keys(formErrors).length > 0

  if(contractId === 'new') {
    configurationObject.audienceType = audienceType
    configurationObject.audienceTypes = audienceTypes
    deliveryObject.allDeliveryModel = allDeliveryModel
    deliveryObject.allFileFormats = allFileFormats
    deliveryObject.control = control
  } else {
    configurationObject.contract = inquiriesContract
    configurationObject.disabledContract = disabledContract
    deliveryObject.contract = inquiriesContract
    deliveryObject.disabledContract = disabledContract
  }

  if (
    isLoadingInquiriesContracts ||
    isGettingAlldestinations ||
    isUpdatingContract ||
    isLoadingDestinations ||
    isFetching
  ){
    console.log('loading', isLoadingInquiriesContracts,
    isGettingAlldestinations,
    isUpdatingContract,
    isLoadingDestinations,
    isFetching)
    return <Loading />
  }

  return inquiriesContract || contractId === 'new' ? (
    <Body>
      {contractId !== 'new' && (
        <ContractDetailsComponent
          contract={inquiriesContract}
          disableUpdate={disableUpdate || disableFormSubmit}
          disabledContract={disabledContract}
          goBack={goBack}
          onClick={handleOnClick}
        />
      )}
      {contractId === 'new' && (
        <BodyHeader>
          <ContractHeaderLabel label={'New Time-Based Contract'} />
          <BodyActions>
            <AddButton disabled={disableFormSubmit} onClick={handleOnClick}>
              Add
            </AddButton>
            <CancelButton
              onClick={() => {
                clearContractValues()
                navigate(`/institution/${ipedId}/inquiries`)
              }}
            >
              Cancel
            </CancelButton>
          </BodyActions>
        </BodyHeader>
      )}

      {!isDuplicating && contractId !== 'new' && (
        <InquiriesSegmentsList
          contract={inquiriesContract}
          disabledContract={disabledContract}
          setSegmentLeadCapTotal={setSegmentLeadCapTotal}
        />
      )}

      <ContractConfiguration
        contractName={contractName}
        contractSource={ContractSource.inquiries}
        control={control}
        endDate={endDate}
        leadCap={leadCap}
        notes={notes}
        salesforceID={salesforceID}
        startDate={startDate}
        {...configurationObject}
      />
      <DeliveryCriteria
        allDeliveryCadence={allDeliveryCadence}
        allDestinations={allDestinations}
        allLeads={allLeads}
        audienceType={contractId === 'new' ? audienceType : inquiriesContract.audience_type}
        contractSource={ContractSource.inquiries}
        //control={control}
        selectedDeliveryCadence={selectedDeliveryCadence}
        selectedDeliveryModel={selectedDeliveryModel}
        selectedDestinations={selectedDestinations}
        selectedFields={selectedFields}
        selectedFileFormat={selectedFileFormat}
        selectedLeads={selectedLeads}
        {...deliveryObject}
      />
    </Body>
  ) : null
}
