import React, { useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SegmentQueue as SegmentQueueComponent } from 'Components/admin/segmentqueue'
import {
  Body,
  BodyTitle,
  BodyActions,
  BodyHeader,
  BodyContent,
} from 'Components/shared/body'
import { TableFooter } from 'Components/shared/table'
import { TablePagination } from 'Containers/shared/tablePagination'
import { SegmentsDetails } from 'Containers/shared/contracts/segments'
import { DefaultButton } from 'Components/shared/buttons'

import { Loading } from 'Utils/loading'
import {
  setSelectAll,
  setSelectedSegments,
  setSelectedCheckboxIdxs,
  selectSegmentQueue,
  clearSelected,
} from './SegmentQueueSlice'

import { usePagination } from 'Hooks/usePagination'
import { useToggle } from 'Hooks/useToggle'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'

import {
  useGetSegmentsQueueQuery,
  useDeleteSegmentMutation,
  useProcessSegmentOrderMutation,
} from 'Services/segments/segments'
import { ContractSource, SegmentAction } from 'Types/index'
import { SearchComponent } from 'Components/connectedComponents/ui'

const sortColumnData = (sortOrder) => [
  { columnName: 'College ID', columnWidth: 80 },
  { columnName: 'Segment Name', columnWidth: 180 },
  { columnName: 'College Name', columnWidth: 180, sortOrder: sortOrder, sortBy: 'college_name' },
  { columnName: 'Contract Related', columnWidth: 180 },
  { columnName: 'Leads Request', columnWidth: 100 },
  { columnName: 'Dedup Records', columnWidth: 100 },
]

export const SegmentQueue = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const PAGE_SIZE = 20

  const { currentPage, handlePageChange } = usePagination()
  const [segmentDetails, setSegmentDetails] = useToggle(false)
  const [segmentRowIdx, setSegmentRowIdx] = useState(-1)
  const [sortOrder, setSortOrder] = useState('asc')

  const { selectAll, selectedSegments, selectedCheckboxIdxs } =
    useSelector(selectSegmentQueue)

    const {search: {value: searchString}} =
    useSelector(state => state)

  const { hasPermission: candProcessSegments, isFetching } = useHasPermission(
    Permissions.ProcessOnDemandPendingSegments
  )

  const { data: segmentData, isLoading, refetch } = useGetSegmentsQueueQuery({
    page: currentPage,
    order: sortOrder,
    searchString 
  })
  const [deleteSegment, { isLoading: isDeleting }] = useDeleteSegmentMutation()
  const [processOrder, { isLoading: isProcessing }] =
    useProcessSegmentOrderMutation()

  const initialRowIdxs = new Array(PAGE_SIZE).fill(false)

  // sets select all checkbox on page change
  useEffect(() => {
    if (selectedCheckboxIdxs[currentPage]) {
      dispatch(
        setSelectAll(
          selectedCheckboxIdxs[currentPage].every((element) => element === true)
        )
      )
    } else {
      dispatch(setSelectAll(false))
    }
  }, [currentPage])

  useEffect(() => {
    if(searchString && searchString.length > 2) {
      refetch()
    }
  }, [searchString])



  const flattenSegments = useMemo(() => {
    let rows = []
    if (segmentData) {
      segmentData?.records?.forEach((segment: any) => {
        rows.push([
          '',
          segment.institution_id,
          segment.name,
          segment.institution_name,
          segment.contract_label,
          segment.lead_total,
          segment.dedupe_count ?? 0,
        ])
      })
    }
    return rows
  }, [segmentData])

  const handleAllClick = (flag: boolean) => {
    dispatch(setSelectAll(flag))
    dispatch(
      setSelectedCheckboxIdxs({
        page: currentPage,
        idxs: new Array(PAGE_SIZE).fill(flag),
      })
    )
    if (flag) {
      dispatch(
        setSelectedSegments({
          page: currentPage,
          segments: segmentData?.records?.map(
            (segment: any, idx: number) => segment.id
          ),
        })
      )
    } else {
      dispatch(setSelectedSegments({ page: currentPage, segments: [] }))
    }
  }

  const handleRowClick = (rowIdx: number) => {
    setSegmentDetails()
    setSegmentRowIdx(rowIdx)
  }

  const handleCheckboxClick = (event, rowIdx: number) => {
    event.stopPropagation()
    const newRows = [...(selectedCheckboxIdxs[currentPage] ?? initialRowIdxs)]
    newRows[rowIdx] = !newRows[rowIdx]
    dispatch(setSelectedCheckboxIdxs({ page: currentPage, idxs: newRows }))

    dispatch(setSelectAll(newRows.every((element) => element === true)))

    const result = []
    segmentData?.records?.forEach((segment: any, idx: number) => {
      if (newRows[idx]) result.push(segment.id)
    })
    dispatch(setSelectedSegments({ page: currentPage, segments: result }))
  }

  const handleDeleteSegment = () => {
    deleteSegment({
      ids: segmentData?.records[segmentRowIdx].id,
    })
    closeSegmentDetailsDialog()
  }

  const handleEditSegment = (institutionId: string) => {
    closeSegmentDetailsDialog()
    navigate(
      `/institution/${institutionId}/candidates/contracts/segment/${segmentData?.records[segmentRowIdx].id}` +
        `?action=${SegmentAction.edit}`
    )
  }

  const handleDuplicateSegment = (institutionId: string) => {
    closeSegmentDetailsDialog()
    navigate(
      `/institution/${institutionId}/candidates/contracts/segment/${segmentData?.records[segmentRowIdx].id}` +
        `?action=${SegmentAction.duplicate}`
    )
  }

  const handleProcessOrders = () => {
    const allSegments = []
    for (const page in selectedSegments) {
      allSegments.push(...selectedSegments[page])
    }
    processOrder({ segment_ids: allSegments })
    dispatch(clearSelected())
  }

  const closeSegmentDetailsDialog = () => {
    setSegmentDetails()
  }

  if (isLoading || isDeleting || isFetching || isProcessing) return <Loading />

  return (
    <Body>
      <BodyHeader>
        <BodyTitle>Candidate Segments in Queue</BodyTitle>
        <SearchComponent />
        {candProcessSegments && (
          <BodyActions>
            <DefaultButton onClick={handleProcessOrders}>
              Process Orders
            </DefaultButton>
          </BodyActions>
        )}
      </BodyHeader>
      <BodyContent>
        {segmentData?.total_records > 0 && (
          <>
            <SegmentQueueComponent
              columnData={sortColumnData(sortOrder)}
              setSortOrder={setSortOrder}
              rows={flattenSegments}
              rowIdxs={selectedCheckboxIdxs[currentPage] ?? initialRowIdxs}
              selectAll={selectAll}
              handleAllClick={handleAllClick}
              handleRowClick={handleRowClick}
              handleCheckboxClick={handleCheckboxClick}
            />
            {segmentDetails && (
              <SegmentsDetails
                disabledContract={!candProcessSegments}
                segmentDetailsDialog={segmentDetails}
                closeSegmentDetailsDialog={closeSegmentDetailsDialog}
                chosenSegment={segmentData?.records[segmentRowIdx]}
                contractSource={ContractSource.candidates}
                segmentLeadCapTotal={
                  segmentData?.records[segmentRowIdx]?.lead_total
                }
                handleDeleteSegment={handleDeleteSegment}
                handleEditSegment={handleEditSegment}
                handleDuplicateSegment={handleDuplicateSegment}
                contract={{
                  audience_type:
                    segmentData?.records[segmentRowIdx]?.contract_information
                      ?.audience_type,
                  label:
                    segmentData?.records[segmentRowIdx]?.contract_information
                      ?.label,
                }}
                isSegmentQueue={true}
              />
            )}
            <TableFooter>
              {segmentData?.total_pages > 1 && (
                <TablePagination
                  rowsPerPage={PAGE_SIZE}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  totalRecords={segmentData?.total_records}
                  totalPages={segmentData?.total_pages}
                />
              )}
            </TableFooter>
          </>
        )}
      </BodyContent>
    </Body>
  )
}
