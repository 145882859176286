import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'

import { Destination } from 'Services/configuration/destinations'
import { FileFormat } from 'Types/index'

interface DeliveryCriteriaState {
  allDestinations: Destination[]
  selectedDestinations: Destination[]
  selectedFileFormat: FileFormat
  selectedDeliveryCadence: string[]
  selectedDeliveryModel: string
  selectedFields: string[]
  allLeads: string[]
  selectedLeads: string[]
  allFileFormats: string[]
  allDeliveryCadence: string[]
  allDeliveryModel: string[]
}

const allDeliveryCadence = [
  'Weekly, Monday',
  'Weekly, Tuesday',
  'Weekly, Wednesday',
  'Weekly, Thursday',
  'Weekly, Friday',
  'Weekly, Saturday',
  'Weekly, Sunday',
]
const allDeliveryModel = ['Deliver inquiries until cap is hit']

const initialState: DeliveryCriteriaState = {
  allDestinations: [],
  selectedDestinations: [],
  selectedFileFormat: FileFormat.CSV,
  selectedDeliveryCadence: [allDeliveryCadence[0]],
  selectedDeliveryModel: allDeliveryModel[0],
  selectedFields: [],
  allLeads: [],
  selectedLeads: [],
  allFileFormats: Object.values(FileFormat),
  allDeliveryCadence,
  allDeliveryModel,
}

const deliveryCriteriaSlice = createSlice({
  name: 'timeBasedDeliveryCriteria',
  initialState,
  reducers: {
    setAllDestinations: (state, action: PayloadAction<Destination[]>) => {
      state.allDestinations = action.payload
    },

    setSelectedDestinations: (state, action: PayloadAction<Destination[]>) => {
      state.selectedDestinations = action.payload
    },

    setSelectedFileFormat: (state, action: PayloadAction<FileFormat>) => {
      state.selectedFileFormat = action.payload
    },

    setSelectedDeliveryCadence: (state, action: PayloadAction<string[]>) => {
      state.selectedDeliveryCadence = action.payload
    },

    setSelectedDeliveryModel: (state, action: PayloadAction<string>) => {
      state.selectedDeliveryModel = action.payload
    },

    setSelectedFields: (state, action: PayloadAction<string[]>) => {
      state.selectedFields = action.payload
    },

    setAllLeads: (state, action: PayloadAction<string[]>) => {
      state.allLeads = action.payload
    },

    setSelectedLeads: (state, action: PayloadAction<string[]>) => {
      state.selectedLeads = action.payload
    },
    clearTimeBasedDeliveryCriteria: () => {
      return initialState
    },
  },
})

export const selectTimeBasedDeliveryCriteria = createSelector(
  (state: RootState) => state.timeBasedDeliveryCriteria.allDestinations,
  (state: RootState) => state.timeBasedDeliveryCriteria.selectedDestinations,
  (state: RootState) => state.timeBasedDeliveryCriteria.selectedFileFormat,
  (state: RootState) => state.timeBasedDeliveryCriteria.selectedDeliveryCadence,
  (state: RootState) => state.timeBasedDeliveryCriteria.selectedDeliveryModel,
  (state: RootState) => state.timeBasedDeliveryCriteria.selectedFields,
  (state: RootState) => state.timeBasedDeliveryCriteria.allLeads,
  (state: RootState) => state.timeBasedDeliveryCriteria.selectedLeads,
  (
    allDestinations,
    selectedDestinations,
    selectedFileFormat,
    selectedDeliveryCadence,
    selectedDeliveryModel,
    selectedFields,
    allLeads,
    selectedLeads
  ): DeliveryCriteriaState => {
    return {
      allDestinations,
      selectedDestinations,
      selectedFileFormat,
      selectedDeliveryCadence,
      selectedDeliveryModel,
      selectedFields,
      allLeads,
      selectedLeads,
      allFileFormats: Object.values(FileFormat),
      allDeliveryCadence,
      allDeliveryModel,
    }
  }
)

export const {
  setAllDestinations,
  setSelectedDestinations,
  setSelectedFileFormat,
  setSelectedDeliveryCadence,
  setSelectedDeliveryModel,
  setSelectedFields,
  setSelectedLeads,
  clearTimeBasedDeliveryCriteria,
} = deliveryCriteriaSlice.actions

export default deliveryCriteriaSlice.reducer
