import React from 'react'
import { useDispatch } from 'react-redux'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import {
  ContractSection,
  ContractSectionHeader,
  ContractConfigurationFieldContainer,
} from 'Components/shared/contracts'
import { DialogField } from 'Components/shared/dialog'
import { Input } from 'Components/shared/input'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { DatePickerField } from 'Components/shared/datepicker'
import { DatePickerContainer as DatePicker } from 'Containers/shared/datepicker/DatePicker'
import { formatDate } from 'Utils/dateUtils'
import { setPurchaseType } from 'Containers/candidates/contracts/OnDemandContractConfigurationSlice'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { useGetContractConfigQuery } from 'Services/global/contractConfig'
import { AudienceTypeHelper, ContractSource, PurchaseType } from 'Types/index'

interface ContractConfigurationProps {
  audienceTypes: { id: string; name: string }[]
  contractSource: ContractSource
  contractName: string
  salesforceID?: string
  purchaseType?: string
  audienceType: string
  leadCap?: number
  startDate?: string
  endDate?: string
  notes: string
  control: any
  setContractName: (contractName: string) => any
  setAudienceType: (audienceType: string) => any
  setPurchaseType?: (purchaseType: string) => any
  setLeadCap?: (leadCap: number) => any
  setStartDate?: (startDate: string) => any
  setEndDate?: (endDate: string) => any
  setNotes: (notes: string) => any
  setSalesforceID?: (salesforceID: string) => any
}

export const ContractConfiguration = ({
  audienceTypes,
  contractSource,
  contractName,
  audienceType,
  purchaseType,
  salesforceID,
  leadCap,
  startDate,
  endDate,
  notes,
  setContractName,
  setAudienceType,
  setLeadCap,
  setStartDate,
  setEndDate,
  setNotes,
  setSalesforceID,
  control,
}: ContractConfigurationProps) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const { hasPermission: canViewNotes } = useHasPermission(
    Permissions.ViewContractNotes
  )

  const { data: contractConfig } = useGetContractConfigQuery({
    contractSource,
    audienceType,
  })

  return (
    <ContractSection>
      <ContractSectionHeader label={'Contract Configuration'} />
      <ContractConfigurationFieldContainer>
        <DialogField id='add-contract-name' label='*Contract Name'>
          <Input
            id='add-contract-name'
            name='contractName'
            placeholder='Add Contract Name'
            control={control}
            rules={{
              required: 'Contract Name is a required field',
            }}
            value={contractName}
            style={matches ? { width: '401px' } : {}}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(setContractName(e.target.value))
            }}
          />
        </DialogField>
        {contractConfig?.fields_available.includes('salesforce_id') && (
          <DialogField id='salesforce-id' label='*Salesforce ID'>
            <Input
              id='add-salesforce-id'
              placeholder='Add Salesforce ID'
              name='salesforceID'
              value={salesforceID}
              style={matches ? { width: '401px' } : {}}
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[a-zA-Z0-9]+$/,
                  message:
                    'Salesforce ID should contain only letters and numbers',
                },
                minLength: {
                  value: 11,
                  message:
                    'Salesforce ID should have a minimum length of 11 characters',
                },
                maxLength: {
                  value: 18,
                  message: 'Salesforce ID should have a maximum length of 18',
                },
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(setSalesforceID(e.target.value))
              }}
            />
          </DialogField>
        )}
        <DialogField id='add-audience-type' label='*Audience Type'>
          <SingleSelectDropdown
            id='add-audience-type'
            style={matches ? { width: '229px' } : {}}
            value={
              audienceType
                ? AudienceTypeHelper.getReadableString(
                    audienceTypes,
                    audienceType
                  )
                : ''
            }
            name='audienceType'
            control={control}
            rules={{
              required: 'Audience Type is a required field',
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const key = AudienceTypeHelper.getAudienceType(
                audienceTypes,
                e.target.value
              )
              dispatch(setAudienceType(key))
            }}
            values={audienceTypes?.map((type) => type.name).sort()}
          />
        </DialogField>
        {contractConfig?.fields_available.includes('purchase_type') && (
          <DialogField id='add-purchase-type' label='*Purchase Type'>
            <SingleSelectDropdown
              id='add-purchase-type'
              style={matches ? { width: '229px' } : {}}
              value={purchaseType}
              name='purchaseType'
              control={control}
              rules={{
                required: 'Purchase Type is a required field',
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(setPurchaseType(e.target.value))
              }
              values={[PurchaseType.eab_internal, PurchaseType.direct_purchase]}
            />
          </DialogField>
        )}
        {contractConfig?.fields_available.includes('lead_cap') && (
          <DialogField id='add-contract-lead-cap' label='*Contract Lead Cap'>
            <Input
              id='add-contract-lead-cap'
              value={String(leadCap)}
              name='leadCap'
              control={control}
              rules={{
                required: 'Contract Lead Cap is a required field',
              }}
              placeholder='0'
              style={matches ? { width: '229px' } : {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(setLeadCap(Number(e.target.value)))
              }
            />
          </DialogField>
        )}

        <DatePickerField id='add-delivery-period' label='*Delivery Period'>
          <DatePicker
            label={'Starting Date'}
            name='startDate'
            value={startDate}
            setValue={setStartDate}
            onChange={(newValue) => {
              dispatch(setStartDate(formatDate(newValue)))
            }}
            disablePast={false}
            control={control}
            rules={{
              required: 'Start Date is a required field',
            }}
          />
          <DatePicker
            label={'End Date'}
            name='endDate'
            value={endDate}
            setValue={setEndDate}
            onChange={(newValue) => {
              dispatch(setEndDate(formatDate(newValue)))
            }}
            minDate={new Date(startDate)}
            disablePast={true}
            control={control}
            rules={{
              required: 'End Date is a required field',
            }}
          />
        </DatePickerField>

        {canViewNotes && (
          <DialogField id='notes-field' label='Notes' alignSelf='flex-start'>
            <Input
              id='add-contract-notes'
              placeholder='Add Notes'
              style={{ width: '504px' }}
              value={notes}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(setNotes(e.target.value))
              }
              multiline
              rows={5}
            />
          </DialogField>
        )}
      </ContractConfigurationFieldContainer>
    </ContractSection>
  )
}
