import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import useWebSocket from 'react-use-websocket'
import { messageReceived } from 'Slices/websocketSlice'
import { useLoginStatusQuery } from 'Services/global/authentication'
import { WebSocketMethods } from 'Types/webSocketMethods'

const pingMessage = {
  method: WebSocketMethods.ping,
  data: null,
}
interface SocketResponse {
  sendMessage: (message: string) => void
}

const PING_INTERVAL = 30000

export const useSocket = (setResponse): SocketResponse => {
  const { data: loginInfo } = useLoginStatusQuery()
  const dispatch = useDispatch()
  const baseUrl =
    process.env.REACT_APP_WS_BASE_URL + 'Authorization=' + loginInfo?.wsToken
  const intervalId = useRef(null)

  const handleOpen = () => {
    sendMessage(JSON.stringify(pingMessage))
    intervalId.current = setInterval(() => {
      sendMessage(JSON.stringify(pingMessage))
    }, PING_INTERVAL)
  }

  const handleClose = () => {
    clearInterval(intervalId.current)
  }

  const handleMessage = (message) => {
    const data = JSON.parse(message?.data)
    if (data?.method !== WebSocketMethods.ping) {
      setResponse(data?.data?.data)
      dispatch(messageReceived(data?.data?.data))
    }
  }

  const { sendMessage } = useWebSocket(
    baseUrl,
    {
      share: true,
      shouldReconnect: () => true,
      onOpen: handleOpen,
      onClose: handleClose,
      onMessage: handleMessage,
    },
    Boolean(loginInfo?.wsToken)
  )

  return { sendMessage }
}
