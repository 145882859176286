import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { buildSliderProperty } from 'Slices/sliderSlice'
import type { RootState } from 'Utils/store'

interface SmartCandidates {
  /* filterQuery is the query that is used to filter the data in the backend */
  filterQuery: any
  /* selectedMin is the selected minimium value in the slider */
  selectedMin: number
  /* selectedMax is the selected maximum value in the slider */
  selectedMax: number
}

export const MIN = 21
export const MAX = 100
const STEP = 1
const VALUES = ['No', 'Yes']
const ARIA_LABEL = 'ENHANCED_CANDIDATES'
const meta = {
  section: 'Enhanced Candidates',
  label: 'Enhanced Candidates',
  selectedValue: VALUES[0],
}

const deriveRange = (range) => {
  const rangeObject = {min: undefined, max: undefined}
  if (Array.isArray(range)) {
    for (let i = 0; i < range.length; i++) {
      if (Array.isArray(range[i])) {
        const minFirst = range[i][0] < range[i][1]
        rangeObject.min = minFirst ? range[i][0] : range[i][1];
        rangeObject.max = minFirst ? range[i][1] : range[i][0];
        return rangeObject
      }
    }
    const minFirst = range[0] < range[1]
    rangeObject.min = minFirst ? range[0] : range[1]
    rangeObject.max = minFirst ? range[1] : range[0];
    return rangeObject
  }
  return {min: MIN, max: MAX}
}

export const initialState: any = {
  isSmartCandidates: false,
  values: VALUES,
  selectedValue: VALUES[0],
  selectedMin: MIN,
  selectedMax: MAX,
  filterQuery: '',
}

export const SmartCandidatesSlice = createSlice({
  name: 'smart',
  initialState,
  reducers: {
    setOnDemandNoSmartCandidates: (state) => {
      state.selectedValue = VALUES[0]
      state.selectedMin = MIN
      state.selectedMax = MAX
      state.isSmartCandidates = false
      state.filterQuery = ''
    },
    setOnDemandSmartCandidatesValue: (state, action) => {
      const {min, max} = deriveRange(action.payload)
      state.selectedValue = VALUES[1]
      state.selectedMin = min
      state.selectedMax = max
      state.isSmartCandidates = true
      state.filterQuery = buildSliderProperty(
        'college_rank',
        [min, max],
        { ...meta, selectedValue: VALUES[1] }
      )
    },
  },
})

const createFilterQuery = (selectedValue: string, min: number, max: number) => {
  switch (selectedValue) {
    case VALUES[0]:
      return initialState.filterQuery
    case VALUES[1]:
      return buildSliderProperty('college_rank', [min, max], meta)
    default:
      return initialState.filterQuery
  }
}

export const selectSmartCandidates = createSelector(
  ({ onDemandFilter: { smartCandidates } }: RootState) =>
    smartCandidates['selectedValue'],
  ({ onDemandFilter: { smartCandidates } }: RootState) =>
    smartCandidates['selectedMin'],
  ({ onDemandFilter: { smartCandidates } }: RootState) =>
    smartCandidates['selectedMax'],
  ({ onDemandFilter: { smartCandidates } }: RootState) =>
    smartCandidates['isSmartCandidates'],
  (
    selectedValue: string,
    selectedMin: number,
    selectedMax: number,
    isSmartCandidates: boolean
  ): {
    selectedValue: string
    values: string[]
    step: number
    ariaLabel: string
    selectedMin: number
    selectedMax: number
    min: number
    max: number
    filterQuery: any
    isSmartCandidates: boolean
    label: string
  } => {
    return {
      selectedValue,
      values: VALUES,
      selectedMin,
      selectedMax,
      min: MIN,
      max: MAX,
      step: STEP,
      ariaLabel: ARIA_LABEL,
      filterQuery: createFilterQuery(selectedValue, selectedMin, selectedMax),
      isSmartCandidates,
      label: meta.label,
    }
  }
)

export const { setOnDemandSmartCandidatesValue, setOnDemandNoSmartCandidates } =
  SmartCandidatesSlice.actions

export default SmartCandidatesSlice.reducer
