import React, { useCallback, useEffect, useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  selectGeography,
  setOnDemandAllUSA,
  setOnDemandInState,
  setOnDemandOutofState,
  setOnDemandSpecificStates,
  setOnDemandZipFile,
  setOnDemandZip5Codes,
  setOnDemandZip3Codes,
  setOnDemandOutsideUS,
} from './GeographySlice'
import { Input } from 'Components/shared/input'
import { FilterSingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { VirtualizedMultiSelect } from 'Components/shared/multiselectdropdown'
import { useGetInstitutionsInfoQuery } from 'Services/global/institution'
import { useGetZipCodesQuery } from 'Services/configuration/zipCodes'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { validateZip5Codes, validateZip3Codes } from 'Utils/zipCodes'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { FileCategory } from 'Types/fileCategory'
import { Loading } from 'Utils/loading'

interface GeographyProps {
  control: any
  filterData?: any
  zip_id?: string
}

export const getFilesfilterQuery = {
  query: {
    filterQuery: {
      properties: [
        {
          column: 'category',
          op: 'eq',
          value: FileCategory.ZipSegmentFilter,
        },
      ],
      operation: 'AND',
    },
  },
}

export const Geography = memo(({ control, filterData, zip_id }: GeographyProps) => {
  const dispatch = useDispatch()
  const { ipedId } = useParams()
  const geography = useSelector(selectGeography)
  const { data } = useGetInstitutionsInfoQuery()
  const { data: zipCodesData, isLoading } = useGetZipCodesQuery({
    ipedId,
    page: 0,
    sortBy: 'updated_at',
    order: 'desc',
    filter: buildFilterQuery(getFilesfilterQuery),
  })
  const [zipFiles, setZipFiles] = useState([])

  const institution = data?.find((institution) => institution.id === ipedId)

  const setSelectedValue = useCallback((value: string) => {
    if (value === 'All USA') dispatch(setOnDemandAllUSA())
    if (value === 'In-State')
      dispatch(setOnDemandInState(institution?.state_name))
    if (value === 'Out-of-State')
      dispatch(setOnDemandOutofState(institution?.state_name))
    if (value === 'Specific States/Territories')
      dispatch(setOnDemandSpecificStates())
    if (value === 'Zipcodes') dispatch(setOnDemandZipFile())
    else if (value === 'Zip Code 5') dispatch(setOnDemandZip5Codes())
    if (value === 'Zip Code 3') dispatch(setOnDemandZip3Codes())
    if (value === 'Outside the US') dispatch(setOnDemandOutsideUS())
  }, [])

  const setStates = useCallback((value: string[]) => {
    dispatch(setOnDemandSpecificStates(value))
  }, [])

  /** This useEffect is for setting filters in edit segment */
  useEffect(() => {
    if (zip_id && zipCodesData) {
      const zipFile = zipCodesData?.records?.find(
        (zipCode: any) => zipCode.id === zip_id
      )
      dispatch(setOnDemandZipFile(zipFile))
    } else if (filterData?.[geography.label]) {
      const geographyData = Array.isArray(filterData[geography.label]) ? filterData[geography.label].find((data: any) => data.selectedValue) : filterData[geography.label]
      if (geographyData.selectedValue === 'All USA')
        dispatch(setOnDemandAllUSA())
      else if (geographyData.selectedValue === 'In-State')
        dispatch(setOnDemandInState(institution?.state_name))
      else if (geographyData.selectedValue === 'Out-of-State')
        dispatch(setOnDemandOutofState(institution?.state_name))
      else if (geographyData.selectedValue === 'Specific States/Territories')
        dispatch(setOnDemandSpecificStates(geographyData.states))
      else if (geographyData.selectedValue === 'Zipcodes')
        dispatch(setOnDemandZipFile(geographyData.zipCodes))
      else if (geographyData.selectedValue === 'Zip Code 5')
        dispatch(setOnDemandZip5Codes(geographyData.zipCodes))
      else if (geographyData.selectedValue === 'Zip Code 3')
        dispatch(setOnDemandZip3Codes(geographyData.zipCodes))
      else if (geographyData.selectedValue === 'Outside the US')
        dispatch(setOnDemandOutsideUS())
    }
  }, [filterData, zip_id, zipCodesData])

  useEffect(() => {
    if (zipCodesData) {
      let rows = []
      zipCodesData.records?.forEach((zipCode: any) => {
        rows.push({ id: zipCode.id, name: zipCode.name })
      })
      setZipFiles(rows)
    }
  }, [zipCodesData])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <FilterSingleSelectDropdown
        label='Geography'
        values={geography.values}
        value={geography.selectedValue}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setSelectedValue(e.target.value)
        }}
      />
      {geography.selectedValue === 'Specific States/Territories' && (
        <div
          style={{
            marginLeft: '10px',
            marginTop: '10px',
          }}
        >
          <VirtualizedMultiSelect
            label=''
            counterLabel={'States'}
            values={geography.allUSAStatesTerritories}
            initialSelectedValues={geography.selectedStates}
            selectedValues={geography.selectedStates}
            setSelectedValues={setStates}
          />
        </div>
      )}
      {geography.selectedValue === 'Zipcodes' && (
        <div
          style={{
            marginLeft: '10px',
            marginTop: '10px',
          }}
        >
          <SingleSelectDropdown
            id='zipCodes'
            name='zipCodes'
            data-testid='zipCodes'
            rules={{ required: true }}
            style={{ width: '253px' }}
            values={zipFiles?.map((zipFile: any) => zipFile.name)}
            value={geography.zipFile.name}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const zipFile = zipCodesData.records?.find(
                (zipCode: any) => zipCode.name === e.target.value
              )
              dispatch(setOnDemandZipFile(zipFile))
            }}
          />
        </div>
      )}

      {geography.selectedValue === 'Zip Code 5' && (
        <div
          style={{
            marginLeft: '10px',
            marginTop: '10px',
          }}
        >
          <Input
            id='add-zip5-codes'
            placeholder='Add Zip Codes separated by commas'
            value={geography.zip5Codes}
            name='zip5Codes'
            control={control}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(setOnDemandZip5Codes(e.target.value))
            }}
            rules={{
              validate: validateZip5Codes,
            }}
            multiline
            rows={5}
          />
        </div>
      )}

      {geography.selectedValue === 'Zip Code 3' && (
        <div
          style={{
            marginLeft: '10px',
            marginTop: '10px',
          }}
        >
          <Input
            id='add-zip3-codes'
            placeholder='Add Zip Codes separated by commas'
            value={geography.zip3Codes}
            name='zip3Codes'
            control={control}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(setOnDemandZip3Codes(e.target.value))
            }}
            rules={{
              validate: validateZip3Codes,
            }}
            multiline
            rows={5}
          />
        </div>
      )}
    </>
  )
})
