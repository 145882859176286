import { createApi } from '@reduxjs/toolkit/query/react'
import { Destination } from 'Services/configuration/destinations'
import {
  contractsQuery,
  transformContractsQueryResponse,
  Contract,
  transformContractByIdQueryResponse,
  getContractByIdquery,
  GetContractsTransformedResponse,
  getSubscribedNotifications,
  ContractApiResponse,
  GetContractsApiResponse,
  createContract,
  addDestinationsToContract,
  subscribeUsertoContractNotifications,
  updateContract,
  searchContractByLabel,
} from './contractsUtils'

import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { GetUsers } from 'Services/configuration/users'
import { baseQuery } from '../getBaseQuery'

interface GetDestinationsForContract {
  data: Destination[]
}

const CONTRACTS = 'CANDIDATE_CONTRACTS'
const CONTRACT_DESTINATIONS = 'CANDIDATE_CONTRACT_DESTINATIONS'
const CONTRACT_NOTIFICATIONS = 'CANDIDATE_CONTRACT_NOTIFICATIONS'

export const CandidateContractsApi = createApi({
  reducerPath: 'candidateContracts',
  baseQuery,
  tagTypes: [CONTRACTS, CONTRACT_DESTINATIONS, CONTRACT_NOTIFICATIONS],
  endpoints: (build) => ({
    /** gets all contracts for an institution by page */
    getCandidatesContracts: build.query<
      GetContractsTransformedResponse,
      {
        ipedId: string
        page: number
        size: number
        order: string
        filter?: string | object
      }
    >({
      query: contractsQuery,
      providesTags: [CONTRACTS],
      transformResponse: transformContractsQueryResponse,
    }),
    /** get all contracts for an institution */
    getAllCandidatesContracts: build.query<
      Contract[],
      {
        ipedId: string
        filterQuery: string | object
      }
    >({
      queryFn: async (
        { ipedId, filterQuery },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const firstPageContracts = await fetchWithBQ({
          url: `institutions/${ipedId}/contracts?page=0&size=10&sort_by=updated_at::desc&filter=${filterQuery}`,
        })
        if (firstPageContracts?.error)
          return { error: firstPageContracts?.error as FetchBaseQueryError }

        let allContracts =
          (firstPageContracts?.data?.['data']?.['records'] as Contract[]) ?? []

        const totalPages =
          firstPageContracts?.data?.['data']?.['total_pages'] ?? 0

        for (let page = 1; page < totalPages; page++) {
          const contracts = await fetchWithBQ({
            url: `institutions/${ipedId}/contracts?page=${page}&size=10&sort_by=updated_at::desc&filter=${filterQuery}`,
          })
          allContracts = [
            ...allContracts,
            ...contracts?.data?.['data']?.['records'],
          ]
        }

        return { data: allContracts }
      },
      providesTags: [CONTRACTS],
    }),

    searchContractByLabel: build.query<
      GetContractsApiResponse,
      { ipedId: string; filterQuery: any }
    >({
      query: searchContractByLabel,
      providesTags: [CONTRACTS],
    }),

    getCandidatesContractsById: build.query<
      Contract,
      { ipedId: string; contractId: string }
    >({
      query: getContractByIdquery,
      providesTags: [CONTRACTS],
      transformResponse: transformContractByIdQueryResponse,
    }),

    /** Get Destinations for a Contract */
    getDestinationsForContract: build.query<
      GetDestinationsForContract,
      { ipedId: string; contractId: string }
    >({
      query: ({ ipedId, contractId }) => ({
        url: `institutions/${ipedId}/contracts/${contractId}/destinations`,
        method: 'GET',
      }),
      providesTags: [CONTRACT_DESTINATIONS],
    }),

    /** Get Subscribed Notifications for a Contract */
    getSubscribedNotifications: build.query<
      GetUsers,
      { ipedId: string; contractId: string }
    >({
      query: getSubscribedNotifications,
      providesTags: [CONTRACT_NOTIFICATIONS],
    }),

    createContract: build.mutation<ContractApiResponse, Partial<Contract>>({
      query: createContract,
      invalidatesTags: [CONTRACTS],
    }),
    updateContract: build.mutation<
      ContractApiResponse,
      { ipedId: string; contractId: string; contract: Partial<Contract> }
    >({
      query: updateContract,
      invalidatesTags: [CONTRACTS],
    }),
    addDestinationsToContract: build.mutation<
      null,
      { ipedId: string; contractId: string; destinationIds: string[] }
    >({
      query: addDestinationsToContract,
      invalidatesTags: [CONTRACT_DESTINATIONS],
    }),
    subscribeUsertoContractNotifications: build.mutation<
      null,
      {
        ipedId: string
        contractId: string
        accountIdsEnabled: string[]
      }
    >({
      query: subscribeUsertoContractNotifications,
      invalidatesTags: [CONTRACT_NOTIFICATIONS],
    }),
  }),
})

export const {
  useGetCandidatesContractsQuery,
  useLazySearchContractByLabelQuery,
  useGetAllCandidatesContractsQuery,
  useGetDestinationsForContractQuery,
  useLazyGetCandidatesContractsQuery,
  useLazyGetCandidatesContractsByIdQuery,
  useGetCandidatesContractsByIdQuery,
  useGetSubscribedNotificationsQuery,
  useCreateContractMutation,
  useAddDestinationsToContractMutation,
  useSubscribeUsertoContractNotificationsMutation,
  useUpdateContractMutation,
} = CandidateContractsApi
