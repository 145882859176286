import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

const initialState = {
  disableUpdate: true,
  isDuplicating: false,
}

export const ContractStateSlice = createSlice({
  name: 'timeBasedContractState',
  initialState,
  reducers: {
    setDisableUpdate: (state, action: PayloadAction<boolean>) => {
      state.disableUpdate = action.payload
    },
    resetDisableUpdate: (state) => {
      state.disableUpdate = true
    },
    setDuplicating: (state, action: PayloadAction<boolean>) => {
      state.isDuplicating = action.payload
    }
  },
})

export const { setDisableUpdate, resetDisableUpdate, setDuplicating } =
  ContractStateSlice.actions

export default ContractStateSlice.reducer
