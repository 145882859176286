import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../getBaseQuery'

export interface InstitutionAPIResponse {
  data: {
    id: string
    name: string
    state: string
    country: string
    state_name: string
  }
}

export interface InstitutionResponse {
  id: string
  name: string
  state: string
  country: string
  state_name: string
}

export const InstitutionsApi = createApi({
  reducerPath: 'institution',
  baseQuery,
  endpoints: (build) => ({
    /** Get iped id and name for all the institutions */
    getInstitutionsInfo: build.query<InstitutionResponse[], void>({
      query: () => ({ url: 'institutions' }),
      transformResponse: (response: { data: InstitutionResponse[] }) => {
        const univData = response.data
        return univData.sort((a, b) => a.name.localeCompare(b.name))
      },
    }),
  }),
})

export const { useGetInstitutionsInfoQuery } = InstitutionsApi
