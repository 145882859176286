import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

const initialState = {
  disableUpdate: true,
}

export const ContractStateSlice = createSlice({
  name: 'onDemandContractState',
  initialState,
  reducers: {
    setDisableUpdate: (state, action: PayloadAction<boolean>) => {
      state.disableUpdate = action.payload
    },
    resetDisableUpdate: (state) => {
      state.disableUpdate = true
    },
  },
})

export const { setDisableUpdate, resetDisableUpdate } =
  ContractStateSlice.actions

export default ContractStateSlice.reducer
