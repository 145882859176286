import React, { useEffect, useState, memo } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
  ContractSection,
  ContractSectionHeader,
  ContractConfigurationFieldContainer,
} from 'Components/shared/contracts'
import { DialogField } from 'Components/shared/dialog'
import { VirtualizedMultiSelect } from 'Components/shared/multiselectdropdown'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { useGetUsersQuery } from 'Services/configuration/users'
import { useGetSubscribedNotificationsQuery } from 'Services/contracts/inquiriesContracts'
import { useGetSupportedColumnsQuery } from 'Services/global/introspection'
import { useGetSubscribedNotificationsQuery as useGetCandidateSubscribedNotificationQuery } from 'Services/contracts/candidatesContracts'
import { TransferList } from 'Components/shared/transferlist'
import { Table } from 'Components/shared/table'
import { NumberDaysToHuman } from 'Utils/dateUtils'
import { Loading } from 'Utils/loading'
import { useGetContractConfigQuery } from 'Services/global/contractConfig'
import { ContractType, FileFormat, ContractSource } from 'Types/index'

const Divider = styled('div')(() => ({
  height: '1px',
  width: '421px',
  marginTop: '20px',
  marginBottom: '10px',
  background: 'hsla(223, 70%, 92%, 1)',
}))

const columnData = [
  { columnName: 'Name', columnWidth: 260, sortOrder: 'ASC' },
  { columnName: 'Email', columnWidth: 250, sortOrder: 'ASC' },
]

interface DeliveryCriteriaProps {
  audienceType?: string
  contractSource: ContractSource
  contract: any
  contractType: ContractType
  allDestinations: any[]
  selectedDestinations: any[]
  selectedFileFormat: FileFormat
  selectedDeliveryCadence?: string[]
  selectedDeliveryModel?: string
  selectedFields: string[]
  allDeliveryCadence?: string[]
  allLeads: string[]
  selectedLeads: string[]
  allLookbackPeriods?: string[]
  selectedLookbackPeriod?: string

  setAllDestinations: (allDestinations: any[]) => any
  setSelectedDestinations: (selectedDestinations: any[]) => any
  setServerSelectedDestinations?: (selectedDestinations: any[]) => any
  setSelectedFileFormat: (selectedFileFormat: string) => any
  setServerSelectedFileFormat?: (selectedFileFormat: string) => any
  setSelectedDeliveryCadence?: (selectedDeliveryCadence: string[]) => any
  setServerSelectedDeliveryCadence?: (selectedDeliveryCadence: string[]) => any
  setSelectedDeliveryModel?: (selectedDeliveryModel: string) => any
  setServerSelectedDeliveryModel?: (selectedDeliveryModel: string) => any
  setSelectedFields: (selectedFields: string[]) => any
  setServerSelectedFields?: (selectedFields: string[]) => any
  setSelectedLeads: (selectedLeads: string[]) => any
  setServerSelectedLeads?: (selectedLeads: string[]) => any
  setSelectedLookbackPeriod?: (selectedLookbackPeriod: string) => any
  setServerLookbackPeriod?: (selectedLookbackPeriod: string) => any

  disabledContract: boolean
}

export const DeliveryCriteria = memo(
  ({
    audienceType,
    contractSource,
    contract,
    contractType,
    allDestinations,
    allDeliveryCadence,
    selectedDestinations,
    selectedFileFormat,
    selectedDeliveryCadence,
    selectedDeliveryModel,
    selectedFields,
    allLeads,
    selectedLeads,
    allLookbackPeriods,
    selectedLookbackPeriod,
    setAllDestinations,
    setSelectedDestinations,
    setServerSelectedDestinations,
    setSelectedFileFormat,
    setServerSelectedFileFormat,
    setSelectedDeliveryCadence,
    setServerSelectedDeliveryCadence,
    setSelectedDeliveryModel,
    setServerSelectedDeliveryModel,
    setSelectedFields,
    setServerSelectedFields,
    setSelectedLeads,
    setServerSelectedLeads,
    setSelectedLookbackPeriod,
    setServerLookbackPeriod,
    disabledContract,
  }: DeliveryCriteriaProps) => {
    const { ipedId } = useParams()
    const dispatch = useDispatch()
    const [allUsers, setAllUsers] = useState([])
    const [rowIdxs, setRowIdxs] = useState<boolean[]>([])
    const { data: contractConfig } = useGetContractConfigQuery({
      contractSource,
      audienceType,
    })

    /** Get All Users for displaying notifications */
    const { data: usersInfo } = useGetUsersQuery(ipedId)
    const { data: supportedColumns } = useGetSupportedColumnsQuery()

    const notificationQuery =
      contractType === ContractType.annual
        ? useGetSubscribedNotificationsQuery
        : useGetCandidateSubscribedNotificationQuery

    /** Get Subscribed notifications for that contract*/
    const {
      data: allSubscribedNotifications,
      isLoading: isLoadingSubscibedNotifications,
    } = notificationQuery({
      ipedId,
      contractId: contract?.id,
    })

    useEffect(() => {
      let rows = []
      if (usersInfo) {
        usersInfo?.forEach((user: any) => {
          const fullName = user.first_name + ' ' + user.last_name
          rows.push(['', fullName, user.email])
        })
        setAllUsers(rows)
      }
    }, [usersInfo])

    useEffect(() => {
      if (allSubscribedNotifications) {
        let notifications = []
        let rowIdxs = []
        allSubscribedNotifications?.data.forEach((notification: any) => {
          if (notification.is_enabled) {
            notifications.push(notification.id)
            rowIdxs.push(true)
          } else {
            rowIdxs.push(false)
          }
        })

        setRowIdxs(rowIdxs)

        if (notifications.length > 0)
          dispatch(setServerSelectedLeads(notifications))
        else dispatch(setServerSelectedLeads([]))
      }
    }, [allSubscribedNotifications])

    useEffect(() => {
      if (contract) {
        setSelectedDeliveryCadence &&
          dispatch(setServerSelectedDeliveryCadence(contract?.delivery_cadence))
        setSelectedDeliveryModel &&
          dispatch(setServerSelectedDeliveryModel(contract?.delivery_model))
        dispatch(setServerSelectedFields(contract?.fields_to_export || []))
        setServerLookbackPeriod &&
          dispatch(
            setServerLookbackPeriod(
              contract?.look_back_period
                ? NumberDaysToHuman[contract?.look_back_period]
                : ''
            )
          )
      }
    }, [contract])

    const handleAllClick = (flag: boolean) => {
      setRowIdxs(rowIdxs.map(() => flag))
      if (flag) {
        dispatch(
          setSelectedLeads(usersInfo?.map((user: any, idx: number) => user.id))
        )
      } else {
        dispatch(setSelectedLeads([]))
      }
    }

    const handleRowClick = (rowIdx: number) => {
      const newRows = [...rowIdxs]
      newRows[rowIdx] = !newRows[rowIdx]

      setRowIdxs(newRows)

      let result = []
      usersInfo?.forEach((user: any, idx: number) => {
        if (newRows[idx]) result.push(user.id)
      })

      dispatch(setSelectedLeads(result))
    }

    const handleSelectedDestinations = (
      selectedDestinationLabels: string[]
    ) => {
      if (allDestinations) {
        let selectedDestinations = []
        allDestinations.forEach((destination: any) => {
          if (selectedDestinationLabels.includes(destination.label))
            selectedDestinations.push(destination)
        })

        dispatch(setSelectedDestinations(selectedDestinations))
      }
    }

    const handleSelectedDeliveryCadence = (selectedCadenceLabels: string[]) => {
      if (selectedCadenceLabels.length > 0) {
        dispatch(setSelectedDeliveryCadence(selectedCadenceLabels))
      }
    }

    if (isLoadingSubscibedNotifications) return <Loading />

    return (
      <ContractSection>
        <ContractSectionHeader label={'Delivery Criteria'} />
        <ContractConfigurationFieldContainer>
          <DialogField
            id='add-delivery-destination'
            label='Delivery Destination'
          >
            <VirtualizedMultiSelect
              label={''}
              counterLabel={'Destinations'}
              values={
                allDestinations?.map(
                  (destination: any) => destination?.label
                ) || []
              }
              initialSelectedValues={
                selectedDestinations?.map(
                  (destination: any) => destination?.label
                ) || []
              }
              selectedValues={
                selectedDestinations?.map(
                  (destination: any) => destination?.label
                ) || []
              }
              setSelectedValues={handleSelectedDestinations}
              disabled={disabledContract}
            />
          </DialogField>
          <DialogField id='add-file-format' label='*File Format to Deliver'>
            <SingleSelectDropdown
              id='add-file-format-select'
              name='fileFormat'
              style={{ width: '279px' }}
              rules={{
                required: 'File Format is a required field',
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(setSelectedFileFormat(e.target.value))
              }
              value={selectedFileFormat}
              values={Object.values(FileFormat)}
              disabled={disabledContract}
            />
          </DialogField>

          {contractConfig?.fields_available.includes('delivery_lookback') && (
            <DialogField id='add-delivery-lookback' label='*Delivery Look-Back'>
              <SingleSelectDropdown
                id='add-delivery-lookback'
                name='deliveryLookback'
                style={{ width: '279px' }}
                rules={{
                  required: 'Delivery Look-Back is a required field',
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSelectedLookbackPeriod &&
                  dispatch(setSelectedLookbackPeriod(e.target.value))
                }
                values={allLookbackPeriods}
                value={selectedLookbackPeriod}
              />
            </DialogField>
          )}

          {contractConfig?.fields_available.includes('delivery_cadence') && (
            <DialogField id='add-delivery-cadence' label='*Delivery Cadence'>
              <VirtualizedMultiSelect
                label={''}
                counterLabel={'Cadence'}
                values={allDeliveryCadence || []}
                initialSelectedValues={selectedDeliveryCadence || []}
                selectedValues={selectedDeliveryCadence || []}
                setSelectedValues={handleSelectedDeliveryCadence}
                disabled={disabledContract}
              />
            </DialogField>
          )}

          {contractConfig?.fields_available.includes('delivery_model') && (
            <DialogField id='add-delivery-model' label='Delivery Model'>
              <SingleSelectDropdown
                id='add-delivery-model-select'
                style={{ width: '279px' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(setSelectedDeliveryModel('lead_cap_reached'))
                }
                value={'Deliver inquiries until cap is hit'}
                values={['Deliver inquiries until cap is hit']}
                disabled={disabledContract}
              />
            </DialogField>
          )}

          <DialogField id='divider' label=''>
            <Divider />
          </DialogField>

          <DialogField
            id='add-fields-to-export'
            label='Fields to Export'
            alignSelf='flex-start'
          >
            <TransferList
              allItems={supportedColumns
              }
              exportedItems={selectedFields}
              exportItems={(selectedItems: string[]) =>
                dispatch(setSelectedFields(selectedItems))
              }
              disabled={
                disabledContract ||
                supportedColumns?.length === 0
              }
            />
          </DialogField>
          <DialogField id='divider' label=''>
            <Divider />
          </DialogField>
          <DialogField
            id='automatic-lead-report'
            label='Automatic Lead Report Notification'
            alignSelf='flex-start'
          >
            <Table
              width={'516px'}
              height={'248px'}
              columnData={columnData}
              rows={allUsers}
              variant='striped'
              isSelectable={true}
              selectedAll={rowIdxs.every((elem) => elem === true)}
              handleAllClick={handleAllClick}
              rowIndexes={rowIdxs}
              handleRowClick={handleRowClick}
              disabled={disabledContract}
              handleCheckboxClick={(_, idx: number) => handleRowClick(idx)}
            />
          </DialogField>
        </ContractConfigurationFieldContainer>
      </ContractSection>
    )
  }
)
