import React, { useState, useMemo } from 'react'
import { DatePickerComponent as DatePicker } from 'Components/shared/datepicker/DatePicker'

interface DatePickerProps {
  /** the name of the date picker */
  name: string
  /** the control to register the date picker */
  control: any
  /* the rules to apply to the date picker */
  rules: any
  /** the label for the date picker period */
  label: string
  /** the initial date value if applied */
  value?: string
  setValue?: (newValue: string) => void
  /** the function to call when the date picker value changes */
  onChange?: (newValue: string) => void
  /** boolean that determines styling and disables input if contract is expired */
  disabled?: boolean
  /** the min date criteria for end date input */
  minDate?: any
  /** boolean that determines whether date input can be in the past */
  disablePast?: boolean
}

export const DatePickerContainer = ({
  name,
  label,
  value,
  setValue,
  onChange,
  disabled,
  minDate,
  disablePast,
  rules,
  control,
  ...props
}: DatePickerProps) => {
  const [error, setError] = useState('')
  const [open, setOpen] = useState(false)

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'minDate':
        return 'End date must be after Start date'
      case 'disablePast':
        return 'End date cannot be in the past'
      case 'invalidDate':
        return 'Invalid date'
      default: {
        return ''
      }
    }
  }, [error])

  return (
    <DatePicker
      name={name}
      control={control}
      rules={rules}
      label={label}
      value={value}
      setValue={setValue}
      onChange={onChange}
      minDate={minDate}
      errorMessage={errorMessage}
      open={open}
      setOpen={setOpen}
      setError={setError}
      disablePast={disablePast}
      {...props}
    />
  )
}
