import React, {useState} from 'react'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Pencil from 'Assets/images/edit_pencil.svg'
import {
  BodyModals,
} from 'Components/shared/body'
import { ContractStatus } from 'Types/index'
import { ContractNameDialog } from 'Components/connectedComponents/contracts/ContractNameDialog'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { useGetCandidatesContractsByIdQuery } from 'Services/contracts/candidatesContracts'
import { useGetInquiryContractByIdQuery } from 'Services/contracts/inquiriesContracts'

const EditTitle = styled(Pencil)(() => ({
  position: 'absolute',
  right: '-30px',
  top: '8px',
  cursor: 'pointer',
}))

const Title = styled('div')(({ theme }) => ({
  color: theme.palette.secondary['100'],
  fontSize: '2rem',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '30px',
}))

const SubLabel = styled('div')(({ theme }) => ({
  marginTop: '8px',
}))

interface HeaderProps {
  /** label is the name of the Contract type header */
  label: string
  /* children is the sublabel*/
  children?: React.ReactNode
}

export const ContractHeaderLabel = ({ label, children }: HeaderProps) => {
  const { contractId, ipedId } = useParams()
  const isInquiriesContract = window.location.pathname.includes('inquiries') || window.location.hash.includes('inquiries')
  const { data: contractData, isLoading: isContractLoading } = isInquiriesContract ? useGetInquiryContractByIdQuery({
    ipedId,
    contractId,
  }) :
  useGetCandidatesContractsByIdQuery({
    ipedId,
    contractId,
  })
  const isExpired = [ContractStatus.expired, ContractStatus.completed].includes(
    contractData?.status
  )
  const [editContractDialog, setEditContractDialog] = useState(false)
  const { timeBasedContractState: {isDuplicating} } = useSelector((state: any) => state)
  const { hasPermission: canViewEnhancedCandidates } = useHasPermission(
    Permissions.ViewEnhancedCandidatesSegmentDetails
  )
  const isEditable = children && canViewEnhancedCandidates && !isExpired && !isDuplicating
  return (
    <>
      <Title>
        {label} 
        {isEditable && <EditTitle onClick={() => setEditContractDialog(true)}/>}
        <SubLabel>{children}</SubLabel>
      </Title>
      <BodyModals>
          <ContractNameDialog closeDialog={() => setEditContractDialog(false)} isOpen={editContractDialog}/>
      </BodyModals>
    </>
  )
}
