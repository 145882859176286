import { createSlice, PayloadAction } from '@reduxjs/toolkit'
export interface SearchState {
  value: string
}

const initialState: SearchState = {
  value: '',
} as SearchState

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.value = action.payload
    },
    clearSearch(state) {
      state.value = ''
    },
  },
})

const { actions, reducer } = searchSlice
export const { setSearch, clearSearch } = actions
export default reducer
