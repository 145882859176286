import React from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ClickAwayListener } from '@mui/material'
import { styled } from '@mui/material/styles'
import Calendar from 'Assets/images/calendar.svg'
import { useController } from 'react-hook-form'
import moment from 'moment'

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  '& .MuiInputBase-root': {
    color: theme.palette.secondary.main,
    fontSize: '0.75rem',
    lineHeight: '18px',
    height: '40px',
    width: '134px',
  },
  '& .MuiFormLabel-root': {
    color: theme.palette.primary.dark,
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '15px',
    marginTop: '-3px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset, &:hover fieldset, &.Mui-focused fieldset': {
      border: `1.5px solid ${theme.palette.secondary['80']}`,
    },
  },
  '& .Mui-disabled': {
    backgroundColor: theme.palette.common.white,
    '-webkit-text-fill-color': theme.palette.primary.dark,
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1.5px solid ${theme.palette.secondary['80']}`,
    },
  },
}))

const ErrorMessage = styled('p')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '0.7rem',
  lineHeight: '16px',
  color: theme.palette.error.main,
  marginBottom: '0px',
}))

const dateFormat = 'MM/DD/yyyy'

interface DatePickerProps {
  /** the name of the date picker */
  name: string
  /** the control to register the date picker */
  control: any
  /* the rules to apply to the date picker */
  rules: any
  /** the label for the date picker period */
  label: string
  /** the initial date value if applied */
  value?: string
  setValue?: (newValue: string) => void
  /** the function to call when the date picker value changes */
  onChange: (newValue: string) => void
  /** boolean that determines styling and disables input if contract is expired */
  disabled?: boolean
  /** the min date criteria for end date input */
  minDate?: any
  /** the function that sets error type */
  setError?: (err: string) => void
  /** the message to display depending on error type */
  errorMessage?: string
  /** the function that determines whether to open datepicker window */
  setOpen?: any
  /** boolean that opens datepicker window */
  open?: boolean
  /** boolean that determines whether date input can be in the past */
  disablePast?: boolean
}

export const DatePickerComponent = ({
  name,
  control,
  rules,
  label,
  value,
  setValue,
  onChange,
  disabled,
  minDate,
  errorMessage,
  setError,
  open,
  setOpen,
  disablePast,
  ...props
}: DatePickerProps) => {
  const {
    field: { onChange: handleFieldChange, value: controllerValue, ref },
    formState: { errors },
  } = useController({ name, control, rules })
  const dataPickerValue = controllerValue
    ? moment(controllerValue, dateFormat).toDate()
    : value
    ? moment(value, dateFormat).toDate()
    : null
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ClickAwayListener
        onClickAway={() => setOpen(false)}
        mouseEvent='onMouseDown'
        touchEvent='onTouchStart'
      >
        <div>
          <StyledDatePicker
            onAccept={() => setOpen(false)}
            open={open}
            label={label}
            inputFormat='MM/dd/yyyy'
            value={dataPickerValue}
            inputRef={ref}
            name={name}
            onChange={(newValue) => {
              handleFieldChange(newValue)
              onChange(newValue)
            }}
            onError={(e: string) => setError(e)}
            slots={{
              openPickerIcon: Calendar,
            }}
            slotProps={{
              textField: {
                helperText: errorMessage,
              },
              openPickerButton: {
                onClick: () => setOpen((prevOpen) => !prevOpen),
              },
            }}
            minDate={minDate}
            disabled={disabled}
            disablePast={disablePast}
            {...props}
          />
          {errors[name]?.message && (
            <ErrorMessage>{errors[name]?.message}</ErrorMessage>
          )}
        </div>
      </ClickAwayListener>
    </LocalizationProvider>
  )
}
