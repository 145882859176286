import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import Button from '@mui/material/Button'
import {
  BodyHeader,
  BodyActions,
  ContractHeaderLabel,
  ContractHeaderSubLabel,
} from 'Components/shared/body'
import { setDuplicating } from 'Containers/inquiries/contractdetails/TimeBasedContractStateSlice'

import GoBack from 'Assets/images/go_back.svg'

const Container = styled('div')(() => ({
  position: 'relative',
  marginBottom: '20px',
}))

const PreviousPage = styled(GoBack)(() => ({
  position: 'absolute',
  left: '-40px',
  top: '8px',
  cursor: 'pointer',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const UpdateButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
    border: 'none',
  },
  [theme.breakpoints.up('md')]: {
    width: '104px',
  },
}))

interface ContractDetailsProps {
  contract: any
  goBack: () => void
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabledContract: boolean
  disableUpdate?: boolean
}

export const ContractDetails = memo(
  ({
    contract,
    goBack,
    onClick,
    disabledContract,
    disableUpdate,
  }: ContractDetailsProps) => {
    const dispatch = useDispatch()
    const { timeBasedContractState: {isDuplicating} } = useSelector((state: any) => state)
    const canDuplicate = contract.source === 'inquiries'
    return (
      <BodyHeader>
        <Container>
          <PreviousPage onClick={(ev) => {canDuplicate && dispatch(setDuplicating(false)); return goBack()}} />
          <ContractHeaderLabel label={`${isDuplicating ? 'New Contract based on ' : ''}${contract['label']}`}>
            <ContractHeaderSubLabel
              label={'DATE CREATED'}
              value={contract['created_at']}
            />
            <ContractHeaderSubLabel
              label={'UPDATED'}
              value={contract['updated_at']}
            />
          </ContractHeaderLabel>
        </Container>
        <BodyActions>
          {canDuplicate && (<UpdateButton onClick={() => {dispatch(setDuplicating(!isDuplicating))}} >
            {isDuplicating ? 'Cancel' : 'Duplicate'}
          </UpdateButton>)}
          {(!disabledContract || isDuplicating) && (
            <UpdateButton onClick={onClick} disabled={disableUpdate}>
              {isDuplicating ? 'Add' : 'Update'}
            </UpdateButton>
          )}
        </BodyActions>
      </BodyHeader>
    )
  }
)
