import React, { useMemo, useState, memo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  useGetSegmentsForContractQuery,
  useDeactivateSegmentMutation,
} from 'Services/segments/segments'
import { SegmentsList as SegmentsListComponent } from 'Components/inquiries/contracts/segments/SegmentsList'
import { SegmentsDetails } from 'Containers/shared/contracts/segments'

import { Loading } from 'Utils/loading'
import { useToggle } from 'Hooks/useToggle'
import { ContractSource, SegmentAction } from 'Types/index'

interface SegmentsListProps {
  contract: any
  disabledContract: boolean
  setSegmentLeadCapTotal?: (segmentLeadCapTotal: number) => any
}

const columnData = [
  {
    columnName: 'Segment Name',
    columnWidth: 250,
    sortOrder: 'ASC',
  },
  { columnName: 'Segment Lead Cap', columnWidth: 76, sortOrder: 'ASC' },
  { columnName: '% of Lead Cap Requested', columnWidth: 75, sortOrder: 'ASC' },
  { columnName: 'Status', columnWidth: 100, sortOrder: 'ASC' },
]

export const SegmentsList = memo(
  ({
    contract,
    disabledContract,
    setSegmentLeadCapTotal,
  }: SegmentsListProps) => {
    const navigate = useNavigate()
    const [segmentDetails, setSegmentDetails] = useToggle(false)
    const [rowIdx, setRowIdx] = useState(-1)
    const { ipedId } = useParams()
    const { data: segments, isLoading: isLoadingSegments } =
      useGetSegmentsForContractQuery({
        ipedId,
        contractId: contract.id,
      })

    const [deactivateSegment, { isLoading: isDeactivatingSegment }] =
      useDeactivateSegmentMutation()

    const flattenSegments = useMemo(() => {
      let segmentRows = []
      let leadCaptotal = 0
      if (segments && contract) {
        segments?.forEach((segment: any) => {
          segmentRows.push([
            segment.id,
            segment.name,
            segment.lead_total.toLocaleString(),
            `${((segment.lead_total / contract['lead_cap']) * 100).toFixed(
              2
            )}%`,
            segment.is_active ? 'Active ' : 'Deactivated',
          ])
          leadCaptotal += segment.is_active ? segment.lead_total : 0
        })
        segmentRows.push([
          '',
          'Total',
          leadCaptotal.toLocaleString(),
          `${((leadCaptotal / contract['lead_cap']) * 100).toFixed(2)}%`,
          '',
        ])
        setSegmentLeadCapTotal(leadCaptotal)
      }
      return segmentRows
    }, [segments, contract])

    const handleRowClick = (rowIdx: number) => {
      setSegmentDetails()
      setRowIdx(rowIdx)
    }

    const closeSegmentDetailsDialog = () => {
      setSegmentDetails()
    }

    const handleDeactivateSegment = () => {
      closeSegmentDetailsDialog()
      deactivateSegment({
        ipedId,
        segmentId: segments[rowIdx].id,
        isActive: false,
      })
    }

    const handleDuplicateSegment = () => {
      closeSegmentDetailsDialog()
      navigate(
        `/institution/${ipedId}/inquiries/contracts/segment/${segments[rowIdx].id}` +
          `?action=${SegmentAction.duplicate}`
      )
    }

    if (isLoadingSegments || isDeactivatingSegment) return <Loading />

    return (
      <>
        <SegmentsListComponent
          segments={segments}
          flattenSegments={flattenSegments}
          columnData={columnData}
          handleRowClick={handleRowClick}
        />
        {segmentDetails && segments[rowIdx] && (
          <SegmentsDetails
            segmentDetailsDialog={segmentDetails}
            closeSegmentDetailsDialog={closeSegmentDetailsDialog}
            handleDeactivateSegment={handleDeactivateSegment}
            handleDuplicateSegment={handleDuplicateSegment}
            contract={contract}
            contractSource={ContractSource.inquiries}
            chosenSegment={segments[rowIdx]}
            segmentLeadCapTotal={segments[rowIdx].lead_total}
            disabledContract={disabledContract}
          />
        )}
      </>
    )
  }
)
