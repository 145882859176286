import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import InputBase from '@mui/material/InputBase'
import Select from '@mui/material/Select'
import Badge from '@mui/material/Badge'
import { VirtualizedSingleSelect } from 'Components/shared/singleselectdropdown'
import { MenuItem } from './MenuItem'
import UserProfileIcon from 'Assets/images/user_profile.svg'

const Menu = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  position: 'absolute',
  right: '0px',
  top: '100%',
  background: theme.palette.common.white,
  boxShadow: `0px 2px 8px ${alpha(theme.palette.secondary['100'], 0.1)}`,
  cursor: 'auto',
  padding: '24px 32px',
  width: '304px',
  zIndex: theme.zIndex.header,
}))

const MenuLabel = styled('div')(({ theme }) => ({
  color: theme.palette.secondary['100'],
  fontSize: '1.25rem',
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: '-0.2px',
  lineHeight: '25px',
  marginBottom: '34px',
  width: '157px',
}))

const MenuValue = styled('div')(({ theme }) => ({
  color: theme.palette.secondary['100'],
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '19px',
}))

const MenuItemsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '16px',
}))

const PortalLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '17px',
  textDecoration: 'none',
}))

const Divider = styled('div')(({ theme }) => ({
  border: `0.5px solid ${theme.palette.grey['300']}`,
  height: '1px',
  width: '240px',
}))

const LogoutButton = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  backgroundColor: theme.palette.common.white,
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  lineHeight: '20px',
  marginTop: '24px',
  padding: '10px 32px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.2)}`,
  },
}))

const Container = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  height: '100%',
}))

const UserName = styled('div')(({ theme }) => ({
  color: theme.palette.secondary['100'],
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '18px',
  marginLeft: '8px',
  textOverflow: 'ellipsis',
  maxWidth: '140px',
  position: 'relative',
  whiteSpace: 'nowrap',
}))

const CustomInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    position: 'relative',
    background: 'transparent',
    border: `1.2px solid ${theme.palette.primary.light}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
    width: '240px',
    minHeight: '34px',
    padding: '5px 0 0 8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: '4px',
      borderColor: theme.palette.primary.light,
      boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
    },
  },
  '& .MuiSelect-icon': {
    marginRight: '5px',
    top: '45%',
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
}))

const CustomSelect = styled(Select)(({ theme }) => ({
  '& ul': {
    border: `1.2px solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.main,
    minHeight: 'auto',
    maxHeight: '170px',
    overflowY: 'auto',
    padding: '0',
    top: '0',
    left: '0',
    width: '240px',
    '& li': {
      display: 'block',
      fontSize: '0.875rem',
      lineHeight: '18px',
      textOverflow: 'ellipsis',
      overflowX: 'hidden',
      padding: '7px 6px',
      whiteSpace: 'nowrap',
      '&:hover': {
        backgroundColor: `${alpha(theme.palette.primary.main, 0.1)}`,
        overflowX: 'hidden',
      },
    },
  },
}))

const CurrentInstitution = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '0.75rem',
  fontWeight: theme.typography.fontWeightMedium,
  letterSpacing: '0.01em',
  lineHeight: '16px',
  position: 'absolute',
  right: '0',
  top: '27px',
  zIndex: 9,
}))

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.error.main,
  },
}))

const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiAutocomplete-input': {
    paddingTop: '5.5px',
  },
  '& .MuiOutlinedInput-root': {
    position: 'relative',
    background: 'transparent',
    border: `1.2px solid ${theme.palette.primary.light}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
    width: '240px',
    minHeight: '34px',
    padding: '5px 0 0 8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: '4px',
      borderColor: theme.palette.primary.light,
      boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
    },
  },
  '& .MuiSvgIcon-root': {
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
}))

const liStyle = {
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '15px',
  background: 'none',
  height: 'auto',
  width: 'auto',
  color: 'hsla(216, 93%, 46%, 1)',
  paddingLeft: '10px',
  whiteSpace: 'nowrap',
  display: 'inline-block',
}

interface AccountSettingsProps {
  /** User's first name */
  firstName: string
  /** User's last name */
  lastName: string
  /** User's email */
  email: string
  /** Institution id and name */
  institutionsInfo: { id: string; name: string }[]
  /** When the user clicks log out */
  handleLogout: () => void
  /** Flag that tells you to show menu or not */
  showMenu: boolean
  /** To show or hide the user profile */
  setShowMenu: () => void
  /** Institution id  */
  ipedId: string
  /** Institution name */
  institutionName: string
  /** Set the institution id and name in redux */
  changeInstitution: (object) => void
  /** Determines whether to show pending segments badge */
  hasPending: boolean
  /** Determines whether user is in admin portal */
  isAdmin?: boolean
  /** Determines whether user can switch institution */
  canSwitchInstitution?: boolean
}

export const AccountSettings = ({
  firstName,
  lastName,
  email,
  institutionsInfo,
  ipedId,
  institutionName,
  changeInstitution,
  handleLogout,
  showMenu,
  setShowMenu,
  hasPending,
  isAdmin,
  canSwitchInstitution,
}: AccountSettingsProps) => {
  return (
    <Container
      role='dialog'
      aria-labelledby='accountSettingsTitle'
      onMouseEnter={setShowMenu}
      onMouseLeave={setShowMenu}
    >
      <StyledBadge
        role='status'
        color='primary'
        variant='dot'
        overlap='circular'
        badgeContent=' '
        invisible={!hasPending}
      >
        <UserProfileIcon />
      </StyledBadge>
      <UserName>
        {firstName} {lastName}
        {!isAdmin && (
          <CurrentInstitution>
            {institutionName} ID[{ipedId}]
          </CurrentInstitution>
        )}
      </UserName>
      {showMenu && (
        <Menu>
          <MenuLabel id='accountSettingsTitle'>Account Settings</MenuLabel>
          <MenuItemsContainer>
            <MenuItem label={'Name'}>
              <MenuValue>
                {firstName} {lastName}
              </MenuValue>
            </MenuItem>
            <MenuItem label={'Email'}>
              <MenuValue>{email}</MenuValue>
            </MenuItem>
            <MenuItem>
              <Divider />
            </MenuItem>
            {canSwitchInstitution && (
              <>
                <MenuItem>
                  <PortalLink to='/admin/segments-queue'>Admin</PortalLink>
                </MenuItem>
                <MenuItem>
                  <Divider />
                </MenuItem>
                <MenuItem label={'Institution Name'}>
                  <VirtualizedSingleSelect
                    label={''}
                    values={institutionsInfo?.map(
                      (univ) => `[ID ${univ.id}]  ${univ.name}`
                    )}
                    initialSelectedValue={institutionName}
                    selectedValue={institutionName}
                    setSelectedValue={(e) => {
                      changeInstitution(e)
                      setShowMenu()
                    }}
                    StyledInput={StyledInput}
                    liStyle={liStyle}
                  />
                </MenuItem>
                <MenuItem>
                  <Divider />
                </MenuItem>
              </>
            )}
          </MenuItemsContainer>
          <LogoutButton href='/' onClick={handleLogout}>
            Logout
          </LogoutButton>
        </Menu>
      )}
    </Container>
  )
}
