import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import {
  setSalesforceID,
  setStartDate,
  setEndDate,
  setNotes,
  setLeadCap,
  setContractName,
} from 'Containers/inquiries/contractdetails/TimeBasedContractConfigurationSlice'
import {
  setSelectedDestinations,
  setSelectedFileFormat,
  setSelectedDeliveryCadence,
  setSelectedDeliveryModel,
  setSelectedFields,
  setSelectedLeads,
} from 'Containers/inquiries/contractdetails/TimeBasedDeliveryCriteriaSlice'
import {
  setStartDate as setCandidateStartDate,
  setEndDate as setCandidateEndDate,
  setNotes as setCandidateNotes,
} from 'Containers/candidates/contractdetails/OnDemandContractConfigurationSlice'
import {
  setSelectedDestinations as setCandidateSelectedDestinations,
  setSelectedFileFormat as setCandidateSelectedFileFormat,
  setSelectedFields as setCandidateSelectedFields,
  setSelectedLeads as setCandidateSelectedLeads,
  setSelectedLookbackPeriod,
} from 'Containers/candidates/contractdetails/OnDemandDeliveryCriteriaSlice'
import { setDisableUpdate } from 'Containers/inquiries/contractdetails/TimeBasedContractStateSlice'
import { setDisableUpdate as setDisableCandidateUpdate } from 'Containers/candidates/contractdetails/OnDemandContractStateSlice'
import { DeduplicatesApi } from 'Services/contracts/deduplicates'

export const UpdateContractChangesMiddleware = createListenerMiddleware()

UpdateContractChangesMiddleware.startListening({
  /** These reducers are invoked when a client changes the state in inquiries update contract */
  matcher: isAnyOf(
    setSalesforceID,
    setStartDate,
    setEndDate,
    setLeadCap,
    setNotes,
    setSelectedDestinations,
    setSelectedFileFormat,
    setSelectedDeliveryCadence,
    setSelectedDeliveryModel,
    setSelectedFields,
    setSelectedLeads,
    setContractName
  ),
  effect: (action, listenerApi) => {
    if (action.payload) {
      listenerApi.dispatch(setDisableUpdate(false))
    }
  },
})

UpdateContractChangesMiddleware.startListening({
  /** These reducers are invoked when a client changes the state in candidates update contract */
  matcher: isAnyOf(
    setCandidateStartDate,
    setCandidateEndDate,
    setCandidateNotes,
    setCandidateSelectedDestinations,
    setCandidateSelectedFileFormat,
    setCandidateSelectedFields,
    setCandidateSelectedLeads,
    setSelectedLookbackPeriod
  ),
  effect: (action, listenerApi) => {
    if (action.payload) {
      listenerApi.dispatch(setDisableCandidateUpdate(false))
    }
  },
})

UpdateContractChangesMiddleware.startListening({
  matcher: DeduplicatesApi.endpoints.getUploadFileData.matchFulfilled,
  effect: (action, listenerApi) => {
    if (action.payload) {
      listenerApi.dispatch(setDisableCandidateUpdate(false))
    }
  },
})
