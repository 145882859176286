import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import Button from '@mui/material/Button'
import {
  Body,
  BodyActions,
  BodyHeader,
  BodyContent,
  ContractHeaderLabel,
} from 'Components/shared/body'
import { ContractConfiguration } from 'Containers/shared/contracts/contractconfiguration'
import { DeliveryCriteria } from 'Containers/shared/contracts/deliverycriteria'
import {
  useCreateContractMutation,
  useAddDestinationsToContractMutation,
  useSubscribeUsertoContractNotificationsMutation,
  useLazySearchContractByLabelQuery,
} from 'Services/contracts/inquiriesContracts'
import { useGetAudienceTypesQuery } from 'Services/global/audienceTypes'
import {
  setContractName,
  setAudienceType,
  setLeadCap,
  setStartDate,
  setEndDate,
  setNotes,
  setSalesforceID,
  selectTimeBasedContractConfiguration,
  clearContractConfiguration,
} from './TimeBasedContractConfigurationSlice'

import {
  setAllDestinations,
  setSelectedDestinations,
  setSelectedFileFormat,
  setSelectedDeliveryCadence,
  setSelectedDeliveryModel,
  setSelectedFields,
  setSelectedLeads,
  selectTimeBasedDeliveryCriteria,
  clearTimeBasedDeliveryCriteria,
} from './TimeBasedDeliveryCriteriaSlice'
import { formatContractDate } from 'Utils/dateUtils'
import { contractLabelSearchQuery } from 'Services/contracts/contractsUtils'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { Loading } from 'Utils/loading'
import { ContractSource, ContractType } from 'Types/index'

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const CancelButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.4)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const AddButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
    border: 'none',
  },
  [theme.breakpoints.up('md')]: {
    width: '104px',
  },
}))

export const AddInquiriesContract = () => {
  const navigate = useNavigate()
  const { ipedId } = useParams()
  const dispatch = useDispatch()
  const {
    control,
    formState: { errors: formErrors, isValid },
    setError: setFormError,
    clearErrors: clearFormErrors,
    unregister,
  } = useForm({
    mode: 'onChange',
  })

  const {
    contractName,
    audienceType,
    salesforceID,
    leadCap,
    startDate,
    endDate,
    notes,
  } = useSelector(selectTimeBasedContractConfiguration)

  const {
    allDestinations,
    allDeliveryCadence,
    allFileFormats,
    allDeliveryModel,
    selectedDestinations,
    selectedFileFormat,
    selectedDeliveryCadence,
    selectedDeliveryModel,
    selectedFields,
    allLeads,
    selectedLeads,
  } = useSelector(selectTimeBasedDeliveryCriteria)

  const [
    createContract,
    {
      isLoading: isCreatingContract,
      data: contractData,
      isSuccess: isContractCreated,
    },
  ] = useCreateContractMutation()

  const { data: audienceTypes, isFetching: isFetchingAudienceTypes } =
    useGetAudienceTypesQuery(ContractSource.inquiries)

  const [addDestinationsToContract, { isLoading: isAddingDestinations }] =
    useAddDestinationsToContractMutation()

  const [
    subscribeUsertoContractNotifications,
    { isLoading: isSubscribingUsers },
  ] = useSubscribeUsertoContractNotificationsMutation()

  const [fetchContractRecords, { data: contractRecords }] =
    useLazySearchContractByLabelQuery()

  const addContract = () => {
    createContract({
      ipedId,
      source: ContractSource.inquiries,
      contract_type: ContractType.annual,
      fields_to_export: selectedFields,
      lead_cap: leadCap,
      salesforce_id: salesforceID,
      label: contractName,
      notes: notes,
      start_date: formatContractDate(startDate),
      end_date: formatContractDate(endDate),
      audience_type: audienceType,
      delivery_cadence: selectedDeliveryCadence,
      delivery_model: 'lead_cap_reached',
      look_back_period: 720,
    })
  }

  const clearContractValues = () => {
    dispatch(clearContractConfiguration())
    dispatch(clearTimeBasedDeliveryCriteria())
  }

  useEffect(() => {
    //If contract is created, do the following
    if (isContractCreated) {
      //add destinations to contract
      if (selectedDestinations.length > 0) {
        addDestinationsToContract({
          contractId: contractData.data.id,
          ipedId,
          destinationIds: selectedDestinations.map(
            (destination: any) => destination.id
          ),
        })
      }
      if (selectedLeads.length > 0) {
        //subscribe user to contract notifications
        subscribeUsertoContractNotifications({
          contractId: contractData.data.id,
          ipedId,
          accountIdsEnabled: selectedLeads,
        })
      }
      //clear redux slice
      clearContractValues()
      //navigate to inquiries page
      navigate(`/institution/${ipedId}/inquiries`)
    }
  }, [isContractCreated])

  useEffect(() => {
    fetchContractRecords({
      ipedId,
      filterQuery: buildFilterQuery(contractLabelSearchQuery(contractName)),
    })
  }, [contractName])

  useEffect(() => {
    if (contractRecords?.data?.total_records > 0) {
      setFormError(
        'root.contractName',
        {
          type: 'manual',
          message: 'Duplicate contract name. Please enter a unique name.',
        },

        { shouldFocus: true }
      )
    } else {
      clearFormErrors('root.contractName')
    }
  }, [contractRecords])

  const disableFormSubmit = !isValid || Object.keys(formErrors).length > 0

  if (
    isCreatingContract ||
    isAddingDestinations ||
    isSubscribingUsers ||
    isFetchingAudienceTypes
  )
    return <Loading />

  return (
    <Body>
      <BodyHeader>
        <ContractHeaderLabel label={'New Time-Based Contract'} />
        <BodyActions>
          <CancelButton
            onClick={() => {
              clearContractValues()
              navigate(`/institution/${ipedId}/inquiries`)
            }}
          >
            Cancel
          </CancelButton>
          <AddButton disabled={disableFormSubmit} onClick={addContract}>
            Add
          </AddButton>
        </BodyActions>
      </BodyHeader>
      <BodyContent>
        <ContractConfiguration
          audienceTypes={audienceTypes}
          contractName={contractName}
          salesforceID={salesforceID}
          audienceType={audienceType}
          leadCap={leadCap}
          startDate={startDate}
          endDate={endDate}
          notes={notes}
          contractSource={ContractSource.inquiries}
          control={control}
          setContractName={setContractName}
          setAudienceType={setAudienceType}
          setLeadCap={setLeadCap}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setNotes={setNotes}
          setSalesforceID={setSalesforceID}
        />
        <DeliveryCriteria
          allFileFormats={allFileFormats}
          audienceType={audienceType}
          allDeliveryModel={allDeliveryModel}
          allDestinations={allDestinations}
          allDeliveryCadence={allDeliveryCadence}
          selectedDestinations={selectedDestinations}
          selectedFileFormat={selectedFileFormat}
          selectedDeliveryCadence={selectedDeliveryCadence}
          selectedDeliveryModel={selectedDeliveryModel}
          selectedFields={selectedFields}
          allLeads={allLeads}
          selectedLeads={selectedLeads}
          contractSource={ContractSource.inquiries}
          control={control}
          setAllDestinations={setAllDestinations}
          setSelectedDestinations={setSelectedDestinations}
          setSelectedFileFormat={setSelectedFileFormat}
          setSelectedDeliveryCadence={setSelectedDeliveryCadence}
          setSelectedDeliveryModel={setSelectedDeliveryModel}
          setSelectedFields={setSelectedFields}
          setSelectedLeads={setSelectedLeads}
        />
      </BodyContent>
    </Body>
  )
}
