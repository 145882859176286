import { createSelector, createSlice } from '@reduxjs/toolkit'
import { buildSliderProperty } from 'Slices/sliderSlice'
import type { RootState } from 'Utils/store'
import { ContractSource } from 'Types/index'
import GPAConfig from 'Configs/fields/gpa.json'

const contractSource = ContractSource.inquiries

export const initialState: any = {
  values: Object.values(GPAConfig.values),
  audienceType: '',
  selectedValues: [GPAConfig.values.any],
  selectedMinGpa: GPAConfig.range.min,
  selectedMaxGpa: GPAConfig.range.max,
  filterQuery: '',
}

const columnFromAudienceType = (audienceType: string) => {
  return GPAConfig.column[audienceType]
    ? GPAConfig.column[audienceType]
    : GPAConfig.column.default
}

export const getLabel = (audienceType: string) => {
  return columnFromAudienceType(audienceType) === 'hs_gpa'
    ? 'High School GPA'
    : 'College GPA'
}

const deriveSelectionsAndRange = (range, meta) => {
  const rangeObject = {min: undefined, max: undefined}
  if (Array.isArray(range)) {
    for (let i = 0; i < range.length; i++) {
      if (Array.isArray(range[i])) {
        const minFirst = range[i][0] < range[i][1]
        rangeObject.min = minFirst ? range[i][0] : range[i][1];
        rangeObject.max = minFirst ? range[i][1] : range[i][0];
        const selections = [...range]
        selections[i] = meta?.rangeLabel || 'Specific Range'
        return {selections, rangeObject}
      }
    }
    if(range.includes('Any') || range.includes('any') || range.includes(null) || range.includes(meta?.nullLabel)  || range.includes(meta?.rangeLabel) || range.length === 0) {
      return {selections: range}
    } else {
      const minFirst = range[0] < range[1]
      rangeObject.min = minFirst ? range[0] : range[1]
      rangeObject.max = minFirst ? range[1] : range[0];
      return {rangeObject, selections: [meta?.rangeLabel || 'Specific Range']}
    }
  }
  return {selections: [typeof range === 'string' ? range : 'Any']}
}

export const GpaSlice = createSlice({
  name: 'gpa',
  initialState,
  reducers: {
    setTimeBasedAnyGpa: (state, action) => {
      const { selections, rangeObject } = deriveSelectionsAndRange(action.payload.selections, GPAConfig.meta)
      const newState = { 
        ...state, 
        audienceType: action.payload.audienceType, 
        selectedValues: selections, 
        filterQuery: buildSliderProperty(
          columnFromAudienceType(action.payload.audienceType), 
          [rangeObject ? rangeObject.min : state.selectedMinGpa, rangeObject ? rangeObject.max : state.selectedMaxGpa], 
          { section: GPAConfig.source[contractSource].meta.section, 
            label: getLabel(action.payload.audienceType), 
            nullLabel: GPAConfig.meta.nullLabel, 
            rangeLabel: GPAConfig.meta.rangeLabel }, 
          action.payload.selections) }
      if(rangeObject) {
        newState.selectedMinGpa = rangeObject.min
        newState.selectedMaxGpa = rangeObject.max
      }
      return newState
    },
    setTimeBasedGpaRange: (state, action) => {
      state.audienceType = action.payload.audienceType
      const { min, max } = action.payload
      state.selectedMinGpa = Number(min)
      state.selectedMaxGpa = Number(max)
      state.filterQuery = buildSliderProperty(
        columnFromAudienceType(action.payload.audienceType),
        [
          parseFloat(state.selectedMinGpa).toFixed(2),
          parseFloat(state.selectedMaxGpa).toFixed(2),
        ],
        {
          section: GPAConfig.source[contractSource].meta.section,
          label: getLabel(action.payload.audienceType),
          nullLabel: GPAConfig.meta.nullLabel, 
            rangeLabel: GPAConfig.meta.rangeLabel ,
        },
        state.selectedValues
      )
    },
  },
})

const createFilterQuery = (
  selectedValues: string[],
  min: string,
  max: string,
  audienceType: string
) => {
  const column = columnFromAudienceType(audienceType)
  return buildSliderProperty(column, [min, max], {
    section: GPAConfig.source[contractSource].meta.section,
    label: getLabel(audienceType),
    nullLabel: GPAConfig.meta.nullLabel,
    rangeLabel: GPAConfig.meta.rangeLabel
  }, selectedValues)
}
export const selectGpa = createSelector(
  ({ timeBasedFilter: { prospectProfile } }: RootState) =>
    prospectProfile.gpa['selectedValues'],
  ({ timeBasedFilter: { prospectProfile } }: RootState) =>
    prospectProfile.gpa['selectedMinGpa'],
  ({ timeBasedFilter: { prospectProfile } }: RootState) =>
    prospectProfile.gpa['selectedMaxGpa'],
  ({ timeBasedFilter: { prospectProfile } }: RootState) =>
    prospectProfile.gpa['audienceType'],
  (
    selectedValues: string[],
    selectedMinGpa: number,
    selectedMaxGpa: number,
    audienceType: string
  ): {
    selectedValues: string[]
    values: string[]
    step: number
    ariaLabel: string
    selectedMinGpa: number
    selectedMaxGpa: number
    minGpa: number
    maxGpa: number
    filterQuery: any
    audienceTypes: string[]
    label: string
  } => {
    return {
      selectedValues,
      values: Object.values(GPAConfig.values),
      selectedMinGpa,
      selectedMaxGpa,
      minGpa: GPAConfig.range.min,
      maxGpa: GPAConfig.range.max,
      step: GPAConfig.range.step,
      ariaLabel: GPAConfig.ARIA_LABEL,
      filterQuery: createFilterQuery(
        selectedValues,
        parseFloat(selectedMinGpa?.toString()).toFixed(2),
        parseFloat(selectedMaxGpa?.toString()).toFixed(2),
        audienceType
      ),
      audienceTypes: GPAConfig.source[contractSource].audience_types,
      label: getLabel(audienceType),
    }
  }
)

export const { setTimeBasedAnyGpa, setTimeBasedGpaRange } = GpaSlice.actions

export const GPAAudienceTypes = GPAConfig.source[contractSource].audience_types

export default GpaSlice.reducer
